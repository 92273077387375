import { Component, OnDestroy } from '@angular/core';
import { BuilderService } from '../SERVICES/BuilderService';
import { Raycaster, Vector2 } from 'three';
import { SideAccessory } from '../builders/KE_builder/side_accessories/side_accessory';
import { ShadeScreen } from '../builders/KE_builder/side_accessories/shade_screen';
import { SceneService } from 'modules/scene-setup/scene.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SideAccessoryArr } from '../builders/interfaces';
import { GlassDoors } from '../builders/KE_builder/side_accessories/glass_doors';
import { Curtain } from '../builders/KE_builder/side_accessories/curtain';

@Component({
  selector: 'app-screen-config',
  templateUrl: './screen-config.component.html',
  styleUrls: ['./screen-config.component.scss'],
})
export class ScreenConfigComponent implements OnDestroy {
  public addingScreen = false;
  public addingWindow = false;
  public addingCurtain = false;

  public canvas: HTMLElement | null;
  public emptyArray = true;
  public modulesAccessories: SideAccessoryArr[][] = [];

  public get isEmpty(): boolean {
    for (let i = 0; i < this.modulesAccessories.length; i++) {
      for (let j = 0; j < this.modulesAccessories[i].length; j++) {
        if (this.modulesAccessories[i][j].length !== 0) {
          return false;
        }
      }
    }
    return true;
  }

  constructor(
    public builderService: BuilderService,
    private sceneService: SceneService,
    private snackbar: MatSnackBar
  ) {
    this.canvas = document.getElementById('viewerCanvas');
    this.modulesAccessories = this.builderService.sceneBuilder.getAccessoriesList();
  }

  ngOnDestroy(): void {
    this.addingScreen = false;
    this.addingWindow = false;
    this.addingCurtain = false;
    this.snackbar.dismiss();
    this.builderService.sceneBuilder.hideSideAccessoryLocations();
    this.canvas?.removeEventListener('mousedown', this.canvasClickEvent);
    this.sceneService.sceneController.requestRenderIfNotRequested();
  }

  public get moduleNumber(): number {
    return this.builderService.sceneBuilder.getModuleNumber();
  }

  removeClickEvents() {
    this.builderService.sceneBuilder.hideSideAccessoryLocations();
    this.canvas?.removeEventListener('mousedown', this.canvasClickEvent);
    this.sceneService.sceneController.requestRenderIfNotRequested();
    this.snackbar.dismiss();
  }

  addScreenClick() {
    this.removeClickEvents();

    this.addingWindow = false;
    this.addingCurtain = false;
    this.addingScreen = !this.addingScreen;

    if (this.addingScreen) {
      const state = document.getElementById('toggle-menu')?.style.bottom;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const visible = getComputedStyle(document.getElementById('toggle-menu')!).display === 'none';

      if ((state === 'var(--mobileMenuHeight)' || state !== undefined) && !visible)
        document.getElementById('toggle-menu')?.click();

      const ref = this.snackbar.open('Izberite stran postavitve s klikom na model', 'Prekini', {
        verticalPosition: 'top',
        panelClass: 'customSnackbar',
      });

      ref.onAction().subscribe(() => {
        this.removeClickEvents();
        this.addingScreen = false;
        const state = document.getElementById('toggle-menu')?.style.bottom;
        if (state === '0px') document.getElementById('toggle-menu')?.click();
      });

      this.builderService.sceneBuilder.showPossibleScreenLocations();
      this.canvas?.addEventListener('mousedown', this.canvasClickEvent);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    }
  }

  addWindowClick() {
    this.removeClickEvents();

    this.addingWindow = !this.addingWindow;
    this.addingScreen = false;
    this.addingCurtain = false;

    if (this.addingWindow) {
      const state = document.getElementById('toggle-menu')?.style.bottom;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const visible = getComputedStyle(document.getElementById('toggle-menu')!).display === 'none';

      if ((state === 'var(--mobileMenuHeight)' || state !== undefined) && !visible)
        document.getElementById('toggle-menu')?.click();

      const ref = this.snackbar.open('Izberite stran postavitves s klikom na model', 'Prekini', {
        verticalPosition: 'top',
        panelClass: 'customSnackbar',
      });

      ref.onAction().subscribe(() => {
        this.removeClickEvents();
        this.addingWindow = false;
        const state = document.getElementById('toggle-menu')?.style.bottom;
        if (state === '0px') document.getElementById('toggle-menu')?.click();
      });

      this.builderService.sceneBuilder.showPossibleWindowLocations();
      this.canvas?.addEventListener('mousedown', this.canvasClickEvent);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    }
  }

  addCurtainClick() {
    this.removeClickEvents();

    this.addingWindow = false;
    this.addingScreen = false;
    this.addingCurtain = !this.addingCurtain;

    if (this.addingCurtain) {
      const state = document.getElementById('toggle-menu')?.style.bottom;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const visible = getComputedStyle(document.getElementById('toggle-menu')!).display === 'none';

      if ((state === 'var(--mobileMenuHeight)' || state !== undefined) && !visible)
        document.getElementById('toggle-menu')?.click();

      const ref = this.snackbar.open('Izberite stran postavitve s klikom na model', 'Prekini', {
        verticalPosition: 'top',
        panelClass: 'customSnackbar',
      });

      ref.onAction().subscribe(() => {
        this.removeClickEvents();
        this.addingCurtain = false;
        const state = document.getElementById('toggle-menu')?.style.bottom;
        if (state === '0px') document.getElementById('toggle-menu')?.click();
      });

      this.builderService.sceneBuilder.showPossibleCurtainLocations();
      this.canvas?.addEventListener('mousedown', this.canvasClickEvent);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    }
  }

  checkInstance(accessory: SideAccessory | null, type: string) {
    if (type === 'glassdoors') return accessory instanceof GlassDoors;
    if (type === 'screen') return accessory instanceof ShadeScreen;
    if (type === 'curtain') return accessory instanceof Curtain;

    return accessory;
  }

  canvasClickEvent = (event: MouseEvent) => {
    const mouse = new Vector2();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const canvasBounds = this.canvas!.getBoundingClientRect();

    mouse.x = ((event.clientX - canvasBounds.left) / (canvasBounds.right - canvasBounds.left)) * 2 - 1;
    mouse.y = -((event.clientY - canvasBounds.top) / (canvasBounds.bottom - canvasBounds.top)) * 2 + 1;

    const raycaster: Raycaster = this.sceneService.sceneController.getRaycaster(mouse);
    const intersected = this.builderService.sceneBuilder.checkIntersection(raycaster);

    if (intersected) {
      this.addingScreen = false;
      this.addingWindow = false;
      this.addingCurtain = false;
      this.snackbar.dismiss();
      this.canvas?.removeEventListener('mousedown', this.canvasClickEvent);
      this.modulesAccessories = this.builderService.sceneBuilder.getAccessoriesList();
      const state = document.getElementById('toggle-menu')?.style.bottom;
      if (state === '0px') document.getElementById('toggle-menu')?.click();
    }
  };

  onAccesorryDeleted() {
    this.modulesAccessories = this.builderService.sceneBuilder.getAccessoriesList();
  }
}
