import {
  Group,
  Mesh,
  WebGLRenderTarget,
  OrthographicCamera,
  CameraHelper,
  MeshDepthMaterial,
  ShaderMaterial,
  PlaneGeometry,
  MeshBasicMaterial,
  WebGLRenderer,
  Scene,
} from 'three';

import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';

export class ShadowController {
  private scene: Scene;

  private floorColor: string;
  private floorOpacity: number;
  private floorHeight: number;
  private floorWidth: number;

  private shadowBlur: number;
  private shadowDarkness: number;
  private shadowOpacity: number;

  private shadowGroup: Group;
  private renderTarget: WebGLRenderTarget;
  private renderTargetBlur: WebGLRenderTarget;
  private shadowCamera: OrthographicCamera;
  private cameraHelper: CameraHelper;
  private depthMaterial: MeshDepthMaterial;
  private horizontalBlurMaterial: ShaderMaterial;
  private verticalBlurMaterial: ShaderMaterial;

  private shadowPlane: Mesh;
  private blurPlane: Mesh;
  private fillPlane: Mesh;

  constructor(scene: Scene, configuration: any) {
    this.scene = scene;

    this.floorColor = configuration.plane.color;
    this.floorOpacity = configuration.plane.opacity;
    this.floorHeight = configuration.plane.height;
    this.floorWidth = configuration.plane.width;

    this.shadowBlur = configuration.shadow.blur;
    this.shadowDarkness = configuration.shadow.darkness;
    this.shadowOpacity = configuration.shadow.opacity;
    this.init();
  }

  public init() {
    const component = this;

    this.shadowGroup = new Group();
    this.shadowGroup.position.y = 0;
    this.scene.add(this.shadowGroup);

    // the render target that will show the shadows in the plane texture
    this.renderTarget = new WebGLRenderTarget(512, 512);
    this.renderTarget.texture.generateMipmaps = false;

    // the render target that we will use to blur the first render target
    this.renderTargetBlur = new WebGLRenderTarget(512, 512);
    this.renderTargetBlur.texture.generateMipmaps = false;

    const planeGeometry = new PlaneGeometry(this.floorHeight, this.floorWidth).rotateX(Math.PI / 2);
    const planeMaterial = new MeshBasicMaterial({
      map: this.renderTarget.texture,
      opacity: this.shadowOpacity,
      transparent: true,
      depthWrite: false,
    });

    this.shadowPlane = new Mesh(planeGeometry, planeMaterial);
    // make sure it's rendered after the fillPlane
    this.shadowPlane.renderOrder = 1;
    this.shadowGroup.add(this.shadowPlane);

    // the y from the texture is flipped!
    this.shadowPlane.scale.y = -1;

    // the plane onto which to blur the texture
    this.blurPlane = new Mesh(planeGeometry);
    this.blurPlane.visible = false;
    this.shadowGroup.add(this.blurPlane);

    // the plane with the color of the ground
    const fillPlaneMaterial = new MeshBasicMaterial({
      color: this.floorColor,
      opacity: this.floorOpacity,
      transparent: true,
      depthWrite: false,
    });

    this.fillPlane = new Mesh(planeGeometry, fillPlaneMaterial);
    this.fillPlane.rotateX(Math.PI);
    this.shadowGroup.add(this.fillPlane);

    this.shadowCamera = new OrthographicCamera(
      -this.floorHeight / 2,
      this.floorWidth / 2,
      this.floorHeight / 2,
      -this.floorWidth / 2,
      0,
      0.3
    );

    this.shadowCamera.rotation.x = Math.PI / 2; // get the camera to look up
    this.shadowGroup.add(this.shadowCamera);

    this.cameraHelper = new CameraHelper(this.shadowCamera);

    // like MeshDepthMaterial, but goes from black to transparent
    this.depthMaterial = new MeshDepthMaterial();
    this.depthMaterial.userData.darkness = {
      value: this.shadowDarkness,
    };

    this.depthMaterial.onBeforeCompile = function (shader) {
      shader.uniforms['darkness'] = component.depthMaterial.userData.darkness;
      shader.fragmentShader = /* glsl */ `
        uniform float darkness;
        ${shader.fragmentShader.replace(
          'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
          'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
        )}`;
    };

    this.depthMaterial.depthTest = false;
    this.depthMaterial.depthWrite = false;

    this.horizontalBlurMaterial = new ShaderMaterial(HorizontalBlurShader);
    this.horizontalBlurMaterial.depthTest = false;

    this.verticalBlurMaterial = new ShaderMaterial(VerticalBlurShader);
    this.verticalBlurMaterial.depthTest = false;
  }

  public blurShadow(renderer: WebGLRenderer, amount: number) {
    this.blurPlane.visible = true;

    // blur horizontally and draw in the renderTargetBlur
    this.blurPlane.material = this.horizontalBlurMaterial;
    (this.blurPlane.material as ShaderMaterial).uniforms['tDiffuse'].value = this.renderTarget.texture;
    this.horizontalBlurMaterial.uniforms['h'].value = (amount * 1) / 256;

    renderer.setRenderTarget(this.renderTargetBlur);
    renderer.render(this.blurPlane, this.shadowCamera);

    // blur vertically and draw in the main renderTarget
    this.blurPlane.material = this.verticalBlurMaterial;
    (this.blurPlane.material as ShaderMaterial).uniforms['tDiffuse'].value = this.renderTargetBlur.texture;
    this.verticalBlurMaterial.uniforms['v'].value = (amount * 1) / 256;

    renderer.setRenderTarget(this.renderTarget);
    renderer.render(this.blurPlane, this.shadowCamera);

    this.blurPlane.visible = false;
  }

  public update(renderer: WebGLRenderer) {
    const initialBackground = this.scene.background;
    this.scene.background = null;

    // force the depthMaterial to everything
    this.cameraHelper.visible = false;
    this.scene.overrideMaterial = this.depthMaterial;

    // set renderer clear alpha
    const initialClearAlpha = renderer.getClearAlpha();
    renderer.setClearAlpha(0);

    // render to the render target to get the depths
    renderer.setRenderTarget(this.renderTarget);
    renderer.render(this.scene, this.shadowCamera);

    // and reset the override material
    this.scene.overrideMaterial = null;
    this.cameraHelper.visible = true;

    this.blurShadow(renderer, this.shadowBlur);

    // a second pass to reduce the artifacts
    // (0.4 is the minimum blur amout so that the artifacts are gone)
    this.blurShadow(renderer, this.shadowBlur * 0.4);

    // reset and render the normal scene
    renderer.setRenderTarget(null);
    renderer.setClearAlpha(initialClearAlpha);
    this.scene.background = initialBackground;
  }

  public dispose() {
    this.blurPlane.geometry.dispose();
    this.shadowGroup.clear();
    this.depthMaterial.dispose();
    this.horizontalBlurMaterial.dispose();
    this.verticalBlurMaterial.dispose();
    this.shadowPlane.geometry.dispose();
    this.blurPlane.geometry.dispose();
    this.fillPlane.geometry.dispose();
  }
}
