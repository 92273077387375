import { Mesh, BoxGeometry, MeshStandardMaterial, Object3D, Vector3, Box3 } from 'three';
import { Floor, IBuilder } from '../interfaces';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { LedBuilder } from './led_builder';
import { ModuleState } from './module_builder';
import { PergolaBuilder } from './pergola_builder';

export class Rafter extends Object3D {
  mainPart: Mesh;
  rafterRotation: number;
  loader: GLTFLoader;
  size: Vector3;

  //offsets: Vector3 = new Vector3(-0.019, -0.003, -0.01);

  constructor(
    rotation: number,
    private _settings: ModuleState,
    postModels: Array<Mesh>,
    private _modelMaterial: MeshStandardMaterial
  ) {
    super();
    this.rafterRotation = rotation;

    this.mainPart = postModels[0].clone(); //glbModel.scene.children[0] as Mesh;;
    this.rotation.y = this.rafterRotation;
    this.add(this.mainPart);

    // get size
    const boundingBox = new Box3().setFromObject(this.mainPart);
    this.size = boundingBox.getSize(new Vector3(0, 0, 0));

    // resize
    this.mainPart.scale.y = this._settings.rafterSettings.height / this.size.y;
    this.mainPart.scale.z = this._settings.rafterSettings.width / this.size.z;
    this.mainPart.rotation.y = this.rafterRotation;

    this.mainPart.material = this._modelMaterial;

    // enable shadow casting
    this.mainPart.castShadow = true;
    this.mainPart.receiveShadow = true;
  }

  // funkcija, ki wrnte pormise na loadanje vseh modelov
  static async getModels(loader: GLTFLoader): Promise<Array<Mesh>> {
    const [rafterModule] = await Promise.all([loader.loadAsync('./assets/builder/KE/rafter/rafter_v2.glb')]);

    const mainPart = rafterModule.scene.children[0] as Mesh;

    return [mainPart];
  }

  setPosition(x: number, y: number, z: number) {
    this.position.set(x, y - this._settings.rafterSettings.height, z);
  }

  setLength(length: number) {
    this.mainPart.scale.x = length / this.size.x;
  }

  dispose() {
    this.mainPart.geometry.dispose();
  }
}

export class TopFrameBuilder extends Object3D implements IBuilder {
  width: number;
  length: number;
  height: number;

  postWidth: number;
  rafters = new Array<Rafter>();

  mainGeometry: BoxGeometry;
  mainMaterial: MeshStandardMaterial;

  settings: ModuleState;
  rotations = [0, -Math.PI / 2.0, Math.PI, Math.PI / 2.0];
  public loaded: boolean = false;
  leds: LedBuilder;

  constructor(settings: ModuleState, private _loader: GLTFLoader) {
    super();
    this.settings = settings;
    this.width = this.settings.width;
    this.height = this.settings.height;
    this.length = this.settings.length;
    this.postWidth = this.settings.postSettings.width;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateTexture?(_floor: Floor): void {
    throw new Error('Method not implemented.');
  }

  dispose(): void {
    PergolaBuilder.removeObjectsWithChildren(this.mainMaterial);
    PergolaBuilder.removeObjectsWithChildren(this.mainGeometry);

    for (const rafter of this.rafters) {
      PergolaBuilder.removeObjectsWithChildren(rafter);
    }

    this.rafters = [];
    this.leds.dispose();

    PergolaBuilder.removeObjectsWithChildren(this);
  }

  async initObjects(): Promise<void> {
    this.mainMaterial = new MeshStandardMaterial({
      roughness: 0.9,
      metalness: 0.3,
      color: this.settings.postSettings.color.code,
    });

    const rafterModels = await Rafter.getModels(this._loader);

    this.leds = new LedBuilder(this.settings);
    this.add(this.leds);

    for (let i = 0; i < 4; i++) {
      const model = new Rafter((i * Math.PI) / 4, this.settings, rafterModels, this.mainMaterial);

      model.castShadow = true;
      model.receiveShadow = true;
      this.add(model);
      this.rafters.push(model);
    }

    this.loaded = true;
    this.updateDimensions();
    this.updateColor();
  }

  updateDimensions() {
    this.width = this.settings.width;
    this.height = this.settings.height;
    this.length = this.settings.length;

    this.updatePosts();
    this.settings.moduleAttachedSides.forEach((side) => {
      this.rafters[side].visible = false;
    });
    this.leds.updateDimensions();
  }

  updatePosts() {
    if (!this.loaded) {
      return;
    }

    this.rafters[0].setPosition(this.width / 2 - this.settings.postSettings.width, this.height, this.length / 2);
    this.rafters[1].setPosition(this.width / 2, this.height, this.length / -2 + this.settings.postSettings.length);
    this.rafters[2].setPosition(this.width / -2 + this.settings.postSettings.width, this.height, this.length / -2);
    this.rafters[3].setPosition(this.width / -2, this.height, this.length / 2 - this.settings.postSettings.length);

    for (let i = 0; i < 4; i++) {
      let addLength = 0;
      let addOffset = 0;
      let scalar = -1;
      if (i > 2) {
        scalar = 1;
      }

      if (this.settings.wallSettings.sides.includes(i)) {
        addLength = this.settings.postSettings.width * 2;
        addOffset = this.settings.postSettings.width * scalar;
      }

      if (i % 2 === 0) {
        this.rafters[i].setLength(this.width - this.settings.postSettings.width * 2 + addLength);
        this.rafters[i].position.x += addOffset;
      } else {
        this.rafters[i].setLength(this.length - this.settings.postSettings.width * 2 + addLength);
        this.rafters[i].position.z += addOffset;
      }
    }
  }

  updateColor() {
    this.mainMaterial.color.set(this.settings.rafterSettings.color.code);
    this.leds.updateColor();
  }
}
