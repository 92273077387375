import { PergolaSettings } from './KE_builder/pergola_settings';
import { IBuilder } from './interfaces';
import {
  Mesh,
  BoxGeometry,
  MeshStandardMaterial,
  Object3D,
  TextureLoader,
  RepeatWrapping,
  Vector2,
  LinearSRGBColorSpace,
} from 'three';
import { Side } from './enums';

export class WallBuilder extends Object3D implements IBuilder {
  model: Mesh;

  settings: PergolaSettings;

  walls = new Array<Mesh>();
  enabledSides: Side[];

  mainGeometry: BoxGeometry;
  mainMaterial: MeshStandardMaterial;
  textureLoader: TextureLoader;

  private _metalness = 0;
  private _roughness = 1;
  private _uvScale = 3;
  private _normalScale = 0.5;

  constructor(settings: PergolaSettings) {
    super();
    this.settings = settings;
  }

  dispose(): void {
    this.mainGeometry?.dispose();
    this.mainMaterial.dispose();

    for (const wall of this.walls) {
      wall.geometry.dispose();
    }
  }

  async initObjects(): Promise<void> {
    this.textureLoader = new TextureLoader();
    const texture = await this.textureLoader.load('/assets/builder/facade2.jpg');

    this.mainGeometry = new BoxGeometry(1, 1, 1);
    texture.repeat.set(this._uvScale, this._uvScale);
    texture.colorSpace = LinearSRGBColorSpace;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;

    this.mainMaterial = new MeshStandardMaterial({
      color: this.settings.wallSettings.color.code,
      normalMap: texture,
      normalScale: new Vector2(this._normalScale, this._normalScale),
    });
    this.mainMaterial.roughness = this._roughness;
    this.mainMaterial.metalness = this._metalness;
    for (let i = 0; i < 4; i++) {
      const model = new Mesh(this.mainGeometry, this.mainMaterial);
      model.castShadow = true;
      model.receiveShadow = true;
      this.add(model);
      model.visible = false;
      this.walls.push(model);
    }

    this.updateWalls();
    this.updateDimensions();
    this.updateColor();
  }

  updateDimensions() {
    // update posisions of posts
    const width = this.settings.width;
    const height = this.settings.height;
    const length = this.settings.length;

    for (let i = 0; i < 4; i++) {
      if (i % 2 === 0) {
        this.walls[i].scale.x = width + this.settings.wallSettings.thickness * 2;
        this.walls[i].scale.z = this.settings.wallSettings.thickness;
      } else {
        this.walls[i].scale.z = length + this.settings.wallSettings.thickness * 2;
        this.walls[i].scale.x = this.settings.wallSettings.thickness;
      }
      this.walls[i].scale.y = height + this.settings.wallSettings.thickness;
    }

    this.walls[0].position.set(
      0,
      (height + this.settings.wallSettings.thickness) / 2,
      (length + this.settings.wallSettings.thickness) / 2
    );
    this.walls[1].position.set(
      (width + this.settings.wallSettings.thickness) / 2,
      (height + this.settings.wallSettings.thickness) / 2,
      0
    );
    this.walls[2].position.set(
      0,
      (height + this.settings.wallSettings.thickness) / 2,
      (length + this.settings.wallSettings.thickness) / -2
    );
    this.walls[3].position.set(
      (width + this.settings.wallSettings.thickness) / -2,
      (height + this.settings.wallSettings.thickness) / 2,
      0
    );

    this.mainMaterial.normalMap?.repeat.set(
      this.settings.length * this._uvScale,
      (this.settings.height + this.settings.wallSettings.offset) * this._uvScale
    );
  }

  updateColor() {
    this.mainMaterial.color.set(this.settings.wallSettings.color.code);
  }

  updateWalls() {
    for (let i = 0; i < 4; i++) {
      this.walls[i].visible = false;
    }

    this.settings.wallSettings.sides.forEach((side) => {
      switch (side) {
        case Side.front:
          this.walls[0].visible = true;
          break;
        case Side.right:
          this.walls[1].visible = true;
          break;
        case Side.back:
          this.walls[2].visible = true;
          break;
        case Side.left:
          this.walls[3].visible = true;
          break;
      }
    });
  }
}
