<mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header (click)="itemClick($event)">
        <mat-panel-title *ngIf="singleModule; else multiModule">
          ZIP Rolo {{moduleSideName}}
        </mat-panel-title>
        <ng-template #multiModule>
          <mat-panel-title>
            ZIP Rolo {{moduleSideName}} - Modul {{moduleName}}
          </mat-panel-title>
        </ng-template>
      </mat-expansion-panel-header>
      
      <h5>Prosojnost senčila</h5>

      <div class="buttonContainer">
        <button mat-mini-fab 
          *ngFor="let opacity of opacityOptions; index as i" 
          (click)="changeOpacity(i)"
          [ngStyle]="{'background-color': '#ffc14c'}"
          [ngClass]="{'activeSelection': selectedOpacity === opacity}"
        >{{opacity}}</button>
      </div>

      <h5 style="margin-top: 1.5em">Barva senčila</h5>

      <div class="buttonContainer">
        <button mat-mini-fab 
        *ngFor="let color of colorOptions; index as i" 
        (click)="changeColor(i)" 
        [ngStyle]="{'background-color': color}"
        [ngClass]="{'activeSelection': selectedColor === color}"></button>
      </div>

      <button class="removeButton" (click)="removeScreen()" mat-button>Odstrani senčilo</button>
    </mat-expansion-panel>
  </mat-accordion>