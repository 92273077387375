import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BuilderService } from '../SERVICES/BuilderService';
import { CurrentTime } from '../builders/enums';
import { Floor } from '../builders/interfaces';
import { SceneService } from 'modules/scene-setup/scene.service';
import { LoaderService } from 'modules/loader/loader.service';

@Component({
  selector: 'app-environment-config',
  templateUrl: './environment-config.component.html',
  styleUrls: ['./environment-config.component.scss'],
})
export class EnvironmentConfigComponent {
  @Output() closeClick = new EventEmitter();
  @Input() showClose: boolean = true;

  public rotation: number;
  public screenRotation: number;
  public windowRotation: number;
  public curtainRotation: number;

  public rotationLimits: number[];
  public availableFloors: Floor[];

  public windowSettingDisabled: boolean;
  public screenSettingDisabled: boolean;
  public curtainSettingDisabled: boolean;

  constructor(private _builderService: BuilderService, private _sceneService: SceneService, private _loaderService: LoaderService) {
    this.rotationLimits = this._builderService.sceneBuilder.getLouversRotationLimit();

    this.rotation = this._builderService.sceneBuilder.getLouverRotation();
    this.screenRotation = this._builderService.sceneBuilder.getScreenStretch();
    this.windowRotation = this._builderService.sceneBuilder.getWindowStretch();
    this.curtainRotation = this._builderService.sceneBuilder.getCurtainStretch();

    this.windowSettingDisabled = this._builderService.sceneBuilder.getWindowList().length === 0;
    this.screenSettingDisabled = this._builderService.sceneBuilder.getScreenList().length === 0;
    this.curtainSettingDisabled = this._builderService.sceneBuilder.getCurtainList().length === 0;

    this.availableFloors = this._builderService.sceneBuilder.getAvailableFloors();
  }

  public getTime(): number {
    return this._builderService.getTime();
  }

  onRotation(): void {
    if (!this.rotation || this.rotation < this.rotationLimits[0] || this.rotation > this.rotationLimits[1]) {
      this.rotation = this._builderService.sceneBuilder.getLouverRotation();
      return;
    }

    this._builderService.sceneBuilder.setLouversRotation(this.rotation);
    this._sceneService.sceneController.requestRenderIfNotRequested();
  }

  onScreenRotation(): void {
    if (!this.screenRotation || this.screenRotation < 0 || this.screenRotation > 100) {
      this.screenRotation = this._builderService.sceneBuilder.getScreenStretch();
      return;
    }

    this._builderService.sceneBuilder.setScreenStreching(this.screenRotation);
    this._sceneService.sceneController.requestRenderIfNotRequested();
  }

  onCurtainRotation(): void {
    if (!this.curtainRotation || this.curtainRotation < 0 || this.curtainRotation > 100) {
      this.curtainRotation = this._builderService.sceneBuilder.getCurtainStretch();
      return;
    }

    this._builderService.sceneBuilder.setCurtainStreching(this.curtainRotation);
    this._sceneService.sceneController.requestRenderIfNotRequested();
  }

  onWindowRotation(): void {
    if (!this.windowRotation || this.windowRotation < 0 || this.windowRotation > 100) {
      this.windowRotation = this._builderService.sceneBuilder.getWindowStretch();
      return;
    }

    this._builderService.sceneBuilder.setWindowStreching(this.windowRotation);
    this._sceneService.sceneController.requestRenderIfNotRequested();
  }

  switchDay(): void {
    this._builderService.setTime(CurrentTime.day);
  }

  switchNight(): void {
    this._builderService.setTime(CurrentTime.night);
  }

  switchStudio(): void {
    this._builderService.setTime(CurrentTime.studio);
  }

  switchFloor(floor: Floor): void {
    this._loaderService.loading = true;
    this._builderService.sceneBuilder.floorBuilder.updateTexture(floor).then(() => {
      this._sceneService.sceneController.requestRenderIfNotRequested();
      this._loaderService.loading = false;
    });
  }

  public toURL(url: string) {
    return 'url( ../..' + url + ')';
  }

  public getActiveFloor(floor: Floor): boolean {
    return this._builderService.getSelectedFloor() === floor;
  }
}
