import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from "@angular/core";
import { LoaderService } from "./loader.service";

@Component({
  selector: "app-component-loader",
  templateUrl: "./component-loader.component.html",
  styleUrls: ["./component-loader.component.scss"],
})
export class ComponentLoaderComponent implements AfterViewInit, OnDestroy {
  private intersectionObserver!: IntersectionObserver;

  @Input() logo: string = "";
  @Input() text: string = "Loading";

  constructor(private elementRef: ElementRef, private loaderService: LoaderService) {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add your logic here that should be executed when the component is fully visible.
          this.loaderService.loaderIsDisplayed();
        }
      });
    });

    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }
}
