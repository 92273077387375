/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../SERVICES/BackendService';
import { BuilderService } from '../SERVICES/BuilderService';
import { Color } from 'three';
import { IColor } from '../builders/interfaces';
import { ColorConfigMode } from '../builders/enums';
@Component({
  selector: 'app-color-config',
  templateUrl: './color-config.component.html',
  styleUrls: ['./color-config.component.scss'],
})
export class ColorConfigComponent implements OnInit, OnDestroy {
  @Input() mode: ColorConfigMode = ColorConfigMode.frame;

  public availableColors: IColor[] = [];

  public listColNumber = 3;
  public listWidth = '100%';
  public listRowHeight = '1:1';

  public customColor: boolean = false;
  public customColorInput: string = '';

  constructor(public backendService: BackendService, public builderService: BuilderService) {
    this.availableColors = builderService.sceneBuilder.getAvailableColors();

    if (window.innerWidth <= 750) {
      this.listColNumber = this.availableColors.length;
      this.listWidth = `${this.availableColors.length * 100}px`;
      this.listRowHeight = '50px';
    }
  }

  ngOnInit(): void {
    if (this.selectedColor?.customColor) {
      this.customColor = true;
      this.customColorInput = this.selectedColor.customColor.replace('Po meri - ', '');
    }
  }

  ngOnDestroy(): void {
    if (this.customColor && this.customColorInput) {
      this.colorClick({
        id: this.selectedColor!.id,
        code: this.selectedColor!.code,
        name: this.selectedColor!.name,
        customColor: `Po meri - ${this.customColorInput}`,
      });
    } else {
      this.colorClick({
        id: this.selectedColor!.id,
        code: this.selectedColor!.code,
        name: this.selectedColor!.name,
        customColor: '',
      });
    }
  }

  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth <= 750) {
      this.listColNumber = this.availableColors.length;
      this.listWidth = `${this.availableColors.length * 100}px`;
      this.listRowHeight = '50px';
    } else {
      this.listColNumber = 3;
      this.listWidth = `100%`;
      this.listRowHeight = '1:1';
    }
  }

  public colorKeyboardClick(evt: KeyboardEvent, color: IColor) {
    const index = this.availableColors.findIndex((e) => e === color);

    if (index === 0) {
      if (evt.key === 'ArrowRight') {
        this.colorClick(this.availableColors[index + 1]);
        document.getElementById(`c${this.mode}${this.availableColors[index + 1].code}`)?.focus();
      }
    } else if (index === this.availableColors.length - 1) {
      if (evt.key === 'ArrowLeft') {
        this.colorClick(this.availableColors[index - 1]);
        document.getElementById(`c${this.mode}${this.availableColors[index - 1].code}`)?.focus();
      }
    } else {
      if (evt.key === 'ArrowRight') {
        this.colorClick(this.availableColors[index + 1]);
        document.getElementById(`c${this.mode}${this.availableColors[index + 1].code}`)?.focus();
      } else if (evt.key === 'ArrowLeft') {
        this.colorClick(this.availableColors[index - 1]);
        document.getElementById(`c${this.mode}${this.availableColors[index - 1].code}`)?.focus();
      }
    }
  }

  public toColor(code: number) {
    return `#${new Color(code).getHexString()}`;
  }

  public get selectedColor() {
    if (this.mode === ColorConfigMode.frame) {
      return this.builderService.selectedFrameColor;
    } else if (this.mode === ColorConfigMode.louvers) {
      return this.builderService.selectedLouverColor;
    }
    return null;
  }

  public getButtonClass(code: number) {
    if (code === this.selectedColor?.code) {
      return 'selectedColor';
    }
    return '';
  }

  public colorClick(color: IColor) {
    if (this.mode === ColorConfigMode.frame) {
      this.builderService.configFrameColorEmitter.emit(color);
    } else if (this.mode === ColorConfigMode.louvers) {
      this.builderService.configLouverColorEmitter.emit(color);
    }
  }
}
