

  <mat-grid-list style="margin-bottom: 1.5em;" cols="{{columnNumber}}" rowHeight="1:1" gutterSize="10px">
    <mat-grid-tile *ngFor="let layout of layouts" style="overflow: visible">
      <div id="image-wrapper" style="overflow: visible">
        <button
          id="{{ layout.id }}"
          mat-button
          class="image-div"
          (click)="layoutClick(layout)"
          [ngStyle]="{ 'background-image': toURL(layout.thumbnailURL) }"
          [ngClass]="{'selectedLayout': builderService.selectedLayout && builderService.selectedLayout.id === layout.id}"
        ></button>
        <span id="layout-name">{{ layout.name }}</span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>



