export enum Side {
  front,
  right,
  back,
  left,
}

export enum SideAccessoryState {
  preview,
  added,
  ghost,
}

export enum WindowClosinType {
  left,
  right,
  accordion,
}

export enum CurtainClosingType {
  left,
  right,
}

export enum ColorConfigMode {
  louvers,
  frame,
}

export enum CurrentTime {
  day,
  night,
  studio
}
