import { IBuilder } from '../interfaces';
import { Mesh, MeshStandardMaterial, Object3D, Box3, Vector3 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ModuleState } from './module_builder';
import { PergolaBuilder } from './pergola_builder';
import { Side } from '../enums';

export class Post extends Object3D {
  bottomPart: Mesh;
  topPart: Mesh;
  postRotation: number;
  loader: GLTFLoader;

  bottomPartSize: Vector3;
  topPartSize: Vector3;

  constructor(
    private _postRotation: number,
    private _settings: ModuleState,
    postModels: Array<Mesh>,
    private _modelMaterial: MeshStandardMaterial
  ) {
    super();
    this.loader = new GLTFLoader();

    this.bottomPart = postModels[0].clone();
    this.topPart = postModels[1].clone();

    // add to scene
    this.add(this.bottomPart);
    this.add(this.topPart);

    // load box sizesgetModels
    const boundingBoxBottomPart = new Box3().setFromObject(this.bottomPart);
    this.bottomPartSize = boundingBoxBottomPart.getSize(new Vector3(0, 0, 0));
    const boundingBoxTopPart = new Box3().setFromObject(this.topPart);
    this.topPartSize = boundingBoxTopPart.getSize(new Vector3(0, 0, 0));

    // add material
    this.bottomPart.material = this._modelMaterial;
    this.topPart.material = this._modelMaterial;

    // resize
    this.bottomPart.scale.z = this._settings.postSettings.width / this.bottomPartSize.z;
    this.bottomPart.scale.x = this._settings.postSettings.length / this.bottomPartSize.x;

    this.topPart.scale.z = this._settings.postSettings.width / this.topPartSize.z;
    this.topPart.scale.x = this._settings.postSettings.length / this.topPartSize.x;

    //this.bottomPart.position.y = this.bottomPartSize.y;
    this.rotation.y = this._postRotation;
  }

  // funkcija, ki wrnte pormise na loadanje vseh modelov
  static async getModels(loader: GLTFLoader): Promise<Array<Mesh>> {
    const [bottomModule, topModule] = await Promise.all([
      loader.loadAsync('./assets/builder/KE/post/post_bottom.glb'),
      loader.loadAsync('./assets/builder/KE/post/post_top.glb'),
    ]);

    const bottomPart = bottomModule.scene.children[0] as Mesh;
    const topPart = topModule.scene.children[0] as Mesh;

    bottomPart.castShadow = true;
    topPart.castShadow = true;

    bottomPart.receiveShadow = true;
    topPart.receiveShadow = true;

    return [bottomPart, topPart];
  }

  setPosition(x: number, z: number, height: number) {
    this.position.x = x;
    this.position.z = z;

    const topPartHeight = height - this.bottomPartSize.y;
    this.topPart.position.y = this.bottomPartSize.y;
    this.topPart.scale.y = topPartHeight / this.topPartSize.y;
  }

  dispose() {
    PergolaBuilder.removeObjectsWithChildren(this.bottomPart);
    PergolaBuilder.removeObjectsWithChildren(this.topPart);
  }
}

export class PostsBuilder extends Object3D implements IBuilder {
  postWidth: number;
  postHeight: number;
  postLength: number;
  model: Mesh;

  settings: ModuleState;

  cornerPosts = new Array<Post>();

  mainMaterial: MeshStandardMaterial;

  public loaded: boolean = false;
  private _rotations = [Math.PI, Math.PI, 0, 0, Math.PI, 0];

  constructor(settings: ModuleState, private _loader: GLTFLoader) {
    super();
    this.settings = settings;
    this.postWidth = this.settings.width;
    this.postHeight = this.settings.height;
    this.postLength = this.settings.postSettings.length;
  }

  dispose(): void {
    PergolaBuilder.removeObjectsWithChildren(this.mainMaterial);
    PergolaBuilder.removeObjectsWithChildren(this.model);

    for (const post of this.cornerPosts) {
      post.dispose();
    }

    this.cornerPosts = [];

    PergolaBuilder.removeObjectsWithChildren(this);
  }

  async initObject(): Promise<void> {
    this.mainMaterial = new MeshStandardMaterial({
      roughness: 0.9,
      metalness: 0.3,
      color: this.settings.postSettings.color.code,
    });

    const postModels = await Post.getModels(this._loader);

    for (let i = 0; i < 6; i++) {
      const model = new Post(this._rotations[i], this.settings, postModels, this.mainMaterial);

      model.castShadow = true;
      model.receiveShadow = true;
      this.add(model);
      this.cornerPosts.push(model);
    }

    this.loaded = true;

    this.updateDimensions();
    this.updateModels();
    this.updateWalls();
    this.updateColor();
  }

  updateDimensions() {
    this.updatePostDimensions();
    this.updateModels();
  }

  updatePostDimensions() {
    this.postWidth = this.settings.postSettings.width;
    this.postLength = this.settings.postSettings.length;
    this.postHeight = this.settings.height;
  }

  updateModels() {
    // update posisions of posts
    const width = this.settings.width;
    const height = this.settings.height;
    const length = this.settings.length;

    this.cornerPosts[0].setPosition(width / -2.0 + this.postWidth / 2.0, length / 2.0 - this.postWidth / 2.0, height);
    this.cornerPosts[1].setPosition(width / 2.0 - this.postWidth / 2.0, length / 2.0 - this.postWidth / 2.0, height);
    this.cornerPosts[2].setPosition(width / 2.0 - this.postWidth / 2.0, length / -2.0 + this.postWidth / 2.0, height);
    this.cornerPosts[3].setPosition(width / -2.0 + this.postWidth / 2.0, length / -2.0 + this.postWidth / 2.0, height);

    // additional posts
    this.cornerPosts[4].setPosition(width / 2.0 - this.postWidth / 2.0, 0, height);
    this.cornerPosts[5].setPosition(width / -2.0 + this.postWidth / 2.0, 0, height);

    this.updateWalls();
  }

  updateColor() {
    this.mainMaterial.color.set(this.settings.postSettings.color.code);
  }

  updateWalls() {
    for (let i = 0; i < 4; i++) {
      this.cornerPosts[i].visible = true;
    }

    this.settings.wallSettings.sides.forEach((side) => {
      this.cornerPosts[side].visible = false;
      this.cornerPosts[(side + 1) % 4].visible = false;
    });

    if (this.settings.length > this.settings.minLengthAddPost) {
      this.cornerPosts[4].visible = !this.settings.wallSettings.sides.includes(Side.right);
      this.cornerPosts[5].visible = !this.settings.wallSettings.sides.includes(Side.left);
    } else {
      this.cornerPosts[4].visible = false;
      this.cornerPosts[5].visible = false;
    }
  }
}
