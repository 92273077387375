import {
  DataTexture,
  DirectionalLight,
  EquirectangularReflectionMapping,
  Group,
  Raycaster,
  Scene,
  SRGBColorSpace,
  Texture,
  TextureLoader,
} from 'three';
import { CurrentTime, CurtainClosingType, Side, WindowClosinType } from './enums';
import { FloorBuilder } from './floor_builder';
import { Floor, IColor, Layout, SideAccessoryArr, SideRecord } from './interfaces';
import { PergolaBuilder } from './KE_builder/pergola_builder';
import { PergolaSettings } from './KE_builder/pergola_settings';
import { SideAccessory } from './KE_builder/side_accessories/side_accessory';
import { WallBuilder } from './wall_builder';
import { ShadeScreen } from './KE_builder/side_accessories/shade_screen';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { SceneController } from 'modules/scene-setup/SceneController';
import { Curtain } from './KE_builder/side_accessories/curtain';
import { GlassDoors } from './KE_builder/side_accessories/glass_doors';
import { GroundProjectedSkybox } from 'three/examples/jsm/objects/GroundProjectedSkybox';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

export class SceneBuilder extends Scene {
  private _pergolaBuilder: PergolaBuilder;
  public wallBuilder: WallBuilder;
  public floorBuilder: FloorBuilder;
  private _glowPass: OutlinePass;

  settings: PergolaSettings;
  loaded = false;

  private hdrDay: DataTexture;
  private hdrNight: DataTexture;
  private daySkybox: Texture;
  private nightSkybox: Texture;
  private time: CurrentTime | null;
  private skybox: GroundProjectedSkybox | null;
  private spotlight: DirectionalLight;

  constructor(settings: PergolaSettings) {
    super();
    this.settings = settings;
  }

  public async initObjects(glowPass: OutlinePass) {
    this._glowPass = glowPass;
    this._pergolaBuilder = new PergolaBuilder(this.settings, this._glowPass);
    this.wallBuilder = new WallBuilder(this.settings);
    this.floorBuilder = new FloorBuilder(this.settings);

    this.spotlight = new DirectionalLight(0xffa95c, 1);
    this.spotlight.castShadow = true;
    this.spotlight.position.set(10, 10, 10);
    this.spotlight.shadow.mapSize.width = 256;
    this.spotlight.shadow.mapSize.height = 256;

    await Promise.all([
      this._pergolaBuilder.initObjects(),
      this.wallBuilder.initObjects(),
      this.floorBuilder.initObjects(),
      this.loadDayHDR(),
    ])
      .then(() => {
        this.add(this._pergolaBuilder);
        this.add(this.wallBuilder);
        this.add(this.floorBuilder);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error occurred during initialization:', error);
      });

    this.loaded = true;
  }

  dispose() {
    this._pergolaBuilder.dispose();
    this.wallBuilder.dispose();
    this.floorBuilder.dispose();
  }

  setLouversRotation(angle: number): void {
    this.settings.louversSettings.rotation = angle;
    this._pergolaBuilder.updateLouversRotation();
  }

  getModuleStates(): (SideRecord | null)[] {
    return this._pergolaBuilder.getModuleStates();
  }

  getModuleNumber(): number {
    return this._pergolaBuilder.getNumModules();
  }

  getLouversRotationLimit(): number[] {
    return [this.settings.louversSettings.minRotation, this.settings.louversSettings.maxRotation];
  }

  getLouverRotation(): number {
    return this.settings.louversSettings.rotation;
  }

  getWidthLimit(): number[] {
    return [this.settings.minWidth, this.settings.maxWidth];
  }

  getHeightLimit(): number[] {
    return [this.settings.minHeight, this.settings.maxHeight];
  }

  getLengthLimit(): number[] {
    return [this.settings.minLength, this.settings.maxLength];
  }

  getScreenColors(): IColor[] {
    return this.settings.shadesColors;
  }

  getCurtainColors(): IColor[] {
    return this.settings.curtainColors;
  }

  getWindowStretch(): number {
    return this.settings.windowStreched;
  }

  getScreenStretch(): number {
    return this.settings.screenStreched;
  }

  getCurtainStretch(): number {
    return this.settings.curtainStreched;
  }

  getWidth(): number {
    return this.settings.width;
  }

  getHeight(): number {
    return this.settings.height;
  }

  getLength(): number {
    return this.settings.length;
  }

  getDimensionSteps(): number[] {
    return [this.settings.widthStep, this.settings.lengthStep, this.settings.heightStep];
  }

  getAvailableFloors(): Floor[] {
    return this.settings.availableFloors;
  }

  setWalls(walls: Side[]): void {
    this.settings.wallSettings.sides = walls;
    this._updateWalls();
  }

  private _updateWalls(): void {
    this.wallBuilder.updateWalls();
    this._pergolaBuilder.updateWalls();
  }

  setDimensions(width: number, length: number, height: number): void {
    if (this.loaded) {
      this.settings.width = width;
      this.settings.length = length;
      this.settings.height = height;
      this._updateDimensions();
    }
  }

  private _updateDimensions(): void {
    this._pergolaBuilder.updateDimensions();
    this.wallBuilder.updateDimensions();
    this.floorBuilder.updateDimensions();
  }

  setColor(color: IColor): void {
    this.settings.louversSettings.color = color;
    this.settings.rafterSettings.color = color;
    this.settings.postSettings.color = color;
    // sets color of all elements in pergola to same color
    this._pergolaBuilder.updateLouversColor();
    this._pergolaBuilder.updateFrameColor();
  }

  setWallsColor(color: IColor): void {
    this.settings.wallSettings.color = color;
    this.wallBuilder.updateColor();
  }

  setFrameColor(color: IColor): void {
    this.settings.rafterSettings.color = color;
    this.settings.postSettings.color = color;
    this._pergolaBuilder.updateFrameColor();
  }

  setLouversColor(color: IColor): void {
    this.settings.louversSettings.color = color;
    this._pergolaBuilder.updateLouversColor();
  }

  /* setInnerLeds(val: boolean, glowPass: OutlinePass) {
    this.settings.ledSettings.hasInner = val;
    this._pergolaBuilder.updateInnerLeds(glowPass);
  } */

  setInnerLeds(val: number, glowPass: OutlinePass) {
    this.settings.ledSettings.selectedInnerLed = val;
    this._pergolaBuilder.updateInnerLeds(glowPass);
  }

  getAvailableColors(): IColor[] {
    return this.settings.availableColors;
  }

  getAvailableLayouts(): Layout[] {
    return this.settings.availableLayouts;
  }

  getScreenOpacities(): number[] {
    return [1, 5, 10];
  }

  getWindowList(): SideAccessory[] {
    return this._pergolaBuilder.getWindowList();
  }

  getScreenList(): SideAccessory[] {
    return this._pergolaBuilder.getScreenList();
  }

  getCurtainList(): SideAccessory[] {
    return this._pergolaBuilder.getCurtainList();
  }

  getAccessoriesList(): SideAccessoryArr[][] {
    return this._pergolaBuilder.getAccessoriesList();
  }

  setScreenStreching(percentage: number): void {
    this.settings.screenStreched = percentage;
    this._pergolaBuilder.updateScreenStretching();
  }

  setWindowStreching(percentage: number): void {
    this.settings.windowStreched = percentage;
    this._pergolaBuilder.updateWindowStretching();
  }

  setCurtainStreching(percentage: number): void {
    this.settings.curtainStreched = percentage;
    this._pergolaBuilder.updateCurtainStretching();
  }

  showPossibleScreenLocations(): void {
    this._pergolaBuilder.showPossibleScreenLocations();
  }

  showPossibleWindowLocations(): void {
    this._pergolaBuilder.showPossibleWindowLocations();
  }

  showPossibleCurtainLocations(): void {
    this._pergolaBuilder.showPossibleCurtainLocations();
  }

  hideSideAccessoryLocations(): void {
    this._pergolaBuilder.hideSideAccessoryLocations();
  }

  checkIntersection(raycaster: Raycaster): SideAccessory | null {
    return this._pergolaBuilder.checkIntersection(raycaster);
  }

  removePanel(panel: SideAccessory): void {
    this._pergolaBuilder.removePanel(panel);
  }

  getPanelSide(panel: SideAccessory | null): Side | undefined {
    if (panel) return this._pergolaBuilder.getPanelSide(panel);
    return undefined;
  }

  changeScreenColor(panel: ShadeScreen, color: IColor) {
    panel.setColor(color);
  }

  changeCurtainColor(panel: Curtain, color: IColor) {
    panel.setColor(color);
  }

  changeScreenOpacity(panel: ShadeScreen, opacity: number) {
    panel.setOpacity(opacity);
  }

  updateWindowNumber(panel: GlassDoors, number: number) {
    panel.setWindowsNumber(number);
  }

  updateWindowClosingType(panel: GlassDoors, type: string) {
    let closingType: WindowClosinType = WindowClosinType.left;
    switch (type) {
      case 'Levo':
        closingType = WindowClosinType.left;
        break;
      case 'Desno':
        closingType = WindowClosinType.right;
        break;
      case 'Harmonika':
        closingType = WindowClosinType.accordion;
        break;
    }
    panel.setClosingType(closingType);
  }

  updateCurtainClosingType(panel: Curtain, type: string) {
    let closingType: CurtainClosingType = CurtainClosingType.left;

    switch (type) {
      case 'Levo':
        closingType = CurtainClosingType.left;
        break;
      case 'Desno':
        closingType = CurtainClosingType.right;
        break;
    }

    panel.setClosingType(closingType);
  }

  focusElement(panel: SideAccessory | null, side: Side, sceneController: SceneController) {
    if (panel) {
      panel.focus(side, sceneController);
    }
  }

  getPergola3Dmodel(): Group {
    return this._pergolaBuilder;
  }

  async setTime(time: CurrentTime): Promise<CurrentTime | null> {
    if (!this.time || time !== this.time) {
      if (this.skybox) {
        this.remove(this.skybox);
      }

      if (time === CurrentTime.day) {
        if (!this.daySkybox) {
          await this.loadDayTexture();
        }
        if (!this.hdrDay) {
          await this.loadDayHDR();
        }
        this.skybox = new GroundProjectedSkybox(this.daySkybox);
        this.environment = this.hdrDay;
        this.add(this.spotlight);
      } else if (time === CurrentTime.studio) {
        this.environment = this.hdrDay;
        this.add(this.spotlight);
      } else {
        if (!this.nightSkybox) {
          await this.loadNightTexture();
        }

        if (!this.hdrNight) {
          await this.loadNightHDR();
        }
        this.skybox = new GroundProjectedSkybox(this.nightSkybox);
        this.environment = this.hdrNight;
        this.remove(this.spotlight);
      }

      if (time !== CurrentTime.studio) {
        if (this.skybox) {
          this.skybox.scale.setScalar(500);
          this.skybox.height = 5;
          this.skybox.radius = 50;
          this.add(this.skybox);
        }
      }
      this.time = time;
      return this.time;
    }
    return time;
  }

  public async loadDayHDR() {
    const loader = new RGBELoader();

    const texture = await loader.loadAsync('assets/day.hdr');
    texture.mapping = EquirectangularReflectionMapping;

    this.hdrDay = texture;
  }

  public async loadDayTexture() {
    const loader2 = new TextureLoader();

    const image = await loader2.loadAsync('assets/day.jpg');
    image.generateMipmaps = false;

    image.mapping = EquirectangularReflectionMapping;
    image.colorSpace = SRGBColorSpace;
    this.daySkybox = image;
  }

  public async loadNightHDR() {
    const loader = new RGBELoader();

    const texture = await loader.loadAsync('assets/night.hdr');
    texture.mapping = EquirectangularReflectionMapping;

    this.hdrNight = texture;
  }

  public async loadNightTexture() {
    const loader2 = new TextureLoader();

    const image = await loader2.loadAsync('assets/night.jpg');
    image.generateMipmaps = false;

    image.mapping = EquirectangularReflectionMapping;
    image.colorSpace = SRGBColorSpace;
    this.nightSkybox = image;
  }
}
