import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../SERVICES/BackendService';
import { BuilderService } from '../SERVICES/BuilderService';
import { Subscription } from 'rxjs';
import { Layout } from '../builders/interfaces';

@Component({
  selector: 'app-layout-selection',
  templateUrl: './layout-selection.component.html',
  styleUrls: ['./layout-selection.component.scss'],
})
export class LayoutSelectionComponent implements OnInit, OnDestroy {
  public layouts: Layout[] = [];

  private emitterSub: Subscription;
  public columnNumber = 2;

  constructor(public backendService: BackendService, public builderService: BuilderService) {
    this.emitterSub = this.builderService.configLayoutEmitter.subscribe((layout) => {
      this.setActiveBorder(layout);
    });

    if (window.innerWidth <= 750) {
      this.columnNumber = 3;
    }
  }

  ngOnInit(): void {
    this.layouts = this.builderService.sceneBuilder.getAvailableLayouts();
    if (!this.builderService.selectedLayout) {
      this.setActiveBorder(this.layouts[0]);
      this.builderService.selectedLayout = this.layouts[0];
    }
  }

  ngOnDestroy(): void {
    this.emitterSub.unsubscribe();
    this.emitterSub = Subscription.EMPTY;
  }

  public toURL(url: string) {
    return 'url(' + url + ')';
  }

  public layoutClick(layout: Layout) {
    this.builderService.configLayoutEmitter.emit(layout);
  }

  public setActiveBorder(layout: Layout) {
    const active = document.querySelector('.selectedLayout');
    if (active) active.classList.remove('selectedLayout');
    document.querySelector(`#${layout.id}`)?.classList.add('selectedLayout');
  }
}
export { Layout };
