import { WebGLRenderer, Group, Box3, Vector3, PlaneGeometry, MeshStandardMaterial, Mesh, FrontSide } from "three";

import { GLTFExporter, GLTFExporterOptions } from "three/examples/jsm/exporters/GLTFExporter";
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter.js";
import { Observable, ReplaySubject } from "rxjs";
import { ARRenderer, ARStatus } from "./ARRenderer";

declare global {
  interface Window {
    MSStream: any;
    opera: any;
  }
}

export type ARDataType = {
  error: boolean;
  url: boolean;
  message: string;
  status: ARStatus | null;
};
export class ARViewer {
  private deviceType: string;
  private arRenderer: ARRenderer;

  // reply subjects
  public arReplaySubject: ReplaySubject<ARDataType> =
    new ReplaySubject<{
      error: boolean;
      url: boolean;
      message: string;
      status: ARStatus | null;
    }>();
  public arReplayObservable: Observable<ARDataType> =
    this.arReplaySubject.asObservable();

  public arStarted: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public arStartedObservable: Observable<boolean> = this.arStarted.asObservable();

  constructor(private _threeRenderer: WebGLRenderer | null = null, private _androidHDRMapPath: string | null = null) {
    this.deviceType = ARViewer.getDeviceType();
    if (this._threeRenderer) {
      this.initRednerer();
    }
  }

  isInitRenderer() {
    return this._threeRenderer !== null;
  }

  setRenderer(renderer: WebGLRenderer) {
    this._threeRenderer = renderer;
    this.initRednerer();
  }

  private initRednerer() {
    this.arRenderer = new ARRenderer(this._threeRenderer!, this._androidHDRMapPath);
    this.arRenderer.addEventListener("status", (event) => {
      if (event["status"] === ARStatus["NOT_PRESENTING"]) {
        this.arReplaySubject.next({
          error: false,
          url: false,
          message: "",
          status: event["status"]
        });
      }

      if (event["status"] === ARStatus["FAILED"]) {
        this.arReplaySubject.next({
          error: true,
          url: false,
          message:
            "Brskalnik, katerega uporabljate ne podpira ogleda v obogateni resničnosti. Za uporabo te funkcije, prosim odprite konfigurator v katerem drugem brskalniku (npr. Chrome)",
          status: event["status"],
        });
      }

      if (event["status"] === ARStatus["FINISHED"]) {
        this.arReplaySubject.next({
          error: false,
          url: false,
          message: "",
          status: event["status"],
        });
      }
    });

    this.arRenderer.addEventListener("tracking", (event) => {});
  }

  static getDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (
      /iPad|iPhone|iPod/.test(userAgent) ||
      (/(Mac|Macintosh)/.test(userAgent) && navigator.maxTouchPoints > 1 && !window.MSStream)
    ) {
      return "ios";
    }

    return "unknown";
  }

  canOpenAR(): boolean {
    if (this.deviceType === "ios" || this.deviceType === "android") {
      return true;
    }
    return false;
  }

  public isIOS(): boolean {
    return this.deviceType === "ios";
  }

  async openAR(group: Group, center: boolean = false): Promise<any> {
    if (this.deviceType === "ios") {
      // Use openARQuickLook() for iPhones
      await this.openARQuickLook(group, center);
    } else if (this.deviceType === "android") {
      // Activate AR mode in the model-viewer for Android phones
      if (this._threeRenderer === null) {
        throw new Error("AR renderer is not initialized. Use setRenderer()");
      }
      this.openAndroidAR(group, center);
    } else {
      await this.openARQuickLook(group, center);
      //this.openAndroidAR(group, center);
      //this.exportGLB(group, center);
    }
    return true;
  }

  openAndroidAR(group: Group, center: boolean): void {
    this._threeRenderer!.setClearColor(0xFFFFFF, 0);
    if (center) {
      this.arRenderer.present(this.createCenteredCopy(group)); // za Android je potrebno vedno naredit novo groupo (centrirano), ker se disposa, ko konna AR session. TODO: v prihodnje naredi, da bo groupa enaka, in je ne bo disposalo
    } else {
      this.arRenderer.present(group);
    }
    this.arStarted.next(true);
  }

  exportGLB(group: Group, center: boolean): void {
    const gltfExporter = new GLTFExporter();

    const options = {
      binary: true,
      onlyVisible: true,
      maxTextureSize: Infinity,
      includeCustomExtensions: false,
      forceIndices: false,
    } as GLTFExporterOptions;

    if (center) {
      const centeredGroup = this.createCenteredCopy(group);
      gltfExporter.parse(
        centeredGroup,
        // called when the gltf has been generated
        (glb) => {
          const blob = new Blob([options.binary ? (glb as Blob) : JSON.stringify(glb)], {
            type: options.binary ? "application/octet-stream" : "application/json",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "model.glb";
          link.click();
        },
        function (error) {},
        options
      );
      this.removeObjectsWithChildren(centeredGroup);
    } else {
      gltfExporter.parse(
        group,
        // called when the gltf has been generated
        (glb) => {
          const blob = new Blob([options.binary ? (glb as Blob) : JSON.stringify(glb)], {
            type: options.binary ? "application/octet-stream" : "application/json",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "model.glb";
          link.click();
        },
        function (error) {},
        options
      );
    }
  }

  private _addInvisiblePlane(group: Group): Mesh {
    const bbox = new Box3().setFromObject(group);
    const geometry = new PlaneGeometry(1, 1);
    const material = new MeshStandardMaterial({ color: 0xffffff, side: FrontSide, transparent: true, opacity: 0.02 });
    const plane = new Mesh(geometry, material);
    plane.rotation.x = Math.PI / -2;
    plane.scale.x = bbox.max.x - bbox.min.x;
    plane.scale.y = bbox.max.z - bbox.min.z;
    return plane;
  }

  // open in the quick look for iOS
  async openARQuickLook(group: Group, center: boolean, addPlane: boolean = true) {
    let objectURL = null;
    this._addInvisiblePlane(group);
    if (center) {
      const centeredGroup = this.createCenteredCopy(group);
      if (addPlane) {
        const addedPlane = this._addInvisiblePlane(centeredGroup);
        centeredGroup.add(addedPlane);
      }

      objectURL = await this.prepareUSDZ(centeredGroup);
      this.removeObjectsWithChildren(centeredGroup);
    } else {
      let addedPlane = null;
      if (addPlane) {
        addedPlane = this._addInvisiblePlane(group);
        group.add(addedPlane);
      }
      objectURL = await this.prepareUSDZ(group);

      if (addPlane) {
        group.remove(addedPlane!);
      }
    }

    const generateUsdz = true;
    const modelUrl = new URL(objectURL);

    modelUrl.hash += "allowsContentScaling=0";

    const anchor = document.createElement("a");
    anchor.setAttribute("rel", "ar");
    const img = document.createElement("img");
    anchor.appendChild(img);
    anchor.setAttribute("href", modelUrl.toString());
    if (generateUsdz) {
      anchor.setAttribute("download", "model.usdz");
    }

    // attach anchor to shadow DOM to ensure iOS16 ARQL banner click message event propagation
    anchor.style.display = "none";

    this.arStarted.next(true);
    anchor.click();
    anchor.removeChild(img);
    if (generateUsdz) {
      URL.revokeObjectURL(objectURL);
    }
  }

  createCenteredCopy(group: Group): Group {
    // Create a new group to hold the centered copies
    const centeredCopyGroup = new Group();

    // Loop through the child objects of the original group
    group.children.forEach((model) => {
      // Clone the model and add it to the new group
      const centeredCopy = model.clone();
      centeredCopyGroup.add(centeredCopy);
    });

    // Get the bounding box of the entire group
    const bbox = new Box3().setFromObject(centeredCopyGroup);

    // Calculate the center of the bounding box
    const bboxCenter = bbox.getCenter(new Vector3());

    // Calculate the offset to move the group to the origin
    const offset = new Vector3().subVectors(new Vector3(), bboxCenter);
    offset.y = 0;

    // Loop through the child objects of the centered copies group
    centeredCopyGroup.children.forEach((copy) => {
      // Move the copy to the origin in the new group
      copy.position.add(offset);
    });

    // Return the centered copy group
    return centeredCopyGroup;
  }

  // convert scene to USDZ
  async prepareUSDZ(group: Group): Promise<string> {
    const exporter = new USDZExporter();

    // Get the bounding box of the group
    const bbox = new Box3().setFromObject(group);

    // Calculate the center of the bounding box
    const bboxCenter = bbox.getCenter(new Vector3());

    // Calculate the offset to move the group to the origin
    const offset = new Vector3().subVectors(new Vector3(), bboxCenter);

    // Move the group to the origin
    group.position.add(offset);

    const arraybuffer = await exporter.parse(group); // TODO: fix this not to use this.scene

    // Move the group back to its original position
    group.position.sub(offset);

    const blob = new Blob([arraybuffer], {
      type: "model/vnd.usdz+zip",
    });

    const url = URL.createObjectURL(blob);

    return url;
  }

  removeObjectsWithChildren(obj: any) {
    if (obj.children.length > 0) {
      for (var x = obj.children.length - 1; x >= 0; x--) {
        this.removeObjectsWithChildren(obj.children[x]);
      }
    }

    if (obj.geometry) {
      obj.geometry.dispose();
    }

    if (obj.material) {
      if (obj.material.length) {
        for (let i = 0; i < obj.material.length; ++i) {
          if (obj.material[i].map) obj.material[i].map.dispose();
          if (obj.material[i].lightMap) obj.material[i].lightMap.dispose();
          if (obj.material[i].bumpMap) obj.material[i].bumpMap.dispose();
          if (obj.material[i].normalMap) obj.material[i].normalMap.dispose();
          if (obj.material[i].specularMap) obj.material[i].specularMap.dispose();
          if (obj.material[i].envMap) obj.material[i].envMap.dispose();

          obj.material[i].dispose();
        }
      } else {
        if (obj.material.map) obj.material.map.dispose();
        if (obj.material.lightMap) obj.material.lightMap.dispose();
        if (obj.material.bumpMap) obj.material.bumpMap.dispose();
        if (obj.material.normalMap) obj.material.normalMap.dispose();
        if (obj.material.specularMap) obj.material.specularMap.dispose();
        if (obj.material.envMap) obj.material.envMap.dispose();

        obj.material.dispose();
      }
    }

    obj.removeFromParent();

    return true;
  }
}
