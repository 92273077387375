import { Component } from '@angular/core';
import { BuilderService } from '../SERVICES/BuilderService';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-accesories-config',
  templateUrl: './accesories-config.component.html',
  styleUrls: ['./accesories-config.component.scss'],
})
export class AccesoriesConfigComponent {
  public selectedInnerLed: string;

  constructor(public builderService: BuilderService) {
    this.selectedInnerLed = builderService.innerLedOptions[builderService.selectedInnerLed].toLowerCase();
  }

  /*setInnerLeds(event: MatCheckboxChange) {
    this.builderService.setInnerLeds(event.checked);
  }*/

  setInnerLeds(event: MatSelectChange) {
    for (const [index, option] of this.builderService.innerLedOptions.entries()) {
      if (option.toLowerCase() === event.value) {
        this.builderService.setInnerLeds(index);
        break;
      }
    }
  }
}
