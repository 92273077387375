<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header (click)="itemClick($event)">
            <mat-panel-title *ngIf="singleModule; else multiModule">
                Zasteklitev {{moduleSideName}}
              </mat-panel-title>
              <ng-template #multiModule>
                <mat-panel-title>
                    Zasteklitev {{moduleSideName}} - Modul {{moduleName}}
                </mat-panel-title>
              </ng-template>
        </mat-expansion-panel-header>

        <h5>Število oken</h5>
        
        <mat-form-field>
            <mat-select (selectionChange)="updateWindowNumber()" name="windowNumber" [(ngModel)]="windowNumber">
                <mat-option *ngFor="let number of allNumbers" [value]="number">
                    {{number}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <h5>Tip zlaganja</h5>
        
        <mat-form-field>
            <mat-select (selectionChange)="updateClosingType()" name="closingType" [(ngModel)]="closingType">
                <mat-option *ngFor="let type of allTypes" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button (click)="removeWindow()" class="removeButton" mat-button>Odstrani okno</button>

    </mat-expansion-panel>
  </mat-accordion>