
  <div class="panelContainer">
    <mat-grid-list cols="3" rowHeight="1:1" gutterSize="10px">
      <mat-grid-tile style="overflow: visible">
        <div id="image-wrapper" style="overflow: visible">
          <button
            id="addScreenButton"
            mat-button
            class="image-div"
            (click)="addScreenClick()"
            [ngClass]="{'active': addingScreen}">
        
            <div class="buttonContainer">
              <mat-icon>add</mat-icon>
              <span>Dodaj stranski rolo</span>
            </div>
            
          </button>
        </div>
      </mat-grid-tile>

      <mat-grid-tile style="overflow: visible">
        <div id="image-wrapper" style="overflow: visible">
          <button
            id="addWindowButton"
            mat-button
            class="image-div"
            (click)="addWindowClick()"
            [ngClass]="{'active': addingWindow}">
          
            <div class="buttonContainer">
              <mat-icon>add</mat-icon>
              <span>Dodaj stekleno steno</span>
            </div>

          </button>
        </div>
      </mat-grid-tile>

      <mat-grid-tile style="overflow: visible">
        <div id="image-wrapper" style="overflow: visible">
          <button
            id="addCurtainButton"
            mat-button
            class="image-div"
            (click)="addCurtainClick()"
            [ngClass]="{'active': addingCurtain}">
          
            <div class="buttonContainer">
              <mat-icon>add</mat-icon>
              <span>Dodaj zavese</span>
            </div>

          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div class="accessoryConfigTitle">Konfiguracija stranskih dodatkov</div>
  <div *ngIf="isEmpty" class="emptyAccessories">Trenutno ni dodanih dodatkov</div>
  
  <div class="accessoryConfigContainer">
    <ng-container *ngFor="let modulAccessories of modulesAccessories; index as j">
      <ng-container *ngFor="let accessoryList of modulAccessories; index as i">
        <ng-container *ngFor="let accessory of accessoryList; index as z">
          <app-screen-settings *ngIf="accessory?.state && checkInstance(accessory, 'screen')" (deleted)="onAccesorryDeleted()" [singleModule]="moduleNumber === 1" [moduleIndex]="j" [index]="i" [panel]="accessory"></app-screen-settings>
          <app-window-settings *ngIf="accessory?.state && checkInstance(accessory, 'glassdoors')" (deleted)="onAccesorryDeleted()" [singleModule]="moduleNumber === 1" [moduleIndex]="j" [index]="i" [panel]="accessory"></app-window-settings>
          <app-curtain-settings *ngIf="accessory?.state && checkInstance(accessory, 'curtain')" (deleted)="onAccesorryDeleted()" [singleModule]="moduleNumber === 1" [moduleIndex]="j" [index]="i" [panel]="accessory"></app-curtain-settings>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

