import { Component, EventEmitter, Output } from '@angular/core';
import { BuilderService } from '../SERVICES/BuilderService';
@Component({
  selector: 'app-type-selection',
  templateUrl: './type-selection.component.html',
  styleUrls: ['./type-selection.component.scss'],
})
export class TypeSelectionComponent {
  @Output() typeClick = new EventEmitter<void>();
  private _infoShowing = false;

  constructor(private builderService: BuilderService) {}

  onTypeClick(type: string) {
    this.builderService.type = type;
    this.typeClick.emit();
  }

  infoCallback(evt: Event) {
    evt.stopPropagation();
    if (evt.type === 'mouseenter' && window.innerWidth <= 750) return;

    const button = evt.target as HTMLElement;
    const image = button.parentElement?.firstElementChild as HTMLImageElement;
    const text = button.parentElement?.lastElementChild as HTMLDivElement;

    if (!this._infoShowing) {
      image.classList.add('infoImageHover');
      text.classList.add('infoTextHover');
      this._infoShowing = true;
    } else {
      image.classList.remove('infoImageHover');
      text.classList.remove('infoTextHover');
      this._infoShowing = false;
    }
  }

  infoCloseCallback(evt: Event) {
    evt.stopPropagation();
    const button = evt.target as HTMLElement;
    const image = button.parentElement?.firstElementChild as HTMLImageElement;
    const text = button.parentElement?.lastElementChild as HTMLDivElement;

    if (this._infoShowing) {
      image.classList.remove('infoImageHover');
      text.classList.remove('infoTextHover');
      this._infoShowing = false;
    }
  }
}
