import {
  Mesh,
  BoxGeometry,
  MeshStandardMaterial,
  Object3D,
  Vector3,
  Box3,
  CapsuleGeometry,
  MeshBasicMaterial,
} from 'three';
import { IBuilder } from '../interfaces';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ModuleState } from './module_builder';
import { PergolaBuilder } from './pergola_builder';
import { LedBuilder } from './led_builder';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
class Louver extends Object3D {
  private _offset: Vector3 = new Vector3(0, 0, -0.02);

  private _mainPart: Mesh;
  private _rightCover: Mesh;
  private _leftCover: Mesh;

  private _coverSize: Vector3 = new Vector3();

  private _ledGeometry: CapsuleGeometry;
  private _ledMesh: Mesh;
  private _ledSizeOffset = 0.05;
  private _ledPositionOffest = -0.005;

  constructor(
    models: Array<Mesh>,
    private _material: MeshStandardMaterial,
    private _length: number,
    private _ledMaterial: MeshBasicMaterial,
    private _glowPass: OutlinePass
  ) {
    super();

    this._mainPart = models[0].clone();
    this._rightCover = models[1].clone();
    this._leftCover = models[1].clone();

    this.add(this._mainPart);
    this.add(this._rightCover);
    this.add(this._leftCover);

    this._mainPart.material = this._material;
    this._rightCover.material = this._material;
    this._leftCover.material = this._material;
    // get dimensions
    const bboxRightCover = new Box3().setFromObject(this._rightCover);
    this._coverSize = bboxRightCover.getSize(new Vector3(0, 0, 0));

    this._mainPart.position.set(this._offset.x, this._offset.y, this._offset.z);
    this._rightCover.position.set(this._offset.x, this._offset.y, this._offset.z);
    this._leftCover.position.set(this._offset.x, this._offset.y, this._offset.z);
    //this._leftCover.scale.x = -1;

    this._ledGeometry = new CapsuleGeometry(0.005, 1, 5, 20);
    this._ledMesh = new Mesh(this._ledGeometry, this._ledMaterial);
    this._ledMesh.rotation.z = Math.PI / 2;
    this._ledMesh.position.z = 0.1;
    this.add(this._ledMesh);
    this._glowPass.selectedObjects.push(this._ledMesh);
    this._ledMesh.visible = false;
    this._updateDimensions(this._length);
  }

  static async getModels(loader: GLTFLoader): Promise<Array<Mesh>> {
    const [mainModule, leftModule, rightModule] = await Promise.all([
      loader.loadAsync('./assets/builder/KE/louver/lamela.glb'),
      loader.loadAsync('./assets/builder/KE/louver/desno_pokrivalo.glb'),
      loader.loadAsync('./assets/builder/KE/louver/kovinski_zatic.glb'),
    ]);

    const mainPart = mainModule.scene.children[0] as Mesh;
    const leftPart = leftModule.scene.children[0] as Mesh;
    const rightPart = rightModule.scene.children[0] as Mesh;

    mainPart.castShadow = true;
    leftPart.castShadow = true;
    rightPart.castShadow = true;

    mainPart.receiveShadow = true;
    leftPart.receiveShadow = true;
    rightPart.receiveShadow = true;

    return [mainPart, leftPart, rightPart];
  }

  setRotation(rotation: number) {
    this.rotation.x = -rotation;
  }

  setPosition(x: number, y: number, z: number) {
    this.position.set(x, y, z);
  }

  private _updateDimensions(length: number) {
    this._updateLength(length);
  }

  private _updateLength(length: number) {
    this._mainPart.scale.x = (length - this._coverSize.x * 2) / 3;
    this._mainPart.position.x = this._coverSize.x;

    this._rightCover.position.x = length - this._coverSize.x;

    this._ledMesh.scale.y = length - this._coverSize.x * 2 - this._ledSizeOffset;
    this._ledMesh.position.x = length / 2;
    this._ledMesh.position.y = this._ledPositionOffest;
  }

  setLength(length: number) {
    this._length = length;
    this._updateLength(this._length);
  }

  dispose() {
    PergolaBuilder.removeObjectsWithChildren(this._mainPart);
    PergolaBuilder.removeObjectsWithChildren(this._rightCover);
    PergolaBuilder.removeObjectsWithChildren(this._leftCover);
  }

  setLED(status: boolean) {
    this._ledMesh.visible = status;
  }
}

class LouversFrame extends Object3D {
  private _front: Mesh;
  private _leftSide: Mesh;
  private _rightSide: Mesh;
  private _back: Mesh;
  private _bottoms: Mesh[] = [];

  private _frontOffset: Vector3 = new Vector3(0.01, 0, 0);
  private _sidePartSize: Vector3;
  private _sideBottomPartSize: Vector3;

  private _louvers: Louver[] = [];
  private _louversLength: number;
  private _louversStep: number = 0.2;
  private _louversRotation: number = 0;

  private _startLouverOffset: Vector3 = new Vector3(0, -0.095, 0.14);

  private _foldingRatio: number = 0.06;
  private _maxDegress: number = 0.8; // če je vhod med 0 in 100, potem v tem primeru nastavi, da je max degrees na 80 stopinj. Nekak tako more bit glede na teste za KE
  private _ledMaterial: MeshBasicMaterial;
  constructor(
    models: Array<Mesh>,
    private _material: MeshStandardMaterial,
    private _width: number,
    private _length: number,
    private _settings: ModuleState,
    private _louverModels: Array<Mesh>,
    private _glowPass: OutlinePass
  ) {
    super();

    this._front = models[0].clone();
    this._back = models[1].clone();
    this._leftSide = models[2].clone();
    this._rightSide = models[2].clone();

    for (let i = 0; i < 4; i++) {
      const model = models[3].clone();
      model.material = this._material;
      this._bottoms.push(model);
      model.position.y = -this._settings.rafterSettings.height;
      model.rotation.y = (i * Math.PI) / 2;
      this.add(model);
    }

    this._front.material = this._material;
    this._back.material = this._material;
    this._leftSide.material = this._material;
    this._rightSide.material = this._material;

    this.add(this._front);
    this.add(this._leftSide);
    this.add(this._rightSide);
    this.add(this._back);

    this._rightSide.scale.x = -1;

    this._ledMaterial = new MeshBasicMaterial({ color: 0xffffff });

    // get dimensions
    const boundingBoxBottomPart = new Box3().setFromObject(this._leftSide);
    this._sidePartSize = boundingBoxBottomPart.getSize(new Vector3(0, 0, 0));

    const bbBottomPart = new Box3().setFromObject(this._bottoms[0]);
    this._sideBottomPartSize = bbBottomPart.getSize(new Vector3(0, 0, 0));

    this.setDimensions(this._width, this._length);
  }

  setRotation(rotation: number) {
    if (this._settings.louversSettings.type === 'fixed') {
      this._louversRotation = rotation;
    } else {
      this._louversRotation = 100 * (Math.PI / 180.0) * this._maxDegress - rotation * this._maxDegress;
    }

    this.updateLouvers();
  }

  setDimensions(width: number, length: number) {
    this._width = width;
    this._length = length;

    this._front.position.x = width / -2 + this._sidePartSize.x - this._frontOffset.x;
    this._front.scale.x = (width - this._sidePartSize.x * 2 + this._frontOffset.x * 2) / 3;
    this._front.position.z = length / -2;

    this._leftSide.position.x = width / -2;
    this._leftSide.scale.z = length / 3;
    this._leftSide.position.z = length / -2;

    this._rightSide.position.x = width / 2;
    this._rightSide.scale.z = length / 3;
    this._rightSide.position.z = length / -2;

    this._back.position.x = width / -2 + this._sidePartSize.x - this._frontOffset.x;
    this._back.scale.x = (width - this._sidePartSize.x * 2 + this._frontOffset.x * 2) / 3;
    this._back.position.z = length / 2;

    // set bottom parts
    this._bottoms[0].position.x = width / -2 + this._sideBottomPartSize.z;
    this._bottoms[0].position.z = length / -2;
    this._bottoms[0].scale.x = (width - this._sideBottomPartSize.z * 2) / 3;

    this._bottoms[1].position.x = width / -2;
    this._bottoms[1].position.z = length / 2;
    this._bottoms[1].scale.x = length / 3;

    this._bottoms[2].position.x = width / 2 - this._sideBottomPartSize.z;
    this._bottoms[2].position.z = length / 2;
    this._bottoms[2].scale.x = (width - this._sideBottomPartSize.z * 2) / 3;

    this._bottoms[3].position.x = width / 2;
    this._bottoms[3].position.z = length / -2;
    this._bottoms[3].scale.x = length / 3;

    this.updateLouvers();
  }

  updateLouvers() {
    this._louversLength = this._width - this._sidePartSize.x * 2;
    let numLouvers = Math.ceil(this._length / this._louversStep);

    numLouvers -= 1;
    // add new louvers if not enough meshes in the array
    while (this._louvers.length < numLouvers) {
      const louver = new Louver(
        this._louverModels,
        this._material,
        this._louversLength,
        this._ledMaterial,
        this._glowPass
      );
      this.add(louver);
      this._louvers.push(louver);
    }

    for (let i = 0; i < this._louvers.length; i++) {
      this._louvers[i].visible = i < numLouvers;
      if(i >= numLouvers){
        this._louvers[i].setPosition(0,0,0);
        this._louvers[i].setLength(0);
      }
      this._louvers[i].setLED(false);
    }

    this._setPositionSubcalculation(numLouvers);

    switch (this._settings.ledSettings.selectedInnerLed) {
      case 0:
        break;
      case 2:
        this._louvers[Math.floor(numLouvers / 2)].setLED(true);
        this._louvers[Math.floor(numLouvers / 4)].setLED(true);
        this._louvers[Math.floor((3 * numLouvers) / 4)].setLED(true);
        break;
    }
  }

  private _setPositionSubcalculation(numLouvers: number): void {
    const startXSlot = this._length / -2.0; // + this.settings.louversSettings.width / 2.0;

    // set louvers position
    for (let i = 0; i < numLouvers; i++) {
      this._louvers[i].setLength(this._louversLength);

      if (this._settings.louversSettings.type === 'fixed') {
        this._louvers[i].setPosition(
          this._width / -2 + this._sidePartSize.x,
          this._startLouverOffset.y,
          this._startLouverOffset.z + startXSlot + i * this._louversStep
        );
      } else {
        this._louvers[i].setPosition(
          this._width / -2 + this._sidePartSize.x,
          this._startLouverOffset.y,
          this._startLouverOffset.z +
            startXSlot +
            i * this._louversStep * this._foldingRatio +
            i * this._louversStep * (1 - this._foldingRatio) * Math.cos(this._louversRotation)
        );
      }

      // set louvers rotation
      this._louvers[i].setRotation(this._louversRotation);
    }
  }

  static async getModels(loader: GLTFLoader): Promise<Array<Mesh>> {
    const models = await Promise.all([
      loader.loadAsync('./assets/builder/KE/louver/sprednji_nosilec_v2.glb'),
      loader.loadAsync('./assets/builder/KE/louver/zadnji_nosilec.glb'),
      loader.loadAsync('./assets/builder/KE/louver/side.glb'),
      loader.loadAsync('./assets/builder/KE/louver/spodnji_nosilec.glb'),
    ]);

    const meshes: Mesh[] = [];
    models.forEach((model) => {
      const mesModel: Mesh = model.scene.children[0] as Mesh;
      mesModel.castShadow = true;
      mesModel.receiveShadow = true;
      meshes.push(mesModel);
    });

    return meshes;
  }

  dispose() {
    PergolaBuilder.removeObjectsWithChildren(this._front);
    PergolaBuilder.removeObjectsWithChildren(this._back);
    PergolaBuilder.removeObjectsWithChildren(this._leftSide);
    PergolaBuilder.removeObjectsWithChildren(this._rightSide);

    for (const bottom of this._bottoms) {
      PergolaBuilder.removeObjectsWithChildren(bottom);
    }

    for (const louver of this._louvers) {
      louver.dispose();
    }

    this._louvers = [];
    this._bottoms = [];
  }
}

export class LouversBuilder extends Object3D implements IBuilder {
  width: number;
  height: number;
  length: number;
  louversLength: number;

  louversRotation = 0;

  private _frame: LouversFrame;
  settings: ModuleState;

  mainGeometry: BoxGeometry;
  mainMaterial: MeshStandardMaterial;

  louverModels: Array<THREE.Mesh>;
  louverFrameModels: Array<THREE.Mesh>;
  objectHolder = new Object3D();
  public loaded: boolean = false;

  leds: LedBuilder;

  constructor(settings: ModuleState, private _loader: GLTFLoader, private _glowPass: OutlinePass) {
    super();
    this.settings = settings;
    this.height = settings.height;
    this.length = settings.length;
    this.width = settings.width;
  }

  dispose(): void {
    PergolaBuilder.removeObjectsWithChildren(this.mainMaterial);
    PergolaBuilder.removeObjectsWithChildren(this.mainGeometry);
    PergolaBuilder.removeObjectsWithChildren(this.objectHolder);
    this._frame.dispose();

    for (const model of this.louverModels) {
      PergolaBuilder.removeObjectsWithChildren(model);
    }

    for (const frame of this.louverFrameModels) {
      PergolaBuilder.removeObjectsWithChildren(frame);
    }

    this.louverFrameModels = [];
    this.louverModels = [];

    PergolaBuilder.removeObjectsWithChildren(this);

    this.leds.dispose();
  }

  async initObjects(): Promise<void> {
    this.mainMaterial = new MeshStandardMaterial({
      roughness: 0.9,
      metalness: 0.3,
      color: this.settings.louversSettings.color.code,
    });

    this.objectHolder = new Object3D();
    this.add(this.objectHolder);

    this.louverModels = await Louver.getModels(this._loader);
    this.louverFrameModels = await LouversFrame.getModels(this._loader);

    this.leds = new LedBuilder(this.settings);
    this.add(this.leds);

    this._frame = new LouversFrame(
      this.louverFrameModels,
      this.mainMaterial,
      this.settings.width - this.settings.rafterSettings.width * 2,
      this.settings.length - this.settings.rafterSettings.width * 2,
      this.settings,
      this.louverModels,
      this._glowPass
    );

    this._frame.position.y = this.settings.height;
    this.add(this._frame);
    this.loaded = true;
    this.updateDimensions();
    this.updateRotation();
    this.updateColor();
  }

  updateModel() {
    if (!this.loaded) {
      return;
    }

    //if (this.width < this.length) {
    this._frame.rotation.y = 0;
    this._frame.setDimensions(
      this.settings.width - this.settings.rafterSettings.width * 2,
      this.settings.length - this.settings.rafterSettings.width * 2
    );
    /*} else {
      this._frame.rotation.y = Math.PI / 2;
      this._frame.setDimensions(
        this.settings.length - this.settings.rafterSettings.width * 2,
        this.settings.width - this.settings.rafterSettings.width * 2
      );
    }*/

    this._frame.position.y = this.height;
  }

  updateRotation() {
    if (!this.loaded) {
      return;
    }
    this._frame.setRotation(this.settings.louversSettings.rotation * (Math.PI / 180.0));
  }

  updateDimensions() {
    this.width = this.settings.width;
    this.height = this.settings.height;
    this.length = this.settings.length;

    this.updateModel();

    this.leds.updateDimensions();
  }

  updateColor() {
    this.mainMaterial.color.set(this.settings.louversSettings.color.code);
    this.leds.updateColor();
  }
}
