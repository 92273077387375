import { Component, OnDestroy } from '@angular/core';
import { BuilderService } from '../SERVICES/BuilderService';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dimension-config',
  templateUrl: './dimension-config.component.html',
  styleUrls: ['./dimension-config.component.scss'],
})
export class DimensionConfigComponent implements OnDestroy {
  public width: number;
  public height: number;
  public length: number;

  public oldWidth: number = 0;
  public oldHeight: number = 0;
  public oldLength: number = 0;

  public heightLimits: number[];
  public lengthLimits: number[];
  public widthLimits: number[];
  public steps: number[];

  private dimensionSub: Subscription;
  public round = Math.round;

  constructor(public builderService: BuilderService, private snackbar: MatSnackBar) {
    this.heightLimits = this.builderService.sceneBuilder.getHeightLimit().map((el) => el * 100);
    this.lengthLimits = this.builderService.sceneBuilder.getLengthLimit().map((el) => el * 100);
    this.widthLimits = this.builderService.sceneBuilder.getWidthLimit().map((el) => el * 100);

    this.dimensionSub = this.builderService.configDimensionsEmitter.subscribe((dimensions) => {
      this.width = Math.round(dimensions[0] * 100);
      this.length = Math.round(dimensions[1] * 100);
      this.height = Math.round(dimensions[2] * 100);
    });

    if (this.builderService.pergolaDimensions) {
      this.width = Math.round(this.builderService.pergolaDimensions[0] * 100);
      this.length = Math.round(this.builderService.pergolaDimensions[1] * 100);
      this.height = Math.round(this.builderService.pergolaDimensions[2] * 100);
    } else {
      this.builderService.configDimensionsEmitter.emit([
        this.builderService.sceneBuilder.getWidth(),
        this.builderService.sceneBuilder.getLength(),
        this.builderService.sceneBuilder.getHeight(),
      ]);
    }

    this.steps = this.builderService.sceneBuilder.getDimensionSteps().map((el) => el * 100);
  }

  ngOnDestroy(): void {
    this.dimensionSub.unsubscribe();
    this.dimensionSub = Subscription.EMPTY;
  }

  public isInvalidWidth(): boolean {
    return !this.width || this.width < this.widthLimits[0] || this.width > this.widthLimits[1];
  }

  public isInvalidLength(): boolean {
    return !this.length || this.length < this.lengthLimits[0] || this.length > this.lengthLimits[1];
  }

  public isInvalidHeight(): boolean {
    return !this.height || this.height < this.heightLimits[0] || this.height > this.heightLimits[1];
  }

  onWidthChange() {
    if (this.isInvalidWidth()) {
      this.oldWidth = Math.round(this.builderService.sceneBuilder.getWidth() * 100);
      return;
    } else {
      this.oldWidth = 0;
    }

    this.changeDimensions();
  }

  onLengthChange() {
    if (this.isInvalidLength()) {
      this.oldLength = Math.round(this.builderService.sceneBuilder.getLength() * 100);
      return;
    } else {
      this.oldLength = 0;
    }

    if (this.length % this.steps[1] !== 5) {
      const offset = 5 - (this.length % this.steps[1]);
      this.length = Math.round(this.length + offset);
    }

    this.changeDimensions();
  }

  onHeightChange() {
    if (this.isInvalidHeight()) {
      this.oldHeight = Math.round(this.builderService.sceneBuilder.getHeight() * 100);
      return;
    } else {
      this.oldHeight = 0;
    }

    this.changeDimensions();
  }

  changeDimensions() {
    const currentWidth = this.oldWidth ? this.oldWidth : this.width;
    const currentLength = this.oldLength ? this.oldLength : this.length;
    const currentHeight = this.oldHeight ? this.oldHeight : this.height;

    const width = Number((Math.round(currentWidth) / 100).toFixed(2)).valueOf();
    const length = Number((Math.round(currentLength) / 100).toFixed(2)).valueOf();
    const height = Number((Math.round(currentHeight) / 100).toFixed(2)).valueOf();

    this.builderService.configDimensionsEmitter.emit([width, length, height]);
  }

  private showError(val: string, limits: number[]) {
    this.snackbar.open(
      `Napaka pri vnosu: ${val} mora biti med ${Math.round(limits[0])} in ${Math.round(limits[1])} cm!`,
      'Zapri',
      {
        verticalPosition: 'top',
        panelClass: 'customSnackbar',
        duration: 2000,
      }
    );
  }

  incrementWidth(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.width += this.steps[0];
    this.changeDimensions();
  }

  decrementWidth(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.width -= this.steps[0];
    this.changeDimensions();
  }

  incrementLength(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.length += this.steps[1];
    this.changeDimensions();
  }

  decrementLength(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.length -= this.steps[1];
    this.changeDimensions();
  }

  incrementHeight(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.height += this.steps[2];
    this.changeDimensions();
  }

  decrementHeight(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.height -= this.steps[2];
    this.changeDimensions();
  }
}
