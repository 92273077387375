
<div class="container">
  <mat-grid-list [ngStyle]="{ 'width': listWidth }" cols="{{listColNumber}}" rowHeight="{{listRowHeight}}" gutterSize="10px">
    <mat-grid-tile
      *ngFor="let color of availableColors; index as i"
      style="overflow: visible"
    >
      <div id="image-wrapper" style="overflow: visible">
        <button
          id="{{ 'c'+ mode + color.code }}"
          mat-button
          class="image-div"
          (click)="colorClick(color)"
          (keydown)="colorKeyboardClick($event, color)"
          [ngStyle]="{ 'background-color': toColor(color.code) }"
          [ngClass]="getButtonClass(color.code)"
        ></button>
        <span id="color-name">{{ color.name }}</span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<div class="customColorContainer">
  <mat-checkbox [(ngModel)]="customColor">Želim barvo po meri: </mat-checkbox>
  <div class="dimension-input">
  <input [disabled]="!customColor" matInput [(ngModel)]="customColorInput" type="text" placeholder="RAL koda"/>
  </div>
</div>
