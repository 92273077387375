import { Box3, BoxGeometry, DoubleSide, Mesh, MeshStandardMaterial, Object3D, PlaneGeometry, Vector3 } from 'three';
import { IColor, ISideWindows } from '../../interfaces';
import { Side, SideAccessoryState, WindowClosinType } from '../../enums';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PergolaBuilder } from '../pergola_builder';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { SceneController } from 'modules/scene-setup/SceneController';
import { SideAccessory } from './side_accessory';
gsap.registerPlugin(ScrollTrigger);

export class Window extends Object3D {
  private _holder: Mesh;
  private _windowBottom: Mesh;
  private _glass: Mesh;

  private _width: number;
  private _height: number;

  private _holderOffset: number = 0.1;
  private _windowOffset: number = -0.005;
  private _glassThickness: number = 0.005;
  private _holderInset: number = 0.01;
  private _holderHieght: number = 1;

  private _windowHeightSub: number = -0.14;
  private _windowHeigthOffset: number = 0;

  private _glassMaterial: MeshStandardMaterial;

  dispose() {
    PergolaBuilder.removeObjectsWithChildren(this._holder);
    PergolaBuilder.removeObjectsWithChildren(this._windowBottom);
    PergolaBuilder.removeObjectsWithChildren(this._glass);
    PergolaBuilder.removeObjectsWithChildren(this._glassMaterial);
    PergolaBuilder.removeObjectsWithChildren(this);
  }

  constructor(
    private _models: Array<Mesh>,
    private _material: MeshStandardMaterial,
    private _closingType: WindowClosinType
  ) {
    super();
    this._holder = this._models[1].clone();
    this._windowBottom = this._models[3].clone();
    const geometry = new BoxGeometry(1, 1, 1);
    this._glassMaterial = new MeshStandardMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.1,
      side: DoubleSide,
      metalness: 0.95,
      roughness: 0.05,
    });
    this._glass = new Mesh(geometry, this._glassMaterial);

    this.add(this._holder);
    this.add(this._windowBottom);
    this.add(this._glass);

    this._holder.scale.z = 3;

    this._holder.material = this._material;
    this._windowBottom.material = this._material;
  }

  setDimensions(width: number, height: number) {
    this._width = width;
    this._height = height;

    this._windowBottom.position.y = height / -2.0 + 0.01;
    this._windowBottom.position.x = this._width / -2.0;
    this._windowBottom.scale.x = this._width;

    this._glass.scale.set(this._width, this._height + this._windowHeightSub, this._glassThickness);
    this._glass.position.z = this._windowOffset;
    this._glass.position.y = this._windowHeigthOffset;

    if (this._closingType === WindowClosinType.left) {
      this._holder.position.x = width / 2 - this._holderOffset;
    } else {
      this._holder.position.x = width / -2 + this._holderOffset;
    }

    this._holder.position.z = this._holderInset;
    this._holder.position.y = height / -2.0 + this._holderHieght;
  }

  updateState(state: SideAccessoryState): void {
    if (state === SideAccessoryState.added) {
      this._glassMaterial.opacity = 0.5;
    }
    if (state === SideAccessoryState.preview) {
      this._glassMaterial.opacity = 0.1;
    }
    if (state === SideAccessoryState.ghost) {
      this._glassMaterial.opacity = 0.05;
    }
  }

  setClosingType(closingType: WindowClosinType): void {
    this._closingType = closingType;
  }
}

export class GlassDoors extends SideAccessory {
  mainMaterial: MeshStandardMaterial;
  geometry: PlaneGeometry;
  private _windowsNumber: number = 3;
  private _closingType: WindowClosinType = WindowClosinType.left;

  private _bottom: Array<Mesh> = [];
  private _top: Array<Mesh> = [];
  private _windows: Array<Window> = [];

  private _windowStartOffset: number = -0.005;

  private _dimensionsBottomHolder: Vector3;

  private _width: number | number[];
  private _height: number;
  private _strechedOut: number = 0;
  static objectName: string = 'windows';

  private _child: GlassDoors | null = null;
  override _order: number = 2;

  dispose() {
    PergolaBuilder.removeObjectsWithChildren(this.mainMaterial);
    PergolaBuilder.removeObjectsWithChildren(this.geometry);

    for (const bottom of this._bottom) {
      PergolaBuilder.removeObjectsWithChildren(bottom);
    }

    this._bottom = [];

    for (const top of this._top) {
      PergolaBuilder.removeObjectsWithChildren(top);
    }

    this._top = [];

    for (const window of this._windows) {
      window.dispose();
    }

    this._windows = [];

    PergolaBuilder.removeObjectsWithChildren(this);
  }

  constructor(private _models: Array<Mesh>, private _frameColor: IColor) {
    super();

    // init material
    this.mainMaterial = new MeshStandardMaterial({
      color: this._frameColor.code, // Semi-transparent green color
      side: DoubleSide,
      opacity: 0.2,
      transparent: true,
      roughness: 0.8,
      metalness: 0.5,
    });

    // init bottom models
    for (let i = 0; i <= 5; i++) {
      const model = this._models[0].clone();
      model.castShadow = true;

      model.material = this.mainMaterial;
      this._bottom.push(model);
      this.add(model);

      if (i > this._windowsNumber) {
        this._bottom[i].visible = false;
      }
    }

    // init top models
    for (let i = 0; i <= 5; i++) {
      const model = this._models[2].clone();
      model.material = this.mainMaterial;
      model.castShadow = true;

      this._top.push(model);
      this.add(model);

      if (i >= this._windowsNumber) {
        this._top[i].visible = false;
      }
    }

    // init top models
    for (let i = 0; i < 5; i++) {
      const model = new Window(this._models, this.mainMaterial, this._closingType);
      model.castShadow = true;

      this._windows.push(model);
      this.add(model);

      if (i >= this._windowsNumber) {
        this._windows[i].visible = false;
      }
    }

    const boundingBoxHolder = new Box3().setFromObject(this._bottom[0]);
    this._dimensionsBottomHolder = boundingBoxHolder.getSize(new Vector3(0, 0, 0));

    this.geometry = new PlaneGeometry(1, 1);
  }

  override getColor(): IColor {
    return this._frameColor;
  }

  static async getModels(loader: GLTFLoader): Promise<Array<Mesh>> {
    const models = await Promise.all([
      loader.loadAsync('./assets/builder/KE/window/bottomNosilec.glb'),
      loader.loadAsync('./assets/builder/KE/window/holder.glb'),
      loader.loadAsync('./assets/builder/KE/window/topNosilec.glb'),
      loader.loadAsync('./assets/builder/KE/window/windowBottomNosilec.glb'),
    ]);

    const meshes: Mesh[] = [];
    models.forEach((model) => {
      const mesModel: Mesh = model.scene.children[0] as Mesh;
      mesModel.castShadow = true;
      mesModel.receiveShadow = true;
      meshes.push(mesModel);
    });

    return meshes;
  }

  private _setDimensionsMainModel(width: number, height: number, offset: number = 0) {
    for (let i = 0; i <= 5; i++) {
      this._bottom[i].scale.x = width;
      this._bottom[i].position.x = width / -2.0 + offset;
      this._bottom[i].position.y = height / -2.0;
      this._bottom[i].position.z = -this._dimensionsBottomHolder.z * (i);
    }

    for (let i = 0; i <= 5; i++) {
      this._top[i].scale.x = width;
      this._top[i].position.x = width / -2.0 + offset;
      this._top[i].position.y = height / 2.0;
      this._top[i].position.z = -this._dimensionsBottomHolder.z * (i);
    }

    const windowWidth = width / this._windowsNumber;
    if (this._closingType === WindowClosinType.left) {
      for (let i = 0; i < this._windowsNumber; i++) {
        this._windows[i].setDimensions(windowWidth, height);
        this._windows[i].position.x = width / -2.0 + windowWidth / 2 + windowWidth * i * this._strechedOut + offset;
        this._windows[i].position.z = -this._dimensionsBottomHolder.z * (i) + this._windowStartOffset;
      }
    } else {
      for (let i = 0; i < this._windowsNumber; i++) {
        this._windows[i].setDimensions(windowWidth, height);
        this._windows[i].position.x =
          width - (width / -2.0 + windowWidth / 2 + windowWidth * i * this._strechedOut) - width + offset;
        this._windows[i].position.z =
          -this._dimensionsBottomHolder.z * (this._windowsNumber - i) + this._windowStartOffset;
      }
    }
  }

  override setDimensions(width: number | number[], height: number) {
    this._width = width;
    this._height = height;
    if (width instanceof Array) {
      if (this._child === null) {
        this._child = new GlassDoors(this._models, this._frameColor);
        this.add(this._child);
        this._child.updateState(this._state);
        this._child.setStreched(this._strechedOut * 100);
        this._child.setClosingType(this._closingType);
      }
      this._child.visible = true;
      this._setDimensionsMainModel(width[0], height, width[0] / -2 - 0.16 / 2);
      this._child.setDimensions(width[1], height);
      this._child.position.x = width[1] / 2 + 0.16 / 2;
    } else {
      if (this._child !== null) {
        this._child.visible = false;
      }
      this._setDimensionsMainModel(width, height);
    }
  }

  setStreched(percentage: number) {
    this._strechedOut = percentage / 100;
    this.setDimensions(this._width, this._height);
    if (this._child !== null) {
      this._child.setStreched(this._strechedOut * 100);
    }
  }

  override updateState(state: SideAccessoryState): void {
    this._state = state;
    if (this._state === SideAccessoryState.preview) {
      this.mainMaterial.opacity = 0.5;
      this;
    }
    if (this._state === SideAccessoryState.added) {
      this.mainMaterial.opacity = 1;
    }
    if (this._state === SideAccessoryState.ghost) {
      this.mainMaterial.opacity = 0.1;
    }

    for (let i = 0; i < 5; i++) {
      this._windows[i].updateState(state);
    }

    if (this._child !== null) {
      this._child.updateState(this._state);
    }
  }

  getWindowsNumber(): number {
    return this._windowsNumber;
  }

  getClosingType(): WindowClosinType {
    return this._closingType;
  }

  override updateColor(color: IColor) {
    this._frameColor = color;
    this.mainMaterial.color.set(color.code);
    if (this._child !== null) {
      this._child.updateColor(this._frameColor);
    }
  }

  setWindowsNumber(value: number) {
    this._windowsNumber = value;
    for (let i = 0; i <= 5; i++) {
      this._bottom[i].visible = i <= this._windowsNumber;
      this._top[i].visible = i <= this._windowsNumber;
    }

    this.setDimensions(this._width, this._height);

    for (let i = 0; i < 5; i++) {
      this._windows[i].visible = i < this._windowsNumber;
    }

    if (this._child !== null) {
      this._child.setWindowsNumber(this._windowsNumber);
    }
  }

  override focus(side: Side, sceneController: SceneController) {
    const bbox = new Box3().setFromObject(this);
    const bboxCenter: Vector3 = new Vector3();
    bbox.getCenter(bboxCenter);

    let val = bboxCenter.x;
    if (side === Side.front || side === Side.back) val = bboxCenter.z;

    gsap.to(sceneController.camera.position, {
      duration: this._animationDuration,
      x: bboxCenter.x + (val === bboxCenter.x ? 10 * Math.sign(val) : 0),
      y: bboxCenter.y,
      z: bboxCenter.z + (val === bboxCenter.z ? 10 * Math.sign(val) : 0),
    });

    gsap.to(sceneController.orbitControls.target, {
      duration: this._animationDuration,
      x: 0,
      y: 0,
      z: 0,
    });
  }

  setClosingType(type: WindowClosinType) {
    this._closingType = type;
    for (let i = 0; i < 5; i++) {
      this._windows[i].setClosingType(type);
    }
    this.setDimensions(this._width, this._height);
  }

  override getState(): ISideWindows {
    return {
      type: GlassDoors.objectName,
      numberWindows: this._windowsNumber,
      closingSide: this._closingType,
    };
  }
}
