<mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header (click)="itemClick($event)">
        <mat-panel-title *ngIf="singleModule; else multiModule">
          Zavesa {{moduleSideName}}
        </mat-panel-title>
        <ng-template #multiModule>
          <mat-panel-title>
            Zavesa {{moduleSideName}} - Modul {{moduleName}}
          </mat-panel-title>
        </ng-template>
      </mat-expansion-panel-header>
      <h5>Barva zavese</h5>

      <div class="buttonContainer">
        <button mat-mini-fab 
          *ngFor="let color of colorOptions; index as i" 
          (click)="changeColor(i)" 
          [ngClass]="{'activeSelection': selectedColor === color}"
          [ngStyle]="{'background-color': color}"></button>
      </div>

      <button class="removeButton" (click)="removeCurtain()" mat-button>Odstrani zaveso</button>
    </mat-expansion-panel>
  </mat-accordion>