import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypeSelectionComponent } from './type-selection/type-selection.component';
import { LayoutSelectionComponent } from './layout-selection/layout-selection.component';
import { DimensionConfigComponent } from './dimension-config/dimension-config.component';
import { ColorConfigComponent } from './color-config/color-config.component';
import { ScreenConfigComponent } from './screen-config/screen-config.component';
import { EnvironmentConfigComponent } from './environment-config/environment-config.component';
import { AccesoriesConfigComponent } from './accesories-config/accesories-config.component';
import { SubmitFormComponent } from './submit-form/submit-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ViewerComponent } from './viewer/viewer.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { ScreenSettingsComponent } from './screen-settings/screen-settings.component';
import { WindowSettingsComponent } from './window-settings/window-settings.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WallColorConfigComponent } from './wall-color-config/wall-color-config.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { ComponentLoaderComponent } from 'modules/loader/component-loader.component';
import { ARComponent } from 'modules/ar_module/component/ar-qr-code.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CookiesComponent } from './cookies/cookies.component';
import { CurtainSettingsComponent } from './curtain-settings/curtain-settings.component';
import { AutoFocusDirective } from './DIRECTIVES/auto-focus.directive';
import { VideoOverlayComponent } from './video-overlay/video-overlay.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    TypeSelectionComponent,
    LayoutSelectionComponent,
    DimensionConfigComponent,
    ColorConfigComponent,
    ScreenConfigComponent,
    EnvironmentConfigComponent,
    AccesoriesConfigComponent,
    SubmitFormComponent,
    ViewerComponent,
    ScreenSettingsComponent,
    WindowSettingsComponent,
    WallColorConfigComponent,
    QrCodeComponent,
    ComponentLoaderComponent,
    ARComponent,
    ConfirmationDialogComponent,
    CookiesComponent,
    CurtainSettingsComponent,
    AutoFocusDirective,
    VideoOverlayComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    HttpClientModule,
    MatGridListModule,
    FormsModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDividerModule,
    ColorPickerModule,
    MatExpansionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    QRCodeModule,
    MatSnackBarModule,
    MatDialogModule,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
