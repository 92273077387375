import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ColorConfigMode } from '../builders/enums';
import { BuilderService } from '../SERVICES/BuilderService';
import { inflate } from 'pako';
import { Observable, first, firstValueFrom } from 'rxjs';
import { LoaderService } from 'modules/loader/loader.service';
import { ARDataType, ARViewer } from 'modules/ar_module';
import { SceneService } from 'modules/scene-setup/scene.service';
import { BackendService } from '../SERVICES/BackendService';
import { ActivatedRoute } from '@angular/router';
import { IPergolaState } from '../builders/interfaces';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Cache } from 'three';

enum NavigationState {
  Types,
  Layouts,
  Dimensions,
  Colors,
  Screens,
  Accessories,
  Environment,
  Submit,
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  navigationState = NavigationState;

  public loading: Observable<boolean>;
  public currentState: NavigationState = NavigationState.Types;
  public previousState: NavigationState;

  public colorConfigMode = ColorConfigMode;
  public qrUrl: string = '';

  private arViewer: ARViewer;
  public hideWallColorPicker = true;
  private _runAROnStart = false;

  private _clickedSettings = false;
  private _clickedAR = false;
  private _showVideo = false;
  private _showVideo2 = false;
  public showVideo = false;
  public showVideo2 = false;

  public formSubmitted: boolean = false;
  public showCloseButton = false;

  private dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  @ViewChild('stepper') stepper!: MatStepper;

  constructor(
    public builderService: BuilderService,
    public loaderService: LoaderService,
    public sceneService: SceneService,
    public backendService: BackendService,
    public dialogService: MatDialog,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.loading = this.loaderService.loadingObservable;
    Cache.enabled = true;
    this.initAR();

    this.loadConfigurationFromURL().then((res: IPergolaState | null) => {
      if (res !== null) {
        this.builderService.type = res.pergolaType;
        this.backendService.loadSettings(this.builderService).then(() => {
          this.builderService.setState(res);
          this.onNext(this.stepper);
        });
      }
    });

    window.onpopstate = () => {
      this.onBack(this.stepper);
      if (this.stepper.selectedIndex) history.pushState(null, '', location.href);
    };

    this.handleCookies();
  }

  private handleCookies(): void {
    let cookiesConsent: string | null = null;

    if (this.cookieService.check('cookie-consent')) {
      cookiesConsent = this.cookieService.get('cookie-consent');
      if (cookiesConsent === 'accept') {
        this.addGtmAndClarityScripts();
      }
    } else {
      const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
        width: '100%',
        maxWidth: '100vw',
        disableClose: true,
        hasBackdrop: false,
        closeOnNavigation: false,
        position: { bottom: '0px' },
      });

      dialogRef.componentInstance.dialogOptions = {
        title: '',
        message:
          'Ta spletna stran uporablja piškotke. Z obiskom in uporabo te spletne strani se strinjate z uporabo in beleženjem piškotkov. ',
        icon: 'cookie',
        button: 'Sprejmem',
        color: 'var(--orangeAccent)',
        cancel: true,
        cancelText: 'Zavrnem',
        navigation: '/cookies',
        class: 'dialog-row',
        xStyle: 'display: none;',
        msgStyle: 'margin: 0px;',
        iconStyle: 'margin: 0px; display: none;',
      };

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((result) => {
          if (result === true) {
            this.cookieService.set('cookie-consent', 'accept', 30);
            this.addGtmAndClarityScripts();
          } else if (result === false) {
            this.cookieService.set('cookie-consent', 'decline', 30);
          }
        });
    }
  }

  private addGtmAndClarityScripts(): void {
    if (!environment.production) {
      return;
    }
    // Add GTM script
    const gtmScript = this.renderer.createElement('script');
    gtmScript.type = 'text/javascript';
    gtmScript.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PSHWX285');`;

    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, gtmScript);

    // Add Clarity script
    const clarityScript = this.renderer.createElement('script');
    clarityScript.type = 'text/javascript';
    clarityScript.textContent = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hpwb5to9e9");
    `;
    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, clarityScript);

    const iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(iframe, 'src', 'https://www.googletagmanager.com/ns.html?id=GTM-PSHWX285');
    this.renderer.setAttribute(iframe, 'height', '0');
    this.renderer.setAttribute(iframe, 'width', '0');
    this.renderer.setStyle(iframe, 'display', 'none');
    this.renderer.setStyle(iframe, 'visibility', 'hidden');

    const noscript = this.renderer.createElement('noscript');
    this.renderer.appendChild(noscript, iframe);

    const body = this.el.nativeElement.ownerDocument.body;
    if (body.firstChild) {
      this.renderer.insertBefore(body, noscript, body.firstChild);
    } else {
      this.renderer.appendChild(body, noscript);
    }

    const gaScript = this.renderer.createElement('script');
    gaScript.type = 'text/javascript';
    gaScript.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-Q4Q61VG0ER');
    `;

    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, gaScript);
  }

  private initAR() {
    this.arViewer = new ARViewer(null, '/assets/neutral.hdr');
    this.arViewer.arStartedObservable.subscribe((started) => {
      if (started) {
        this.loaderService.loading = false;
      }
    });

    this.arViewer.arReplayObservable.subscribe((event) => {
      const model = this.builderService.sceneBuilder.getPergola3Dmodel();
      model.position.set(0, 0, 0);
      this.builderService.sceneBuilder.add(model);
      this._processARMessage(event);
    });
  }

  public confirmARStart(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;

    this.dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
      disableClose: false,
    });

    this.dialogRef.componentInstance.dialogOptions = {
      title: 'Navodila za uporabo',
      message:
        'Za pravilno postavitev pergole v prostor je potrebno poskrbeti za dobro osvetlitev in zadostno velikost prostora.',
      icon: 'info',
      button: 'Postavi v prostor',
      color: 'var(--orangeAccent)',
      cancel: true,
    };

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!this.arViewer.isInitRenderer()) {
          this.arViewer.setRenderer(this.sceneService.sceneController.renderer);
        }

        this.arViewer.openAR(this.builderService.sceneBuilder.getPergola3Dmodel()).then(() => {
          this.loaderService.loading = false;
        });
      } else {
        this.loaderService.loading = false;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component.dialogRef = null as any;
    });
  }

  private _showUnsupportedBrowserMessage() {
    const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
      disableClose: false,
    });
    if (this.arViewer.isIOS()) {
      dialogRef.componentInstance.dialogOptions = {
        title: 'Opozorilo',
        message:
          'Brskalnik, katerega uporabljate ne podpira ogleda v obogateni resničnosti. Za uporabo te funkcije, prosim odprite konfigurator v katerem drugem brskalniku (npr. Chrome).',
        icon: 'error_outline',
        button: 'Razumem',
        color: '#FF6D6A',
        cancel: false,
      };
    } else {
      const url = this.getConfigurationUrl().replace('https://', '');
      dialogRef.componentInstance.dialogOptions = {
        title: 'Opozorilo',
        message: `Brskalnik, katerega uporabljate ne podpira ogleda v obogateni resničnosti. Za uporabo te funkcije, prosim odprite konfigurator v katerem drugem brskalniku. <a href="intent://${url}/#Intent;scheme=http;package=com.android.chrome;end">Kliknite tukaj, da odprete konfiguracijo v drugem brskalniku.</a>`,
        icon: 'error_outline',
        button: 'Razumem',
        color: '#FF6D6A',
        cancel: false,
      };
    }
  }

  private _processARMessage(replay: ARDataType) {
    // show error message
    if (replay.error) {
      this._showUnsupportedBrowserMessage();
    }
  }

  public onNext(stepper: MatStepper) {
    const state = document.getElementById('toggle-menu')?.style.bottom;
    if (state === '0px') document.getElementById('toggle-menu')?.click();

    const menu = document.getElementById('nav-side-menu');
    if (menu !== null) menu.scrollTop = 0;

    if (this._clickedSettings) {
      this.currentState = this.previousState;
      this._clickedSettings = false;
      this.showCloseButton = false;
      return;
    }

    const values = Object.values(NavigationState);
    const map = new Map(values.map((k, i) => [k, values[i + 1]]));
    const next = map.get(this.currentState) as NavigationState;

    if (next !== undefined) {
      /*if(next === NavigationState.Submit && !this._clickedAR) {
        this.onARClick();
        return
      }*/

      stepper.next();
      this.currentState = next;
    }
  }

  public onBack(stepper: MatStepper) {
    const state = document.getElementById('toggle-menu')?.style.bottom;

    const menu = document.getElementById('nav-side-menu');
    if (menu !== null) menu.scrollTop = 0;

    if (state === '0px') document.getElementById('toggle-menu')?.click();

    if (this._clickedSettings) {
      this.currentState = this.previousState;
      this._clickedSettings = false;
      this.showCloseButton = false;
      return;
    }

    const values = Object.values(NavigationState);
    values.splice(1, 2);
    const map = new Map(values.map((k, i) => [k, values[i - 1]]));
    const prev = map.get(this.currentState) as NavigationState;

    if (prev !== undefined) {
      if (this.currentState === NavigationState.Layouts) {
        this.confirmReset(prev);
      } else if (this.currentState === NavigationState.Submit) {
        this.showVideo = false;
        this.showVideo2 = false;
        this.qrUrl = '';
        stepper.previous();
        this.currentState = prev;
      } else {
        stepper.previous();
        this.currentState = prev;
      }
    }
  }

  public confirmReset(prev: NavigationState): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;

    this.dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
      disableClose: false,
    });

    this.dialogRef.componentInstance.dialogOptions = {
      title: 'Želite zapustiti urejanje?',
      message: 'Ste prepričani, da želite zapustiti urejanje in zbrisati trenutno konfiguracijo?',
      icon: 'error_outline',
      button: 'Zapusti',
      color: '#FF6D6A',
      cancel: true,
    };

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.currentState = prev;
        this.stepper.reset();
        this._clickedSettings = false;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component.dialogRef = null as any;
    });
  }

  onVideoClose() {
    this.showVideo = false;
    this.onARClick(true);
  }

  onVideoClose2() {
    this.showVideo2 = false;
    this.onARClick();
  }

  onARClick(calledThroughButton?: boolean) {
    this._clickedAR = true;

    /*if(!this._showVideo2 && !calledThroughButton) {
      this._showVideo2 = true;
      this.showVideo2 = true;
      return;
    }

    if(!this._showVideo) {
      this._showVideo = true;
      this.showVideo = true;
      return;
    }*/

    if (this._isFacebookBrowser()) {
      this._showUnsupportedBrowserMessage();
      return;
    }
    this.loaderService.loading = true;

    if (this.arViewer.canOpenAR()) {
      this.confirmARStart();
    } else {
      this.loaderService.loading = false;
      this.qrUrl = this.getConfigurationUrl();
    }
  }

  private _isFacebookBrowser(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    return userAgent.includes('FB') || userAgent.includes('FBAV') || userAgent.includes('Instagram');
  }

  onEnvironmentClick() {
    const state = document.getElementById('toggle-menu')?.style.bottom;

    if (this.currentState === NavigationState.Environment && !this._clickedSettings) {
      return;
    }

    if (this.currentState !== NavigationState.Environment) {
      this._clickedSettings = true;
      this.previousState = this.currentState;
      this.currentState = NavigationState.Environment;
      this.showCloseButton = true;
    }

    /* if (this.currentState === NavigationState.Environment) {
      this.currentState = this.previousState;
      this._clickedSettings = false;
      this.showCloseButton = false;
    } else {
      this._clickedSettings = true;
      this.previousState = this.currentState;
      this.currentState = NavigationState.Environment;
      this.showCloseButton = true;
    } */

    if (state === '0px') {
      document.getElementById('toggle-menu')?.click();
    }
  }

  onEnvironmentClose() {
    this.currentState = this.previousState;
    this._clickedSettings = false;
    this.showCloseButton = false;
  }

  submitForm() {
    this.backendService.submitEventEmmiter.emit();
  }

  private getConfigurationUrl(): string {
    return this.backendService.getConfigurationUrl(this.builderService);
  }

  private async loadConfigurationFromURL(): Promise<IPergolaState | null> {
    const params = await firstValueFrom(this.route.queryParams);

    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty('ar')) {
      this._runAROnStart = params['ar'] === 'true';
    }

    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty('data')) {
      const compressedString = params['data'];
      // Decode the compressed string from base64 to an array of numbers
      const compressedNumberArray = atob(compressedString)
        .split('')
        .map((char) => char.charCodeAt(0));

      // Decompress the array of numbers to get the original JSON string
      const decompressedByteArray = inflate(new Uint8Array(compressedNumberArray));
      const jsonString = new TextDecoder().decode(decompressedByteArray);
      const ret = JSON.parse(jsonString) as IPergolaState;
      return ret;
    }

    return null;
  }

  toggleMenu() {
    const menu = document.getElementById('nav-side-menu');
    const button = document.getElementById('toggle-menu');

    if (menu && button) {
      if (menu.clientHeight) {
        menu.classList.add('close-menu');
        button.style.bottom = '0';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (button.firstChild! as HTMLDivElement).style.transform = 'rotate(180deg)';
      } else {
        menu.classList.remove('close-menu');
        button.style.bottom = 'var(--mobileMenuHeight)';
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (button.firstChild! as HTMLDivElement).style.transform = 'rotate(0)';
      }
    }
  }

  typeClicked(stepper: MatStepper) {
    this.loaderService.loading = true;

    this.backendService.loadSettings(this.builderService).then(() => {
      if (
        this.builderService.settings.availableLayoutsStr.length === 1 &&
        this.builderService.settings.availableLayoutsStr[0].setting.length === 0
      ) {
        this.hideWallColorPicker = false;
      } else {
        this.hideWallColorPicker = true;
      }

      this.loaderService.loading = false;
      this.onNext(stepper);
    });
  }

  onLoadingStatusChange(loading: boolean) {
    // Handle the loading status change here
    if (!loading && this._runAROnStart) {
      if (this.arViewer.canOpenAR()) {
        if (ARViewer.getDeviceType() === 'ios') {
          this.onARClick();
        } else if (ARViewer.getDeviceType() === 'android') {
          const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            disableClose: false,
          });
          dialogRef.componentInstance.dialogOptions = {
            title: 'Info',
            message: 'Želite pogledati pergolo v vašem prostoru?',
            icon: 'info_outline',
            button: 'Da',
            cancelText: 'Ne',
            color: '#FF6D6A',
            cancel: true,
          };

          dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
              if (result === true) {
                this.onARClick();
              }
            });
        }
      }
    }
  }

  onFormSubmitted() {
    this.formSubmitted = true;
  }
}
