<div *ngIf="!submitted; else successBlock" class="container">
    <h1><span>Hvala</span> za uporabo konfiguratorja!</h1>
    <h2>Prosimo izpolnite obrazec, za pridobitev ponudbe!</h2>

    <form id="submitForm" class="submit-form">
        <mat-form-field class="form-full-width">
            <mat-label for="name">Ime</mat-label>
            <input required matInput type="text" autocomplete="off" id="name" [(ngModel)]="model.name" name="name">
        </mat-form-field>

        <mat-form-field class="form-full-width">
            <mat-label for="surname">Priimek</mat-label>
            <input required matInput type="text" autocomplete="off" id="surname" [(ngModel)]="model.surname" name="surname">
        </mat-form-field>

        <mat-form-field class="form-full-width">
            <mat-label for="address">Naslov</mat-label>
            <input required matInput type="text" autocomplete="off" id="address" [(ngModel)]="model.address" name="address">
        </mat-form-field>

        <mat-form-field class="form-full-width">
            <mat-label for="city">Kraj</mat-label>
            <input required matInput type="text" autocomplete="off" id="city" [(ngModel)]="model.city" name="city">
        </mat-form-field>

        <mat-form-field class="form-full-width">
            <mat-label for="email">Email</mat-label>
            <input 
                required pattern="(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+"
                matInput
                type="email" 
                autocomplete="off" 
                id="email" 
                [(ngModel)]="model.email" 
                name="email">
        </mat-form-field>

        <mat-form-field class="form-full-width">
            <mat-label for="phoneNumber">Telefonska številka</mat-label>
            <input required matInput type="text" autocomplete="off" id="phoneNumber" [(ngModel)]="model.phoneNumber" name="phoneNumber">
        </mat-form-field>
        
        <mat-form-field class="form-full-width">
            <mat-label for="comment">Pustite dodaten komentar</mat-label>
            <textarea matInput type="textarea" autocomplete="off" id="comment" [(ngModel)]="model.comment" name="comment"></textarea>
        </mat-form-field>
    </form>
</div>

<ng-template #successBlock>
    <div class="container">
        <img id="logo" src="../../assets/sencila_logo.png"/>
        <h1 id="succesTittle"><span>Hvala</span> za uporabo konfiguratorja!</h1>
        <h3>Povpraševanje uspešno oddano!</h3>
        <p>Hvala za poslano povpraševanje. Osnutek povpraševanja Vam bomo posredovali tudi po elektronski pošti.</p>
        <p>Ponudbo za povpraševanje prejmete v čim krajšem možnem času!</p>
        <button mat-flat-button (click)="onReset()">Ustvarite novo konfiguracijo</button>
    </div>
</ng-template>