export function numberToHexString(value: number): string {
  const hexString = value.toString(16).padStart(6, '0');
  return `#${hexString.toUpperCase()}`;
}

export function hexStringToNumber(hexString: string): number {
  // Remove the '#' symbol if present
  const cleanHexString = hexString.startsWith('#') ? hexString.slice(1) : hexString;

  // Convert the hex string to a number
  const numberValue = parseInt(cleanHexString, 16);

  return numberValue;
}
