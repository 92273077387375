import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SideAccessory } from '../builders/KE_builder/side_accessories/side_accessory';
import { BuilderService } from '../SERVICES/BuilderService';
import { SceneService } from 'modules/scene-setup/scene.service';
import { GlassDoors } from '../builders/KE_builder/side_accessories/glass_doors';

@Component({
  selector: 'app-window-settings',
  templateUrl: './window-settings.component.html',
  styleUrls: ['./window-settings.component.scss'],
})
export class WindowSettingsComponent {
  @Input() panel: SideAccessory;
  @Input() index: number;
  @Input() moduleIndex: number;
  @Input() singleModule: boolean;

  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(public builderService: BuilderService, private sceneService: SceneService) {}

  public windowNumber: string = '3';
  public closingType: string = 'Levo';

  public allNumbers: string[] = ['3', '4', '5'];
  public allTypes: string[] = ['Levo', 'Desno']; //, 'Harmonika'];
  public get moduleName(): string {
    const moduleNames = ['A', 'B', 'C', 'D'];
    return moduleNames[this.moduleIndex];
  }

  public get moduleSideName(): string {
    const moduleNames = ['spredaj', 'desno', 'zadaj', 'levo'];
    return moduleNames[this.index];
  }

  removeWindow() {
    if (this.panel) {
      this.builderService.sceneBuilder.removePanel(this.panel);
      this.sceneService.sceneController.requestRenderIfNotRequested();
      this.deleted.emit();
    }
  }

  updateWindowNumber() {
    if (this.panel) {
      this.builderService.sceneBuilder.updateWindowNumber(this.panel as GlassDoors, Number(this.windowNumber));
      this.sceneService.sceneController.requestRenderIfNotRequested();
    }
  }

  updateClosingType() {
    if (this.panel) {
      this.builderService.sceneBuilder.updateWindowClosingType(this.panel as GlassDoors, this.closingType);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    }
  }

  itemClick(evt: MouseEvent) {
    evt.stopPropagation();
    if ((evt as PointerEvent).pointerId === -1) return;

    const elements = document.querySelectorAll('mat-expansion-panel-header');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elements.forEach((el: any) => {
      if (evt.currentTarget !== el && (el as HTMLDivElement).classList.contains('mat-expanded')) el.click();
    });

    const opened = (evt.currentTarget as HTMLDivElement).classList.contains('mat-expanded');

    if (!opened) {
      if (this.panel) {
        this.windowNumber = (this.panel as GlassDoors).getWindowsNumber().toString();
      }
      const side = this.builderService.sceneBuilder.getPanelSide(this.panel);
      if (side !== undefined) {
        this.builderService.sceneBuilder.focusElement(this.panel, side, this.sceneService.sceneController);
        this.sceneService.sceneController.requestRenderIfNotRequested();
      }
    }
  }
}
