import { EventEmitter, Injectable } from '@angular/core';
import { SceneBuilder } from '../builders/scene_builder';
import { Floor, IColor, IPergolaState, Layout } from '../builders/interfaces';
import { PergolaSettings } from '../builders/KE_builder/pergola_settings';
import { CurrentTime } from '../builders/enums';
import { SceneService } from 'modules/scene-setup/scene.service';

@Injectable({
  providedIn: 'root',
})
export class BuilderService {
  // Trenutno samo 1 hardcodan tip
  public type: string;

  public time: CurrentTime | null;

  public settings: PergolaSettings;
  public sceneBuilder: SceneBuilder;

  public selectedLayout: Layout | null;
  public configLayoutEmitter = new EventEmitter<Layout>();

  public pergolaDimensions: number[] | null;
  public configDimensionsEmitter = new EventEmitter<number[]>();

  public selectedColor: IColor | null;
  public configColorEmitter = new EventEmitter<IColor>();

  public configFrameColorEmitter = new EventEmitter<IColor>();
  public configLouverColorEmitter = new EventEmitter<IColor>();

  public selectedWallColor: IColor | null;
  public configWallColorEmitter = new EventEmitter<IColor>();

  constructor(private sceneService: SceneService) {
    this.configLayoutEmitter.subscribe((layout) => {
      this.selectedLayout = layout;
      this.sceneBuilder.setWalls(layout.setting);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    });

    this.configDimensionsEmitter.subscribe((dimensions) => {
      this.pergolaDimensions = dimensions;
      this.sceneBuilder.setDimensions(dimensions[0], dimensions[1], dimensions[2]);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    });

    this.configColorEmitter.subscribe((color) => {
      this.selectedColor = color;
      this.sceneBuilder.setColor(color);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    });

    this.configWallColorEmitter.subscribe((color) => {
      this.selectedWallColor = color;
      this.sceneBuilder.setWallsColor(color);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    });

    this.configFrameColorEmitter.subscribe((color) => {
      this.sceneBuilder.setFrameColor(color);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    });

    this.configLouverColorEmitter.subscribe((color) => {
      this.sceneBuilder.setLouversColor(color);
      this.sceneService.sceneController.requestRenderIfNotRequested();
    });
  }

  public get selectedLouverColor(): IColor {
    return this.sceneBuilder.settings.louversSettings.color;
  }

  public get selectedFrameColor(): IColor {
    return this.sceneBuilder.settings.postSettings.color;
  }

  /*public get hasInnerLeds(): boolean {
    return this.sceneBuilder.settings.ledSettings.hasInner;
  }*/

  public get selectedInnerLed(): number {
    return this.sceneBuilder.settings.ledSettings.selectedInnerLed;
  }

  public get innerLedOptions(): string[] {
    return this.sceneBuilder.settings.ledSettings.innerLedOptions;
  }

  /* public setInnerLeds(val: boolean): void {
    this.sceneBuilder.setInnerLeds(val, this.sceneService.sceneController.outlinePass);
    this.sceneService.sceneController.requestRenderIfNotRequested();
  } */

  public setInnerLeds(val: number): void {
    this.sceneBuilder.setInnerLeds(val, this.sceneService.sceneController.outlinePass);
    this.sceneService.sceneController.requestRenderIfNotRequested();
  }

  public setTime(time: CurrentTime): void {
    this.sceneBuilder.setTime(time).then((time) => {
      this.time = time;
      this.sceneService.sceneController.requestRenderIfNotRequested();
    })
  }

  getState(): IPergolaState {
    const moduleStates = this.sceneBuilder.getModuleStates();

    const state: IPergolaState = {
      pergolaType: this.type,
      pergolaLayout: this.selectedLayout,
      width: this.settings.width,
      length: this.settings.length,
      height: this.settings.height,
      frameColor: this.selectedFrameColor,
      louversColor: this.selectedLouverColor,
      louversRotation: this.settings.louversSettings.rotation,
      windowStretch: this.settings.windowStreched,
      screenStretch: this.settings.screenStreched,
      selectedFloor: this.settings.selectedFloor,
      nightDayMode: this.time,
      ledSettings: this.sceneBuilder.settings.ledSettings,
      otherSensorsSettings: {
        sunSensor: this.sceneBuilder.settings.otherSensorsSettings.sunSensor,
        rainSensor: this.sceneBuilder.settings.otherSensorsSettings.rainSensor,
        windSensor: this.sceneBuilder.settings.otherSensorsSettings.windSensor,
      },
      modules: moduleStates,
      walls: this.settings.wallSettings,
    };

    return state;
  }

  setState(state: IPergolaState) {
    this.type = state.pergolaType;
    this.selectedLayout = state.pergolaLayout;
    this.time = state.nightDayMode;
    this.settings.width = state.width;
    this.settings.length = state.length;
    this.settings.height = state.height;
    this.settings.rafterSettings.color = state.frameColor;
    this.settings.postSettings.color = state.frameColor;
    this.settings.louversSettings.color = state.louversColor;
    this.settings.louversSettings.rotation = state.louversRotation;
    this.settings.windowStreched = state.windowStretch;
    this.settings.screenStreched = state.screenStretch;
    this.settings.selectedFloor = state.selectedFloor;
    this.settings.ledSettings = state.ledSettings;
    this.settings.otherSensorsSettings.sunSensor = state.otherSensorsSettings.sunSensor;
    this.settings.otherSensorsSettings.rainSensor = state.otherSensorsSettings.rainSensor;
    this.settings.otherSensorsSettings.windSensor = state.otherSensorsSettings.windSensor;
    this.settings.wallSettings = state.walls;
    this.settings.startModulesAccesories = state.modules;
  }

  public getTime(): number {
    if(this.time !== null)
      return this.time;
    return -1;
  }

  public getSelectedFloor(): Floor {
    return this.settings.selectedFloor;
  }
}
