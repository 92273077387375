  <div class="accessoryContainer">
    <div class="title">LED osvetlitev</div>
    <!--div class="checkboxContainer">
      <mat-checkbox id="insideLedCheckbox" (change)="setInnerLeds($event)" [checked]="builderService.hasInnerLeds">Notranji:</mat-checkbox>
    </div-->
    <div class="checkboxContainer" style="margin-top: 20px;">
      <mat-form-field style="flex-basis: 100%;">
        <mat-select [value]="selectedInnerLed" (selectionChange)="setInnerLeds($event)" name="innerLeds">
            <mat-option *ngFor="let led of builderService.innerLedOptions" [value]="led.toLowerCase()">
                {{led}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    </div>
  </div>

  <div class="accessoryContainer">
    <div class="title">Dodatni senzorji:</div>

    <div class="checkboxContainer">
      <mat-checkbox [(ngModel)]="builderService.sceneBuilder.settings.otherSensorsSettings.rainSensor">Senzor za dež</mat-checkbox>
    </div>

    <div class="checkboxContainer">
      <mat-checkbox [(ngModel)]="builderService.sceneBuilder.settings.otherSensorsSettings.windSensor">Senzor za veter</mat-checkbox>
    </div>

    <div class="checkboxContainer">
      <mat-checkbox [(ngModel)]="builderService.sceneBuilder.settings.otherSensorsSettings.sunSensor">Senzor za svetlobo</mat-checkbox>
    </div>
  </div>

