import { Box3, PerspectiveCamera, Vector3, Euler, OrthographicCamera, Raycaster, Vector2 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { TAARenderPass } from "three/examples/jsm/postprocessing/TAARenderPass";
import { SceneController } from "./SceneController";

const VECTOR_ADD_TEXTSIZE = new Vector3(0.6, 0, 0.6);
export class CameraController {
  private _camera: PerspectiveCamera;
  private _raycaster: Raycaster = new Raycaster();

  public get camera(): PerspectiveCamera {
    return this._camera;
  }

  private _activeCamera: PerspectiveCamera | OrthographicCamera;
  public get activeCamera(): PerspectiveCamera | OrthographicCamera {
    return this._activeCamera;
  }

  private _orbitControls: OrbitControls;
  public get orbitControls(): OrbitControls {
    return this._orbitControls;
  }

  public set orbitControls(value: OrbitControls) {
    this._orbitControls = value;
  }

  //za omogočanje preklopa med 3d in tloris
  private cameraPosition3D: Vector3;
  private cameraRotation3D: Euler;
  private controlsTarget3D: Vector3;

  public fov = 30;
  public cameraState = 0; //0...3D, 1...Tloris(birds eye)

  //OrbitControls Parametri
  public nearClippingPlane = 0.1;
  public farClippingPlane = 5000;

  public canvasWidth: number;
  public canvasHeight: number;

  constructor(
    canvasWidth: number,
    canvasHeight: number,
    domElement: HTMLCanvasElement,
    initCameraPosition: Vector3,
    initCameraTarget: Vector3,
    private sceneController: SceneController
  ) {
    this.initCamera(canvasWidth, canvasHeight, initCameraPosition);
    this.initOrbitControls(domElement, initCameraTarget);
  }

  public initCamera(canvasWidth: number, canvasHeight: number, initCameraPosition: Vector3) {
    // set camera position
    this._camera = new PerspectiveCamera(this.fov, canvasWidth / canvasHeight, 0.1, 1000);
    this._camera.position.copy(initCameraPosition);
    this._camera.rotation;
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this._activeCamera = this._camera;
  }

  public initOrbitControls(domElement: HTMLCanvasElement, initCameraTarget: Vector3) {
    this.orbitControls = new OrbitControls(this.camera, domElement);
    this.orbitControls.enableZoom = true;
    this.orbitControls.enableDamping = true;
    this.orbitControls.maxZoom = this.farClippingPlane / 200;
    this.orbitControls.maxDistance = this.farClippingPlane / 200;
    this.orbitControls.minZoom = this.nearClippingPlane * 10;
    this.orbitControls.minDistance = this.nearClippingPlane * 10;
    this.orbitControls.enablePan = true;
    this.orbitControls.rotateSpeed = 0.4;
    this.orbitControls.maxPolarAngle = Math.PI / 2 - 0.03;

    if (window.innerWidth <= 750) {
      this.orbitControls.maxZoom = this.farClippingPlane / 100;
      this.orbitControls.maxDistance = this.farClippingPlane / 100;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const _v = new Vector3();
    const maxPan = new Vector3(5, 2.5, 5);
    const minPan = new Vector3(-5, -0.01, -5);

    this.orbitControls.addEventListener("change", function () {
      _v.copy(component.orbitControls.target);
      component.orbitControls.target.clamp(minPan, maxPan);
      _v.sub(component.orbitControls.target);
      component.camera.position.sub(_v);
      component.sceneController.requestRenderIfNotRequested();
    });

    component.orbitControls.target.copy(initCameraTarget);
  }

  public update() {
    this.orbitControls.update();
  }

  public setconfiguratorSize(seatingVis3D: HTMLElement) {
    this.camera.aspect = seatingVis3D.clientWidth / seatingVis3D.clientHeight;
    this.camera.updateProjectionMatrix();
    this.canvasWidth = seatingVis3D.clientWidth;
    this.canvasHeight = seatingVis3D.clientHeight;
  }

  public setTopView(camera: THREE.PerspectiveCamera, boundingBox: Box3, screenshot = true) {
    const center = boundingBox.getCenter(new Vector3());
    const size = boundingBox.getSize(new Vector3()).add(VECTOR_ADD_TEXTSIZE);
    let aspectRatio: number;
    if (screenshot) {
      aspectRatio = 16 / 9;
    } else {
      aspectRatio = this.canvasWidth / this.canvasHeight;
    }

    // Calculate the vertical and horizontal field of view
    const vFov = camera.fov * (Math.PI / 180);
    const hFov = 2 * Math.atan(Math.tan(vFov / 2) * aspectRatio);

    // Calculate the minimum distance required for each field of view
    const distanceFromVFov = size.z / 2 / Math.tan(vFov / 2);
    const distanceFromHFov = size.x / 2 / Math.tan(hFov / 2);

    // Choose the maximum distance required to fit the object in both dimensions
    const distance = Math.max(distanceFromVFov, distanceFromHFov);

    // Set the position and rotation of the camera for the floor plan
    camera.position.set(center.x, center.y + distance, center.z);
    camera.up.set(0, 0, -1); // Set the up vector to point in the negative Z direction
    camera.lookAt(center);
    camera.aspect = aspectRatio;
    camera.updateProjectionMatrix();
  }

  setIsometricView(camera: PerspectiveCamera, boundingBox: Box3, aspectRatio: number) {
    //ta funkcija deluje ok, ne pa perfektno
    const center = boundingBox.getCenter(new Vector3());
    const size = boundingBox.getSize(new Vector3());

    const vFov = camera.fov * (Math.PI / 180);
    const hFov = 2 * Math.atan(Math.tan(vFov / 2) * aspectRatio);

    const distanceFromVFov = size.y / 2 / Math.tan(vFov / 2);
    const distanceFromHFov = size.x / 2 / Math.tan(hFov / 2);
    const distanceFromZFov = size.z / 2 / Math.tan(vFov / 2);

    // Choose the maximum distance required to fit the object in both dimensions
    const distance = Math.max(distanceFromVFov, distanceFromHFov, distanceFromZFov);

    // Calculate the scaling factor based on the bounding box size
    const baseScaling = 3; // Adjust this value to control the base amount of padding
    const scalingFactor = 1 + baseScaling / Math.max(size.x, size.y, size.z);
    const scaledDistance = distance * scalingFactor;

    // Set the position of the camera for the isometric view
    const isoVector = new Vector3(1, 1, 1).normalize().multiplyScalar(scaledDistance);
    camera.position.copy(center.clone().add(isoVector));

    camera.up.set(0, 1, 0); // Set the up vector to point in the positive Y direction
    camera.lookAt(center);
    camera.updateProjectionMatrix();
  }

  setIsometricViewOrto(camera: OrthographicCamera, boundingBox: Box3, aspectRatio: number) {
    const center = boundingBox.getCenter(new Vector3());
    const size = boundingBox.getSize(new Vector3()).add(VECTOR_ADD_TEXTSIZE);

    // Calculate the average size of the object
    const averageSize = (size.x + size.y + size.z) / 3;

    // Set a scalar value based on the average size of the object
    const scalarValue = averageSize < 1 ? 0.3 : averageSize < 5 ? 0.2 : 0.1;

    // Add the scalar value to the size
    size.addScalar(scalarValue);

    // Calculate the new size with the correct aspect ratio
    const newSize = new Vector3(size.x, size.y, size.z);
    if (aspectRatio > newSize.x / newSize.z) {
      newSize.x = newSize.z * aspectRatio;
    } else {
      newSize.z = newSize.x / aspectRatio;
    }

    // Create a new bounding box with the correct aspect ratio
    // const newSizeBox = new Box3().setFromCenterAndSize(center, newSize);
    // Set the position of the camera for the isometric view
    // const angle = Math.PI / 4; // Desired angle for the isometric view (45 degrees)

    const isoVector = new Vector3(1, 1, 1).normalize().multiplyScalar(newSize.length() / Math.sqrt(3));
    camera.position.copy(center.clone().add(isoVector));

    // Calculate the orthographic camera's top, right, bottom, and left values
    const halfWidth = newSize.x / 2;
    const halfHeight = newSize.z / 2;

    camera.left = -halfWidth;
    camera.right = halfWidth;
    camera.top = halfHeight;
    camera.bottom = -halfHeight;

    camera.up.set(0, 1, 0); // Set the up vector to point in the positive Y direction
    camera.lookAt(center);
    camera.updateProjectionMatrix();
  }

  setTopDownViewOrto(
    camera: OrthographicCamera,
    boundingBox: Box3,
    aspectRatio: number,
    spaceWidth: number | null = null,
    spaceHeight: number | null = null
  ) {
    const center = boundingBox.getCenter(new Vector3());
    let size;

    if (spaceWidth && spaceHeight) {
      const currentAspectRatio = spaceWidth / spaceWidth;

      if (currentAspectRatio > aspectRatio) {
        spaceHeight = spaceHeight / aspectRatio;
      } else {
        spaceWidth = spaceWidth * aspectRatio;
      }

      size = new Box3(
        new Vector3(spaceWidth / -2, 0, spaceHeight / -2),
        new Vector3(spaceWidth / 2, 1, spaceHeight / 2)
      ).getSize(new Vector3());

      const objectSize = boundingBox.getSize(new Vector3()).add(VECTOR_ADD_TEXTSIZE).addScalar(0.1);
      const calculatedZoom1 = spaceWidth / objectSize.x;
      const calculatedZoom2 = spaceHeight / objectSize.z;
      camera.zoom = Math.min(calculatedZoom1, calculatedZoom2);
    } else {
      size = boundingBox.getSize(new Vector3()).add(VECTOR_ADD_TEXTSIZE).addScalar(0.1);
    }

    // Set the position of the camera for the top-down view

    let newWidth = size.x;
    let newHeight = size.z;

    const currentAspectRatio = newWidth / newHeight;

    if (currentAspectRatio > aspectRatio) {
      newHeight = newWidth / aspectRatio;
    } else {
      newWidth = newHeight * aspectRatio;
    }

    // Create a new bounding box with the correct aspect ratio
    const newSize = new Vector3(newWidth, size.y, newHeight);
    const newBoundingBox = new Box3(
      center.clone().sub(newSize.clone().multiplyScalar(0.5)),
      center.clone().add(newSize.clone().multiplyScalar(0.5))
    );

    const newCenter = boundingBox.getCenter(new Vector3());
    newBoundingBox.getCenter(newCenter);
    camera.position.set(newCenter.x, newCenter.y + size.y, newCenter.z);

    const adjustedSize = newBoundingBox.getSize(new Vector3());

    camera.left = -adjustedSize.x / 2;
    camera.right = adjustedSize.x / 2;
    camera.top = adjustedSize.z / 2;
    camera.bottom = -adjustedSize.z / 2;

    camera.up.set(0, 0, -1); // Set the up vector to point in the negative Z direction
    camera.lookAt(newCenter);
    camera.updateProjectionMatrix();
    return newCenter;
  }

  public toggle3D(
    boundingBox: Box3,
    outlinePass: OutlinePass,
    renderPass: TAARenderPass,
    spaceWidth: number,
    spaceHeight: number
  ) {
    this.orbitControls.enableDamping = false;
    this.orbitControls.update();
    this.orbitControls.enableDamping = true;

    if (this.cameraState === 0) {
      // Store the current Perspective camera settings
      this.cameraPosition3D = this.camera.position.clone();
      this.cameraRotation3D = this.camera.rotation.clone();
      this.controlsTarget3D = this.orbitControls.target.clone();

      // Create and configure the Orthographic camera
      const aspectRatio = this.camera.aspect;
      const orthoCamera = new OrthographicCamera(-aspectRatio, aspectRatio, 1, -1, 0.1, this.farClippingPlane);

      this.orbitControls.target.copy(
        this.setTopDownViewOrto(orthoCamera, boundingBox, aspectRatio, spaceWidth, spaceHeight)
      );

      orthoCamera.updateProjectionMatrix();

      // Set the Orthographic camera as the active camera
      this._activeCamera = orthoCamera;

      // Update the OrbitControls to use the Orthographic camera
      this.orbitControls.object = orthoCamera;
      // Set OrbitControls properties
      this.orbitControls.maxZoom = this.farClippingPlane / 10;
      this.orbitControls.minZoom = 1;
      this.orbitControls.maxDistance = this.farClippingPlane / 10;
      this.orbitControls.enableRotate = false;
      this.orbitControls.update();

      // Store the current camera state
      this.cameraState = 1;
    } else {
      // Set the Perspective camera as the active camera
      this._activeCamera = this.camera;

      // Update the OrbitControls to use the Perspective camera
      this.orbitControls.object = this.camera;

      // Restore the Perspective camera settings
      this.camera.position.copy(this.cameraPosition3D);
      this.camera.setRotationFromEuler(this.cameraRotation3D);
      this.orbitControls.target.copy(this.controlsTarget3D);

      // Set OrbitControls properties
      this.orbitControls.maxZoom = this.farClippingPlane / 100;
      this.orbitControls.maxDistance = this.farClippingPlane / 100;
      this.orbitControls.enableRotate = true;
      this.orbitControls.update();

      // Store the current camera state
      this.cameraState = 0;
    }

    outlinePass.renderCamera = this.activeCamera;
    renderPass.camera = this.activeCamera;
  }

  public get mouseControlsEnabled(): boolean {
    return this.cameraState === 0;
  }

  public getRaycaster(pointer: Vector2): Raycaster {
    this._raycaster.setFromCamera(pointer, this.camera);
    return this._raycaster;
  }
}
