import {
  Box3,
  PCFSoftShadowMap,
  WebGLRenderTarget,
  SRGBColorSpace,
  Vector2,
  LinearFilter,
  RGBAFormat,
  WebGLRenderer,
  Scene,
  Raycaster,
  Vector3,
} from "three";

import { CameraController } from "./CameraController";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { TAARenderPass } from "three/examples/jsm/postprocessing/TAARenderPass.js";

export interface GlowControlls {
  enabled: boolean;
  edgeStrength: number;
  edgeGlow: number;
  edgeThickness: number;
  pulsePeriod: number;
  glowColor: string;
  hiddenGlowColor: string;
}
export class SceneController {
  public get scene(): Scene {
    return this._scene;
  }

  private _renderer: WebGLRenderer;
  public get renderer(): WebGLRenderer {
    return this._renderer;
  }

  private _composer: EffectComposer;
  public get composer(): EffectComposer {
    return this._composer;
  }

  private renderPass: TAARenderPass;
  private _outlinePass: OutlinePass;
  public get outlinePass(): OutlinePass {
    return this._outlinePass;
  }

  private _cameraController: CameraController;
  public get cameraController(): CameraController {
    return this._cameraController;
  }

  private renderRequested: boolean;

  //public shadowController: ShadowController;
  private _renderedFrames = 0;
  private _framesToRender = 30;

  private _increaseSampleLevelFPSLimit: number = 59;
  private _decreaseSampleLevelFPSLimit: number = 35;
  private _initAvgFPS: number = 45;
  private _defaultSampleLevel: number = 2;
  private _defaultPixelRatio: number = 1;
  private _lastFPSUpdateFrame: number = 0; //number of frame of when the update of sampling level happened. This is done to prevent updating sampling level one frame after another
  private _numFramesToUpdateSamplingLevel: number = 40; // minimum number of frames to update sampling level between two jumps.
  private _maxSampleLevel: number = 2;
  private _lastUpdateTime: number = 0;
  private _avgFPS: number = 0;
  private _alpha: number = 0.1;

  public setEdgeStrength(value: number) {
    this.outlinePass.edgeStrength = value;
  }

  public setEdgeGlow(value: number) {
    this.outlinePass.edgeGlow = value;
  }

  public setEdgeThickness(value: number) {
    this.outlinePass.edgeThickness = value;
  }

  public setPulsePeriod(value: number) {
    this.outlinePass.pulsePeriod = value;
  }

  public setGlowColor(value: string) {
    this.outlinePass.visibleEdgeColor.set(value);
  }

  public setHiddenGlowColor(value: string) {
    this.outlinePass.hiddenEdgeColor.set(value);
  }

  public getEdgeStrength() {
    return this.outlinePass.edgeStrength;
  }

  public getEdgeGlow() {
    return this.outlinePass.edgeGlow;
  }

  public getEdgeThickness() {
    return this.outlinePass.edgeThickness;
  }

  public getPulsePeriod() {
    return this.outlinePass.pulsePeriod;
  }

  public getGlowColor() {
    return this.outlinePass.visibleEdgeColor;
  }

  public getHiddenGlowColor() {
    return this.outlinePass.hiddenEdgeColor;
  }

  private glowParameters: GlowControlls;

  constructor(
    seatingVis3D: HTMLElement,
    canvasWidth: number,
    canvasHeight: number,
    params: GlowControlls,
    private _scene: Scene,
    initCameraPosition: Vector3,
    initCameraTarget: Vector3
  ) {
    this.initSceneController(seatingVis3D);
    this._cameraController = new CameraController(
      canvasWidth,
      canvasHeight,
      this.renderer.domElement,
      initCameraPosition,
      initCameraTarget,
      this
    );
    this.glowParameters = params;
    this.initSelectionGlow(seatingVis3D);
    //this.initShadows(spaceWidth, spaceHeight);
    this.renderRequested = false;
  }

  public get cameraState() {
    return this.cameraController.cameraState;
  }

  public get camera() {
    return this.cameraController.activeCamera;
  }
  public get orbitControls() {
    return this._cameraController.orbitControls;
  }

  public toggle3D(boundingBox: Box3, spaceWidth: number, spaceHeight: number) {
    this.cameraController.toggle3D(boundingBox, this.outlinePass, this.renderPass, spaceWidth, spaceHeight);
  }

  public initSceneController(seatingVis3D: HTMLElement) {
    this._renderer = new WebGLRenderer({
      canvas: seatingVis3D,
      antialias: true,
      preserveDrawingBuffer: true,
    });

    this._renderer.sortObjects = false;
    //this._renderer.setSize(canvasWidth, canvasHeight);
    //seatingVis3D.appendChild(this._renderer.domElement);
    // setup renderer
    this.renderer.setClearColor(0xeeeeee, 1);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap; // default PCFShadowMap
    this.renderer.outputColorSpace = SRGBColorSpace;
  }

  private initSelectionGlow(seatingVis3D: HTMLElement): void {
    const size = this.renderer.getDrawingBufferSize(new Vector2());

    const renderTarget = new WebGLRenderTarget(size.width, size.height, {
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      format: RGBAFormat,
      colorSpace: SRGBColorSpace,
    });

    this._composer = new EffectComposer(this.renderer, renderTarget);

    this.renderPass = new TAARenderPass(this.scene, this.camera, "#EEEEEE", 1);
    this.renderPass.unbiased = false;
    this.renderPass.sampleLevel = this._defaultSampleLevel;
    this.composer.addPass(this.renderPass);

    const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
    this.composer.addPass(gammaCorrectionPass);

    this._outlinePass = new OutlinePass(
      new Vector2(seatingVis3D.clientWidth, seatingVis3D.clientHeight),
      this.scene,
      this.cameraController.camera
    );

    this.outlinePass.edgeStrength = this.glowParameters.edgeStrength;
    this.outlinePass.edgeGlow = this.glowParameters.edgeGlow;
    this.outlinePass.edgeThickness = this.glowParameters.edgeThickness;
    this.outlinePass.pulsePeriod = this.glowParameters.pulsePeriod;
    this.outlinePass.visibleEdgeColor.set(this.glowParameters.glowColor);
    this.outlinePass.hiddenEdgeColor.set(this.glowParameters.hiddenGlowColor);
    this.outlinePass.renderToScreen = true;

    this.composer.addPass(this.outlinePass);
  }

  private _adjustFPS(): void {
    // calculate time since last frame
    const now = Date.now();
    const deltaTime = now - this._lastUpdateTime;
    this._lastUpdateTime = now;
    if (this._renderedFrames > 0) {
      // calculate instantaneous fps
      const instantFPS = 1000 / deltaTime;

      // low-pass filter to smooth out the fps
      this._avgFPS = this._alpha * instantFPS + (1 - this._alpha) * this._avgFPS;
    }

    // if average fps drops below a certain threshold, lower sample level
    if (this._renderedFrames > 10 && this.renderer.info.render.frame - this._lastFPSUpdateFrame > this._numFramesToUpdateSamplingLevel) {
      if (this._avgFPS < this._decreaseSampleLevelFPSLimit) {
        this.renderPass.sampleLevel = Math.max(0, this.renderPass.sampleLevel - 1);
        this._lastFPSUpdateFrame = this.renderer.info.render.frame;
        this._avgFPS = this._initAvgFPS;
      } else if (this._avgFPS > this._increaseSampleLevelFPSLimit) {
        this.renderPass.sampleLevel = Math.min(this._maxSampleLevel, this.renderPass.sampleLevel + 1);
        this._avgFPS = this._initAvgFPS;
        this._lastFPSUpdateFrame = this.renderer.info.render.frame;
      }
    }
  }

  private render() {
    this._cameraController.update();

    if (this.glowParameters.enabled) {
      this.composer.render();
    } else {
      this.renderer.render(this.scene, this.camera);
    }

    this._adjustFPS();
    this._renderedFrames++;

    if (this._renderedFrames < this._framesToRender) {
      requestAnimationFrame(() => this.render());
    } else {
      this.renderRequested = false;
    }
  }

  public requestRenderIfNotRequested() {
    if (!this.renderRequested) {
      this.renderRequested = true;
      this._renderedFrames = 0;
      this._avgFPS = this._initAvgFPS;
      requestAnimationFrame(() => this.render());
    } else {
      // Reset the renderedFrames counter to ensure 20 frames are rendered after the latest request
      this._renderedFrames = 0;
    }
  }

  public setconfiguratorSize(seatingVis3D: HTMLElement) {
    const parentDiv = seatingVis3D.parentNode as HTMLElement;
    this.cameraController.setconfiguratorSize(parentDiv);

    let pixelRatio = window.devicePixelRatio;
    if (pixelRatio > 2) {
      pixelRatio = 2;
    }

    this._defaultPixelRatio = pixelRatio;
    this.renderer.setPixelRatio(this._defaultPixelRatio);
    this.composer.setPixelRatio(this._defaultPixelRatio);

    this.renderer.setSize(parentDiv.clientWidth, parentDiv.clientHeight);
    this.composer.setSize(parentDiv.clientWidth, parentDiv.clientHeight);
  }

  public dispose() {
    this.scene.clear();
    this.renderer.dispose();
    this.orbitControls.dispose();
  }

  getRaycaster(pointer: Vector2): Raycaster {
    return this.cameraController.getRaycaster(pointer);
  }

  public saveDefaultProperties(): void {
    this._defaultSampleLevel = this.renderPass.sampleLevel;
  }

  public loadDefaultProperties(): void {
    this.renderPass.sampleLevel = this._defaultSampleLevel;
    this.renderer.setPixelRatio(this._defaultPixelRatio);
    this.composer.setPixelRatio(this._defaultPixelRatio);
  }

  public setSampleLevel(sampleLevel: number): void {
    this.renderPass.sampleLevel = sampleLevel;
  }

  public setPixelRatio(pixelRatio: number): void {
    this.renderer.setPixelRatio(pixelRatio);
    this.composer.setPixelRatio(pixelRatio);
  }
}
