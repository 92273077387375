import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackendService } from '../SERVICES/BackendService';
import { BuilderService } from '../SERVICES/BuilderService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SceneService } from 'modules/scene-setup/scene.service';
import { LoaderService } from 'modules/loader/loader.service';

@Component({
  selector: 'app-submit-form',
  templateUrl: './submit-form.component.html',
  styleUrls: ['./submit-form.component.scss'],
})
export class SubmitFormComponent implements OnDestroy {
  public model: SubmitForm;

  private _subRef: Subscription;
  public submitted: boolean = false;
  @Output() formSubmitted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private sceneService: SceneService,
    private backendService: BackendService,
    private builderService: BuilderService,
    private loadingService: LoaderService,
    private snackbar: MatSnackBar
  ) {
    this.model = new SubmitForm('', '', '', '', '', '', '');

    this._subRef = this.backendService.submitEventEmmiter.subscribe(() => {
      const form = document.getElementById('submitForm') as HTMLFormElement;
      const check = form.checkValidity();

      if (check) {
        this.snackbar.dismiss();
        this.loadingService.loading = true;

        this.backendService.submitOffer(this.sceneService, this.builderService, this.model).then((status) => {
          this.loadingService.loading = false;

          if (status === 200) {
            this.submitted = true;
            this.formSubmitted.emit();
          } else {
            this.snackbar.open('Oddaja ni uspela: Prosimo poskusite znova!', 'Zapri', {
              verticalPosition: 'bottom',
              panelClass: 'customSnackbar2',
            });
          }
        });
      } else {
        this.snackbar.open('Oddaja ni uspela: Prosimo izpolnite vsa potrebna polja!', 'Zapri', {
          verticalPosition: 'bottom',
          panelClass: 'customSnackbar2',
        });
      }
    });
  }

  onReset() {
    location.reload();
  }

  ngOnDestroy(): void {
    this._subRef.unsubscribe();
    this.snackbar.dismiss();
  }
}

export class SubmitForm {
  constructor(
    public name: string,
    public surname: string,
    public email: string,
    public phoneNumber: string,
    public city: string,
    public address: string,
    public comment: string
  ) {}
}
