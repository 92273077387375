import {
  Box3,
  Camera,
  EquirectangularReflectionMapping,
  Matrix3,
  Object3D,
  PerspectiveCamera,
  Raycaster,
  Scene,
  Sphere,
  Vector2,
  Vector3,
} from "three";

import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { Damper } from "./Damper";

const raycaster = new Raycaster();

export class ARScene extends Scene {
  private goalTarget = new Vector3();
  private targetDamperX = new Damper();
  private targetDamperY = new Damper();
  private targetDamperZ = new Damper();

  public target = new Object3D();
  public canScale: boolean = false;
  private _presentedObject = new Object3D();
  public boundingBox = new Box3();
  public boundingSphere = new Sphere();
  public size = new Vector3();

  // These default camera values are never used, as they are reset once the
  // model is loaded and framing is computed.
  public camera: PerspectiveCamera;
  public xrCamera: Camera | null = null;

  constructor(private _hdrMap: string | null = null) {
    super();

    this.add(this.target);
    this.target.name = "Target";

    this.camera = new PerspectiveCamera(45, 9 / 20, 0.1, 100);
    this.camera.name = "MainCamera";

    this.initScene();
  }

  private initScene(): void {
    const loader = new RGBELoader();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (this._hdrMap !== null) {
      loader.load(this._hdrMap, function (texture) {
        texture.mapping = EquirectangularReflectionMapping;
        self.environment = texture;
      });
    }
  }

  public setObject(object: Object3D): void {
    this._presentedObject = object;
    this.add(this._presentedObject);
    this.boundingBox = new Box3().setFromObject(object);
    //this._presentedObject.position.y = -this.boundingBox.min.y;

    this.size = new Vector3();
    this.boundingBox.getSize(this.size);
  }

  get raycaster() {
    return raycaster;
  }

  get presentedObject(): Object3D {
    return this._presentedObject;
  }

  getCamera(): Camera {
    return this.xrCamera != null ? this.xrCamera : this.camera;
  }

  /**
   * This method returns the world position, model-space normal and texture
   * coordinate of the point on the mesh corresponding to the input pixel
   * coordinates given relative to the model-viewer element. If the mesh
   * is not hit, the result is null.
   */
  positionAndNormalFromPoint(
    ndcPosition: Vector2,
    object: Object3D = this
  ): { position: Vector3; normal: Vector3; uv: Vector2 | null } | null {
    this.raycaster.setFromCamera(ndcPosition, this.getCamera());
    const hits = this.raycaster.intersectObject(object, true);

    const hit = hits.find((hit) => hit.object.visible);
    if (hit == null || hit.face == null) {
      return null;
    }

    if (hit.uv == null) {
      return { position: hit.point, normal: hit.face.normal, uv: null };
    }

    hit.face.normal.applyNormalMatrix(new Matrix3().getNormalMatrix(hit.object.matrixWorld));

    return { position: hit.point, normal: hit.face.normal, uv: hit.uv };
  }

  /**
   * Yaw is the scene's orientation about the y-axis, around the rotation
   * center.
   */
  set yaw(radiansY: number) {
    this.rotation.y = radiansY;
  }

  get yaw(): number {
    return this.rotation.y;
  }

  /**
   * Sets the point in model coordinates the model should orbit/pivot around.
   */
  setTarget(modelX: number, modelY: number, modelZ: number) {
    this.goalTarget.set(-modelX, -modelY, -modelZ);
  }

  /**
   * Set the decay time of, affects the speed of target transitions.
   */
  setTargetDamperDecayTime(decayMilliseconds: number) {
    this.targetDamperX.setDecayTime(decayMilliseconds);
    this.targetDamperY.setDecayTime(decayMilliseconds);
    this.targetDamperZ.setDecayTime(decayMilliseconds);
  }

  /**
   * Gets the point in model coordinates the model should orbit/pivot around.
   */
  getTarget(): Vector3 {
    return this.goalTarget.clone().multiplyScalar(-1);
  }

  /**
   * This should be called every frame with the frame delta to cause the target
   * to transition to its set point.
   */
  updateTarget(delta: number): boolean {
    const goal = this.goalTarget;
    const target = this.target.position;
    if (!goal.equals(target)) {
      const normalization = this.boundingSphere.radius / 10;
      let { x, y, z } = target;
      x = this.targetDamperX.update(x, goal.x, delta, normalization);
      y = this.targetDamperY.update(y, goal.y, delta, normalization);
      z = this.targetDamperZ.update(z, goal.z, delta, normalization);
      this.target.position.set(x, y, z);
      this.target.updateMatrixWorld();
      return true;
    } else {
      return false;
    }
  }
}
