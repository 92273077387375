import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-video-overlay',
  templateUrl: './video-overlay.component.html',
  styleUrls: ['./video-overlay.component.scss']
})
export class VideoOverlayComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() videoUrl: string;
  @Input() buttonText: string;
  
  @Output() videoClosed = new EventEmitter<boolean>();
  
  onCloseClick() {
    this.videoClosed.emit(true);
  }
}
