import { Side } from '../enums';
import {
  IColor,
  ILedSettings,
  Floor,
  ILouversSettings,
  IPergolaSettings,
  IPostSettings,
  IRafterSettings,
  IWallSettings,
  IWallSettingsStr,
  Layout,
  LayoutStr,
  IOtherSensorSettings,
  SideRecord,
} from '../interfaces';

export class PergolaSettings implements IPergolaSettings {
  public width: number;
  public length: number;
  public height: number;

  // min max values
  public minWidth: number;
  public maxWidth: number;
  public minHeight: number;
  public maxHeight: number;
  public minLength: number;
  public maxLength: number;
  public minLengthAddPost: number;
  public maxModuleLength: number;
  public maxModuleWidth: number;

  // steps
  public heightStep: number;
  public widthStep: number;
  public lengthStep: number;

  public availableColors: IColor[];
  public shadesColors: IColor[];
  public curtainColors: IColor[];

  public availableLayoutsStr: LayoutStr[];
  public availableLayouts: Layout[];
  public postSettings: IPostSettings;
  public rafterSettings: IRafterSettings;
  public louversSettings: ILouversSettings;
  public wallSettingsStr: IWallSettingsStr;
  public wallSettings: IWallSettings;
  public availableFloors: Floor[];
  public selectedFloor: Floor;

  public ledSettings: ILedSettings;

  public materialRoughness: number;
  public materialMetalness: number;

  public windowStreched: number;
  public screenStreched: number;
  public curtainStreched: number;

  public otherSensorsSettings: IOtherSensorSettings;

  public startModulesAccesories: (SideRecord | null)[];

  public loadSettings(res: IPergolaSettings) {
    this.width = res.width;
    this.length = res.length;
    this.height = res.height;
    this.minWidth = res.minWidth;
    this.maxWidth = res.maxWidth;
    this.minHeight = res.minHeight;
    this.maxHeight = res.maxHeight;
    this.minLength = res.minLength;
    this.maxLength = res.maxLength;
    this.heightStep = res.heightStep;
    this.widthStep = res.widthStep;
    this.lengthStep = res.lengthStep;
    this.minLengthAddPost = res.minLengthAddPost;
    this.availableColors = res.availableColors;
    this.availableLayoutsStr = res.availableLayoutsStr;
    this.materialMetalness = res.materialMetalness;
    this.materialRoughness = res.materialRoughness;
    this.postSettings = res.postSettings;
    this.rafterSettings = res.rafterSettings;
    this.louversSettings = res.louversSettings;
    this.availableFloors = res.availableFloors;
    this.ledSettings = res.ledSettings;
    this.windowStreched = res.windowStreched;
    this.screenStreched = res.screenStreched;
    this.curtainStreched = res.curtainStreched;

    this.maxModuleWidth = res.maxModuleWidth;
    this.maxModuleLength = res.maxModuleLength;

    this.shadesColors = res.shadesColors;
    this.curtainColors = res.curtainColors;

    this.otherSensorsSettings = res.otherSensorsSettings;

    const stringToSide = (str: string): Side | undefined => {
      switch (str.toLowerCase()) {
        case 'right':
          return Side.right;
        case 'left':
          return Side.left;
        case 'front':
          return Side.front;
        case 'back':
          return Side.back;
        default:
          return undefined;
      }
    };

    this.wallSettingsStr = res.wallSettingsStr;

    // Convert the sides property from string array to Side enum array
    if (this.wallSettingsStr.sides && Array.isArray(this.wallSettingsStr.sides)) {
      this.wallSettings = {
        ...this.wallSettingsStr,
        sides: this.wallSettingsStr.sides.map((side: string) => {
          const sideEnum = stringToSide(side);
          if (sideEnum === undefined) {
            throw new Error(`Invalid side: ${side}`);
          }
          return sideEnum;
        }),
      };
    } else {
      throw new Error('Invalid sides property in wallSettingsStr');
    }

    this.availableLayouts = [];

    for (const layout of this.availableLayoutsStr) {
      const temp = {
        ...layout,
        setting: layout.setting.map((side: string) => {
          const sideEnum = stringToSide(side);
          if (sideEnum === undefined) {
            throw new Error(`Invalid side: ${side}`);
          }
          return sideEnum;
        }),
      };

      this.availableLayouts.push(temp);
      this.selectedFloor = this.availableFloors[0];
    }
  }

  getUrlParams(pageUrl: string): URL {
    const url = new URL(pageUrl);
    return url;
  }
}
