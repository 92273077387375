import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription, first, timer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private loadingThreshold = 0;
  private loadingScreenSub!: Subscription;
  public loadingObservable: Observable<boolean>;
  private loadingBehaviorSubject: BehaviorSubject<boolean>;
  public loaderDisplayedObservable: Observable<boolean>;
  private loaderDisplayedReplaySubject: Subject<boolean>;
  private _loading: boolean = true;

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(value: boolean) {
    if (value) {
      this.loadingScreenSub = timer(this.loadingThreshold)
        .pipe(first())
        .subscribe(() => {
          this.loadingBehaviorSubject.next(value);
          this._loading = true;
        });
    } else {
      if (!this.loadingScreenSub.closed) {
        this.loadingScreenSub.unsubscribe();
      }
      this.loadingBehaviorSubject.next(value);
    }
  }

  public loaderIsDisplayed() {
    this.loaderDisplayedReplaySubject.next(true);
  }

  constructor() {
    this.loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
    this.loadingObservable = this.loadingBehaviorSubject.asObservable();
    this.loaderDisplayedReplaySubject = new Subject<boolean>();
    this.loaderDisplayedObservable = this.loaderDisplayedReplaySubject.asObservable();
  }
}
