import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-ar-component",
  templateUrl: "./ar-qr-code.component.html",
  styleUrls: ["./ar-qr-code.component.scss"],
})
export class ARComponent {
  @Input() qrUrl: string;
  @Output() qrUrlChange = new EventEmitter<string>();
  @Input() logoUrl: string;

  @Input() qrTitle: string = "Prikažite ustvarjeno konfiguracijo v svojem prostoru";
  @Input() qrSubtext: string = "Usmerite kamero pametnega telefona ali tablice na spodnjo QR kodo.";
  @Input() qrNotice: string =
    "* Za pravilno postavitev pergole v prostor je potrebno poskrbeti za dobro osvetlitev in zadostno velikost prostora.";
  @Input() arMoveText: string = "Za pozicioniranje konfiguracije usmerite telefon v tla in ga premikajte levo in desno";

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  onCloseClick() {
    this.qrUrl = "";
    this.qrUrlChange.emit(this.qrUrl);
  }
}
