import { Object3D } from 'three';
import { IColor, ISideAccessory, SideAccessoryType } from '../../interfaces';
import { Side, SideAccessoryState } from '../../enums';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { SceneController } from 'modules/scene-setup/SceneController';
gsap.registerPlugin(ScrollTrigger);

export abstract class SideAccessory extends Object3D implements ISideAccessory {
  protected _state: SideAccessoryState = SideAccessoryState.preview;
  protected _animationDuration = 0.4;
  protected _order: number = 0;

  constructor() {
    super();
  }

  public get state(): SideAccessoryState {
    return this._state;
  }

  public getOrder(): number {
    return this._order;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  updateColor(color: IColor): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setDimensions(width: number | number[], height: number, offset: number = 0): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  updateState(state: SideAccessoryState): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  focus(side: Side, sceneController: SceneController): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  abstract getColor(): IColor;
  abstract getState(): SideAccessoryType;
}
