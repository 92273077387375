<button *ngIf="showClose" (click)="closeClick.emit()" style="box-shadow: none !important;" class="closeButton" mat-mini-fab>
    <mat-icon >close</mat-icon>
</button>

<h1 id="selection-header">Nastavitve okolice:</h1>
<div class="configTitle">Animacija pergole</div>

<div class="dimension-row">
    <div class="dimension-label">
      <label>Rotacija lamel: </label>
    </div>
    <div class="dimension-slider">
      <mat-slider min="{{rotationLimits[0]}}" max="{{rotationLimits[1]}}" step="1">
        <input
          matSliderThumb
          [(ngModel)]="rotation"
          (ngModelChange)="onRotation()"
        />
      </mat-slider>
    </div>
    <div class="dimension-input">
      <input
        matInput
        [(ngModel)]="rotation"
        (change)="onRotation()"
        type="number"
        min="{{rotationLimits[0]}}"
        max="{{rotationLimits[1]}}"
        [step]="1"
        inputmode="numeric"
      />
    </div>
</div>

<div class="dimension-row">
  <div class="dimension-label">
    <label>Razteg stranskega roloja: </label>
  </div>
  <div class="dimension-slider">
    <mat-slider min="0" max="100" step="1">
      <input
        [disabled]="screenSettingDisabled"
        matSliderThumb
        [(ngModel)]="screenRotation"
        (ngModelChange)="onScreenRotation()"
      />
    </mat-slider>
  </div>
  <div class="dimension-input">
    <input
      [disabled]="screenSettingDisabled"
      matInput
      [(ngModel)]="screenRotation"
      (change)="onScreenRotation()"
      type="number"
      min="0"
      max="100"
      [step]="1"
      inputmode="numeric"
    />
  </div>
</div>

<div class="dimension-row">
  <div class="dimension-label">
    <label>Razteg steklene stene: </label>
  </div>
  <div class="dimension-slider">
    <mat-slider min="0" max="100" step="1">
      <input
        [disabled]="windowSettingDisabled"
        matSliderThumb
        [(ngModel)]="windowRotation"
        (ngModelChange)="onWindowRotation()"
      />
    </mat-slider>
  </div>
  <div class="dimension-input">
    <input
      [disabled]="windowSettingDisabled"
      matInput
      [(ngModel)]="windowRotation"
      (change)="onWindowRotation()"
      type="number"
      min="0"
      max="100"
      [step]="1"
      inputmode="numeric"
    />
  </div>
</div>

<div class="dimension-row">
  <div class="dimension-label">
    <label>Razteg zaves: </label>
  </div>
  <div class="dimension-slider">
    <mat-slider min="0" max="100" step="1">
      <input
        [disabled]="curtainSettingDisabled"
        matSliderThumb
        [(ngModel)]="curtainRotation"
        (ngModelChange)="onCurtainRotation()"
      />
    </mat-slider>
  </div>
  <div class="dimension-input">
    <input
      [disabled]="curtainSettingDisabled"
      matInput
      [(ngModel)]="curtainRotation"
      (change)="onCurtainRotation()"
      type="number"
      min="0"
      max="100"
      [step]="1"
      inputmode="numeric"
    />
  </div>
</div>

<!--<div style="margin-top: 2.5em" class="configTitle">Čas:</div>

<div class="panelContainer">
    <mat-grid-list cols="3" rowHeight="1:1" gutterSize="10px">
      <mat-grid-tile style="overflow: visible">
        <div id="image-wrapper" style="overflow: visible">
          <button
            id="studioButton"
            mat-button
            class="image-div"
            (click)="switchStudio()"
            [ngClass]="{'active': getTime() === 2}">
        
            <div class="buttonContainer">
                <img width="32px" height="32px" src="./assets/icons/sun.svg" />
                <span>Studio</span>
            </div>
            
          </button>
        </div>
      </mat-grid-tile>

      <mat-grid-tile style="overflow: visible">
        <div id="image-wrapper" style="overflow: visible">
          <button
            id="dayButton"
            mat-button
            class="image-div"
            (click)="switchDay()"
            [ngClass]="{'active': getTime() === 0}">
        
            <div class="buttonContainer">
                <img width="32px" height="32px" src="./assets/icons/sun.svg" />
                <span>Dan</span>
            </div>
            
          </button>
        </div>
      </mat-grid-tile>

      <mat-grid-tile style="overflow: visible">
        <div id="image-wrapper" style="overflow: visible">
          <button
            id="nightButton"
            mat-button
            class="image-div"
            (click)="switchNight()"
            [ngClass]="{'active': getTime() === 1}">
          
            <div class="buttonContainer">
                <img width="32px" height="32px" src="./assets/icons/moon.svg" />
                <span>Noč</span>
            </div>

          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>-->

  <div style="margin-top: 2.5em" class="configTitle">Vrsta tal</div>

  <div class="panelContainer">
    <mat-grid-list cols="3" rowHeight="1:1" gutterSize="10px">
      <mat-grid-tile *ngFor="let floor of availableFloors; index as i" style="overflow: visible">
        <div id="image-wrapper" style="overflow: visible">
          <button
            id="floor{{i}}"
            mat-button
            class="floor-div"
            [ngStyle]="{ 'background-image': toURL(floor.thumbnailURL) }"
            (click)="switchFloor(floor)"
            [ngClass]="{'active': getActiveFloor(floor)}">
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <span class="warning-message">*Te nastavitve ne vplivajo na končno konfiguracijo produkta. Namenjene so samo za namene simulacije okolice v katerega bo pergola postavljena.</span>