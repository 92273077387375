import { Group, Raycaster } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Side } from '../enums';
import {
  IBuilder,
  IColor,
  ILedSettings,
  ILouversSettings,
  IOtherSensorSettings,
  IPostSettings,
  IRafterSettings,
  IWallSettings,
  SideAccessoryArr,
  SideRecord,
} from '../interfaces';
import { LouversBuilder } from './louvers_builder';
import { PergolaSettings } from './pergola_settings';
import { PostsBuilder } from './post_builder';
import { SideAccessory } from './side_accessories/side_accessory';
import { SideAccessoryController } from './side_accessory_controller';
import { TopFrameBuilder } from './top_frame_builder';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';

export class ModuleState {
  width: number;
  length: number;
  height: number;

  minLengthAddPost: number;

  postSettings: IPostSettings;
  rafterSettings: IRafterSettings;
  louversSettings: ILouversSettings;
  wallSettings: IWallSettings;
  ledSettings: ILedSettings;

  materialRoughness: number;
  materialMetalness: number;

  windowStreched: number;
  screenStreched: number;
  curtainStreched: number;

  otherSensorsSettings: IOtherSensorSettings;

  sidesAccessories: Record<Side, SideAccessoryArr> = {
    [Side.right]: [],
    [Side.left]: [],
    [Side.front]: [],
    [Side.back]: [],
  };

  availableSideAccesories: Side[] = [];
  moduleAttachedSides: Side[] = [];
  startModulesAccesories: SideRecord | null;
  shadesColors: IColor[];
  curtainColors: IColor[];
  moduleIndex: number;

  constructor(private _pergolaSettings: PergolaSettings, moduleIndex: number) {
    this.width = this._pergolaSettings.width;
    this.length = this._pergolaSettings.length;
    this.height = this._pergolaSettings.height;
    this.minLengthAddPost = this._pergolaSettings.minLengthAddPost;
    this.postSettings = JSON.parse(JSON.stringify(this._pergolaSettings.postSettings));
    this.rafterSettings = JSON.parse(JSON.stringify(this._pergolaSettings.rafterSettings));
    this.louversSettings = JSON.parse(JSON.stringify(this._pergolaSettings.louversSettings));
    this.wallSettings = JSON.parse(JSON.stringify(this._pergolaSettings.wallSettings));
    this.ledSettings = JSON.parse(JSON.stringify(this._pergolaSettings.ledSettings));

    this.materialRoughness = this._pergolaSettings.materialRoughness;
    this.materialMetalness = this._pergolaSettings.materialMetalness;
    this.windowStreched = this._pergolaSettings.windowStreched;
    this.screenStreched = this._pergolaSettings.screenStreched;
    this.curtainStreched = this._pergolaSettings.curtainStreched;

    this.otherSensorsSettings = JSON.parse(JSON.stringify(this._pergolaSettings.otherSensorsSettings));
    this.shadesColors = this._pergolaSettings.shadesColors;
    this.curtainColors = this._pergolaSettings.curtainColors;
    this.moduleIndex = moduleIndex;
  }
}

export class ModuleBuilder extends Group implements IBuilder {
  postsBuilder: PostsBuilder;
  topFrame: TopFrameBuilder;
  louversBuilder: LouversBuilder;
  sideAccessoryController: SideAccessoryController;

  loaded = false;
  pergolaSettings: PergolaSettings;
  moduleState: ModuleState;

  constructor(
    settings: PergolaSettings,
    attachSides: Side[],
    private _loader: GLTFLoader,
    private _glowPass: OutlinePass,
    private _moduleIndex: number
  ) {
    super();
    this.pergolaSettings = settings;
    this.moduleState = new ModuleState(this.pergolaSettings, this._moduleIndex);
    this.moduleState.moduleAttachedSides = attachSides;
  }

  setSideAccesories(modules: SideRecord | null) {
    this.moduleState.startModulesAccesories = modules;
  }

  dispose(): void {
    this.postsBuilder.dispose();
    this.topFrame.dispose();
    this.louversBuilder.dispose();
    this.sideAccessoryController.dispose();
  }

  updateFrameColor() {
    this.moduleState.rafterSettings.color.code = this.pergolaSettings.rafterSettings.color.code;
    this.moduleState.postSettings.color.code = this.pergolaSettings.postSettings.color.code;
    this.topFrame.updateColor();
    this.postsBuilder.updateColor();
    this.sideAccessoryController.updateColor();
  }

  updateLouversColor() {
    this.moduleState.louversSettings.color.code = this.pergolaSettings.louversSettings.color.code;
    this.louversBuilder.updateColor();
  }

  setAvailableSideAccessoriesPositions(sides: Side[]) {
    this.moduleState.availableSideAccesories = sides;
    this.sideAccessoryController.updateAvailableSideAccessoriesPositions();
  }

  updateInnerLeds(glowPass: OutlinePass) {
    this.moduleState.ledSettings = this.pergolaSettings.ledSettings;
    this.topFrame.leds.updateInnerLights(glowPass);
    this.louversBuilder.updateDimensions();
  }

  updateWalls(): void {
    // remove side acesorries if there is a wall
    this.moduleState.wallSettings.sides.forEach((side) => {
      this.sideAccessoryController.addWall(side);
    });

    this.postsBuilder.updateWalls();
    this.topFrame.updatePosts();
    this.updateDimensions();
  }

  setWallSettings(wallSettings: IWallSettings) {
    this.moduleState.wallSettings = wallSettings;
  }

  updateLouversRotation(): void {
    this.moduleState.louversSettings.rotation = this.pergolaSettings.louversSettings.rotation;
    this.louversBuilder.updateRotation();
  }

  updateScreenStretching(): void {
    this.moduleState.screenStreched = this.pergolaSettings.screenStreched;
    this.sideAccessoryController.setScreenStreching(this.moduleState.screenStreched);
  }

  updateWindowStretching(): void {
    this.moduleState.windowStreched = this.pergolaSettings.windowStreched;
    this.sideAccessoryController.setWindowStreching(this.moduleState.windowStreched);
  }

  updateCurtainStretching(): void {
    this.moduleState.curtainStreched = this.pergolaSettings.curtainStreched;
    this.sideAccessoryController.setCurtainStreching(this.moduleState.curtainStreched);
  }

  getWindowList(): SideAccessory[] {
    return this.sideAccessoryController.getWindowList();
  }

  getScreenList(): SideAccessory[] {
    return this.sideAccessoryController.getScreenList();
  }

  getCurtainList(): SideAccessory[] {
    return this.sideAccessoryController.getCurtainList();
  }

  getAccessoriesList(): SideAccessoryArr[] {
    return this.sideAccessoryController.getAccessoriesList();
  }

  removePanel(panel: SideAccessory): void {
    this.sideAccessoryController.removeSideAccessory(panel);
  }

  async initObjects(): Promise<void> {
    // Initialize the library
    this.postsBuilder = new PostsBuilder(this.moduleState, this._loader);
    const postsBuilderPromise = this.postsBuilder.initObject();

    this.topFrame = new TopFrameBuilder(this.moduleState, this._loader);
    const topFramePromise = this.topFrame.initObjects();

    this.louversBuilder = new LouversBuilder(this.moduleState, this._loader, this._glowPass);
    const louversBuilderPromise = this.louversBuilder.initObjects();

    this.sideAccessoryController = new SideAccessoryController(this.moduleState, this._loader);
    const sideAccessoryControllerPromise = this.sideAccessoryController.initObject();

    await Promise.all([postsBuilderPromise, topFramePromise, louversBuilderPromise, sideAccessoryControllerPromise]);

    this.add(this.topFrame);
    this.add(this.louversBuilder);
    this.add(this.sideAccessoryController);
    this.add(this.postsBuilder);

    this.loaded = true;
  }

  public setDimensions(width: number, length: number, height: number): void {
    this.moduleState.width = width;
    this.moduleState.height = height;
    this.moduleState.length = length;
    this.updateDimensions();
  }

  updateDimensions(): void {
    if (this.loaded) {
      this.postsBuilder.updateDimensions();
      this.topFrame.updateDimensions();
      this.louversBuilder.updateDimensions();
      this.sideAccessoryController.updateDimensions();
    }
  }

  showPossibleWindowLocations() {
    this.sideAccessoryController.showPossibleWindowLocations();
  }

  showPossibleScreenLocations() {
    this.sideAccessoryController.showPossibleScreenLocations();
  }

  showPossibleCurtainLocations() {
    this.sideAccessoryController.showPossibleCurtainLocations();
  }

  hideSideAccessoryLocations() {
    this.sideAccessoryController.hideSideAccessoryLocations();
  }

  checkIntersection(raycaster: Raycaster): SideAccessory | null {
    const intersected = raycaster.intersectObjects(this.children, true);
    return this.sideAccessoryController.checkIntersection(intersected);
  }

  public removeSideAccessories(): void {
    this.sideAccessoryController.removeAllAccessories();
  }
}
