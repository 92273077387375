<div class="container">
    <h1>Izberite tip <span>pergole</span></h1>

    <div id="itemContainer">
        <div (click)="onTypeClick('Skylife')" id="item-skylife" class="typeItem">
            <figure>
                <img src="../../assets/Kedry-Skylife.webp"/>
                <mat-icon 
                    id="info-btn" 
                    (click)="infoCallback($event)" 
                    (mouseenter)="infoCallback($event)"
                    (mouseleave)="infoCloseCallback($event)">info</mat-icon>
                    <div id="info-txt"><b>ZLOŽLJIV STREŠNI SISTEM</b>
                        <br><br>
                        <b>Kedry Skylife</b> uporablja zložljivo, premično lamelno streho, 
                        ki omogoča prilagajanje jakosti svetlobe in prezračevanja. 
                        Z enim samim gladkim gibom se lopute hkrati vrtijo in umaknejo, 
                        kar zagotavlja hitro in enostavno natančno nastavitev osebnega udobja s pritiskom na gumb.
                    </div>
            </figure>
            <span>Bioklimatska Pergola Skylife</span>
        </div>
    </div>
    
    <div id="itemContainer">
        <div (click)="onTypeClick('Hardtop')" id="item-prime" class="typeItem">
            <figure>
                <img src="../../assets/Kedry-Prime_v2.webp"/>
                <mat-icon 
                    (click)="infoCallback($event)" 
                    (mouseleave)="infoCloseCallback($event)" 
                    (mouseenter)="infoCallback($event)" 
                    id="info-btn">info</mat-icon>
                <div id="info-txt"><b>FIKSEN STREŠNI SISTEM</b>
                    <br><br>
                    Inovativnen dizajn, široka in obsežna paleta izdelkov, prilagojene rešitve. 
                    To so glavne značilnosti <b>Kedry Hardtop</b>, samonosne aluminijaste senčne strukture 
                    z nastavljivo streho z lamelami, ki ščiti pred sončnimi žarki, vetrom, in vlago. Zahvaljujoč premičnemu sistemu, 
                    ki vodi deževnico v namenske žlebove, ščiti tudi pred vodo.
                </div>
            </figure>
            <span>Bioklimatska Pergola Hardtop</span>
        </div>
    </div>
    
</div>
