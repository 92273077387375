import { Injectable } from "@angular/core";
import { Subscription, fromEvent } from "rxjs";
import { SceneController } from "./SceneController";
import { ACESFilmicToneMapping, Scene, Vector3 } from "three";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

@Injectable({
  providedIn: "root",
})
export class SceneService {
  public sceneController: SceneController;
  public resizeSubscription: Subscription;

  init(
    canvas: HTMLElement,
    scene: Scene,
    initCameraPosition: Vector3 = new Vector3(-6.3, 7.2, 9),
    initCameraTarget: Vector3 = new Vector3(0, 0, 0)
  ) {
    const params = {
      enabled: true,
      edgeStrength: 1.0,
      edgeGlow: 1,
      edgeThickness: 1.5,
      pulsePeriod: 0,
      glowColor: "#FFFFFF",
      hiddenGlowColor: "#000000",
    };

    this.sceneController = new SceneController(
      canvas,
      canvas.clientWidth,
      canvas.clientHeight,
      params,
      scene,
      initCameraPosition,
      initCameraTarget
    );

    this.resizeSubscription = fromEvent(window, "resize").subscribe(() => {
      this.sceneController.setconfiguratorSize(canvas);
      this.sceneController.requestRenderIfNotRequested();
    });

    this.sceneController.renderer.toneMapping = ACESFilmicToneMapping;
    this.sceneController.renderer.toneMappingExposure = 1;

    this.sceneController.setconfiguratorSize(canvas);
    this.sceneController.requestRenderIfNotRequested();
  }

  updateInitialCameraPosition() {
    gsap.to(this.sceneController.camera.position, {
      duration: 1,
      x: this.sceneController.camera.position.x + 10,
      y: this.sceneController.camera.position.y + 5,
      z: this.sceneController.camera.position.z + 10,
    });
  }

  dispose() {
    this.sceneController.dispose();
    this.resizeSubscription.unsubscribe();
    this.resizeSubscription = Subscription.EMPTY;
  }
}
