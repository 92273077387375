<div id="qr-container" (click)="onCloseClick()">
    <div (click)="$event.stopPropagation();" class="holder_qr">
      <button (click)="$event.stopPropagation();" mat-icon-button class="close-qr-panel">
        <mat-icon>close</mat-icon>
      </button>

      <div (click)="$event.stopPropagation();" class="content_qr">
        <h1 class="qr_title"> {{ title }} </h1>
        <p class="qr_subtext"> {{ subtitle }}</p>
        <video controls autoplay [muted]="'muted'" loop>
            <source type="video/mp4" [src]="videoUrl">
        </video>
        <button mat-button (click)="onCloseClick()" class="confirm-btn">{{ buttonText }}</button>
      </div>
    </div>
</div>