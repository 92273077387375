
  <div id="dimensions-container">
    <div class="dimension-row">
      <div class="dimension-label">
        <label>Širina pergole: </label>
      </div>
      <div class="dimension-slider">
        <mat-slider min="{{widthLimits[0]}}" max="{{widthLimits[1]}}" step="{{steps[0]}}">
          <input
            matSliderThumb
            [(ngModel)]="width"
            (ngModelChange)="onWidthChange()"
          />
        </mat-slider>
      </div>
      <div class="dimension-input">
        <div class="input-container">
          <input
          matInput
          [(ngModel)]="width"
          (input)="onWidthChange()"
          type="number"
          min="{{widthLimits[0]}}"
          max="{{widthLimits[1]}}"
          [step]="steps[0]"
          inputmode="numeric"
        />
        </div>
        
        <button [disabled]="isInvalidWidth() || width === round(widthLimits[0])" class="input-button" mat-flat-button (click)="decrementWidth($event)">
          <mat-icon translate="no" class="footer-icon">remove</mat-icon>
        </button>

        <button [disabled]="isInvalidWidth() || width === round(widthLimits[1])" class="input-button" mat-flat-button (click)="incrementWidth($event)">
          <mat-icon translate="no" class="footer-icon">add</mat-icon>
        </button>
      </div>
      <div appAutoFocus *ngIf='isInvalidWidth()' class="dimension-error">
        Širina mora biti med {{ round(widthLimits[0]) }} in {{ round(widthLimits[1]) }} po koraku {{ steps[0] }} cm!
      </div>
    </div>

    <div class="dimension-row">
      <div class="dimension-label">
        <label>Dolžina pergole: </label>
      </div>

      <div class="dimension-slider">
        <mat-slider min="{{lengthLimits[0]}}" max="{{lengthLimits[1]}}" step="{{steps[1]}}">
          <input
            matSliderThumb
            [(ngModel)]="length"
            (ngModelChange)="onLengthChange()"
          />
        </mat-slider>
      </div>

      <div class="dimension-input">
        <div class="input-container">
          <input
            [(ngModel)]="length"
            (input)="onLengthChange()"
            type="number"
            min="{{lengthLimits[0]}}"
            max="{{lengthLimits[1]}}"
            [step]="steps[1]"
            inputmode="numeric"
          />
        </div>

        <button [disabled]="isInvalidLength() || length === round(lengthLimits[0])" class="input-button" mat-flat-button (click)="decrementLength($event)">
          <mat-icon translate="no" class="footer-icon">remove</mat-icon>
        </button>

        <button [disabled]="isInvalidLength() || length === round(lengthLimits[1])" class="input-button" mat-flat-button (click)="incrementLength($event)">
          <mat-icon translate="no" class="footer-icon">add</mat-icon>
        </button>
      </div>

      <div appAutoFocus *ngIf='isInvalidLength()' class="dimension-error">
        Dolžina mora biti med {{ round(lengthLimits[0]) }} in {{ round(lengthLimits[1]) }} po koraku {{ steps[1] }} cm!
      </div>
    </div>

    <div class="dimension-row">
      <div class="dimension-label">
        <label>Višina pergole: </label>
      </div>

      <div class="dimension-slider">
        <mat-slider min="{{heightLimits[0]}}" max="{{heightLimits[1]}}" step="{{steps[2]}}">
          <input
            matSliderThumb
            [(ngModel)]="height"
            (ngModelChange)="onHeightChange()"
          />
        </mat-slider>
      </div>

      <div class="dimension-input">
        <div class="input-container">
          <input
            [(ngModel)]="height"
            (input)="onHeightChange()"
            type="number"
            min="{{heightLimits[0]}}"
            max="{{heightLimits[1]}}"
            [step]="steps[2]"
            inputmode="numeric"
          />
        </div>

        <button [disabled]="isInvalidHeight() || height === round(heightLimits[0])" class="input-button" mat-flat-button (click)="decrementHeight($event)">
          <mat-icon translate="no" class="footer-icon">remove</mat-icon>
        </button>

        <button [disabled]="isInvalidHeight() || height === round(heightLimits[1])" class="input-button" mat-flat-button (click)="incrementHeight($event)">
          <mat-icon translate="no" class="footer-icon">add</mat-icon>
        </button>
      </div>

      <div appAutoFocus *ngIf='isInvalidHeight()' class="dimension-error">
        Višina mora biti med {{ round(heightLimits[0]) }} in {{ round(heightLimits[1]) }} po koraku {{ steps[2] }} cm!
      </div>
    </div>
  </div>
