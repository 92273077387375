import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SideAccessory } from '../builders/KE_builder/side_accessories/side_accessory';
import { numberToHexString } from '../helpers';
import { BuilderService } from '../SERVICES/BuilderService';
import { ShadeScreen } from '../builders/KE_builder/side_accessories/shade_screen';
import { SceneService } from 'modules/scene-setup/scene.service';

@Component({
  selector: 'app-screen-settings',
  templateUrl: './screen-settings.component.html',
  styleUrls: ['./screen-settings.component.scss'],
})
export class ScreenSettingsComponent {
  @Input() panel: SideAccessory | null;
  @Input() index: number;
  @Input() moduleIndex: number;
  @Input() singleModule: boolean;

  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  public selectedColor: string;
  public selectedOpacity: string;

  constructor(public builderService: BuilderService, private sceneService: SceneService) {}

  public get moduleName(): string {
    const moduleNames = ['A', 'B', 'C', 'D'];
    return moduleNames[this.moduleIndex];
  }

  public get moduleSideName(): string {
    const moduleNames = ['spredaj', 'desno', 'zadaj', 'levo'];
    return moduleNames[this.index];
  }

  public opacityOptions: string[] = ['1%', '5%'];

  public get colorOptions(): string[] {
    const colorStringArr: string[] = [];
    if (this.panel) this.selectedOpacity = `${(this.panel as ShadeScreen).getOpacity()}%`;

    this.builderService.sceneBuilder.getScreenColors().forEach((color) => {
      if (this.panel && this.panel.getColor() === color) this.selectedColor = numberToHexString(color.code);
      colorStringArr.push(numberToHexString(color.code));
    });
    return colorStringArr;
  }

  removeScreen() {
    if (this.panel) {
      this.builderService.sceneBuilder.removePanel(this.panel);
      this.sceneService.sceneController.requestRenderIfNotRequested();
      this.deleted.emit();
    }
  }

  changeColor(index: number) {
    if (this.panel) {
      this.builderService.sceneBuilder.changeScreenColor(
        this.panel as ShadeScreen,
        this.builderService.sceneBuilder.getScreenColors()[index]
      );
      this.sceneService.sceneController.requestRenderIfNotRequested();
    }
  }

  changeOpacity(index: number) {
    if (this.panel) {
      this.builderService.sceneBuilder.changeScreenOpacity(
        this.panel as ShadeScreen,
        this.builderService.sceneBuilder.getScreenOpacities()[index]
      );
      this.sceneService.sceneController.requestRenderIfNotRequested();
    }
  }

  itemClick(evt: MouseEvent) {
    evt.stopPropagation();
    if ((evt as PointerEvent).pointerId === -1) return;

    const elements = document.querySelectorAll('mat-expansion-panel-header');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elements.forEach((el: any) => {
      if (evt.currentTarget !== el && (el as HTMLDivElement).classList.contains('mat-expanded')) el.click();
    });

    const opened = (evt.currentTarget as HTMLDivElement).classList.contains('mat-expanded');

    if (!opened) {
      const side = this.builderService.sceneBuilder.getPanelSide(this.panel);
      if (side !== undefined) {
        this.builderService.sceneBuilder.focusElement(this.panel, side, this.sceneService.sceneController);
        this.sceneService.sceneController.requestRenderIfNotRequested();
      }
    }
  }
}
