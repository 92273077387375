import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BuilderService } from '../SERVICES/BuilderService';
import { CurrentTime } from '../builders/enums';
import { SceneService } from 'modules/scene-setup/scene.service';
import { SceneBuilder } from '../builders/scene_builder';
import { LoaderService } from 'modules/loader/loader.service';
import { Vector3 } from 'three';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent implements OnInit, OnDestroy {
  @Output() loadingStatusChange = new EventEmitter<boolean>();

  private mouseClicked = false;

  private get canvas(): HTMLElement {
    return document.getElementById('viewerCanvas') as HTMLElement;
  }

  constructor(
    private loaderService: LoaderService,
    private builderService: BuilderService,
    private sceneService: SceneService
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    history.pushState(null, '', location.href);

    this.builderService.sceneBuilder = new SceneBuilder(this.builderService.settings);

    this.sceneService.init(
      this.canvas,
      this.builderService.sceneBuilder,
      new Vector3(5.5, 3.2, 9),
      new Vector3(0, 1, 0)
    );

    this.loaderService.loading = true;

    this.canvas.addEventListener('mousedown', (event: MouseEvent) => {
      (event.target as HTMLCanvasElement).style.cursor = 'grabbing';
      component.sceneService.sceneController.requestRenderIfNotRequested();
      component.mouseClicked = true;
    });

    this.canvas.addEventListener('mouseup', (event: MouseEvent) => {
      (event.target as HTMLCanvasElement).style.cursor = 'grab';
      component.mouseClicked = false;
    });

    this.canvas.addEventListener('mousemove', () => {
      if (component.mouseClicked) {
        component.sceneService.sceneController.requestRenderIfNotRequested();
      }
    });

    this.canvas.addEventListener('touchmove', () => {
      component.sceneService.sceneController.requestRenderIfNotRequested();
    });

    this.canvas.addEventListener('wheel', () => {
      component.sceneService.sceneController.requestRenderIfNotRequested();
    });

    this.builderService.sceneBuilder.initObjects(this.sceneService.sceneController.outlinePass).then(() => {
      component.builderService.setTime(CurrentTime.studio);
      component.loaderService.loading = false;
      component.sceneService.sceneController.requestRenderIfNotRequested();

      if (window.innerWidth <= 750) {
        component.sceneService.updateInitialCameraPosition();
      }

      this.loadingStatusChange.emit(false);
    });
  }

  ngOnDestroy(): void {
    this.builderService.selectedLayout = null;
    this.builderService.pergolaDimensions = null;
    this.builderService.selectedColor = null;
    this.builderService.time = null;
    this.builderService.sceneBuilder.dispose();
    this.sceneService.dispose();
  }
}
