import { Object3D, Mesh, MeshBasicMaterial, CapsuleGeometry } from 'three';
import { IBuilder } from '../interfaces';
import { ModuleState } from './module_builder';
import { PergolaBuilder } from './pergola_builder';
import { degToRad } from 'three/src/math/MathUtils';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';

export class LedBuilder extends Object3D implements IBuilder {
  width: number;
  length: number;
  height: number;
  ledWidth: number;
  rafterWidth: number;
  rafterHeight: number;

  postWidth: number;
  innerLeds = new Array<Mesh>();

  settings: ModuleState;
  rotations = [0, -Math.PI / 2.0, Math.PI, Math.PI / 2.0];

  private hasInner = true;

  public loaded: boolean = false;
  private _innerLedOffest = 0.005;

  private _ledMesh: Mesh;
  private _ledMaterial: MeshBasicMaterial;

  constructor(settings: ModuleState) {
    super();
    this.settings = settings;
    this.width = this.settings.width;
    this.height = this.settings.height;
    this.length = this.settings.length;
    this.postWidth = this.settings.postSettings.width;
    this.ledWidth = this.settings.ledSettings.width;
    this.rafterWidth = this.settings.rafterSettings.width;
    this.rafterHeight = this.settings.rafterSettings.height;
    this.initObjects();
  }

  dispose(): void {
    for (const inner of this.innerLeds) {
      PergolaBuilder.removeObjectsWithChildren(inner);
    }

    PergolaBuilder.removeObjectsWithChildren(this._ledMesh);
    PergolaBuilder.removeObjectsWithChildren(this._ledMaterial);

    this.innerLeds = [];
  }

  initObjects() {
    const geometry = new CapsuleGeometry(0.01, 1, 5, 20);
    this._ledMaterial = new MeshBasicMaterial({ color: 0xffffff });
    this._ledMesh = new Mesh(geometry, this._ledMaterial);

    for (let i = 0; i < 4; i++) {
      this.innerLeds.push(this._ledMesh.clone());
      this.innerLeds[i].visible = this.settings.ledSettings.selectedInnerLed === 1;
      this.add(this.innerLeds[i]);
    }

    this.loaded = true;
    this.updateDimensions();
    this.rotateInnerLeds();
    this.updateColor();
  }

  private rotateInnerLeds() {
    this.innerLeds[0].rotateZ(degToRad(90));
    this.innerLeds[2].rotateZ(degToRad(90));
    this.innerLeds[1].rotateX(degToRad(90));
    this.innerLeds[3].rotateX(degToRad(90));
  }

  updateDimensions() {
    this.width = this.settings.width;
    this.height = this.settings.height;
    this.length = this.settings.length;
    this.updateLeds();
  }

  private updateLeds() {
    if (!this.loaded) {
      return;
    }

    this.updateInnerLeds();
  }

  private updateInnerLeds() {
    this.innerLeds[0].scale.setY(this.width - this.rafterWidth * 2);
    //this.innerLeds[0].height = this.ledWidth;
    this.innerLeds[0].position.set(
      0,
      this.height - this.postWidth + this.ledWidth / 2 + this._innerLedOffest,
      this.length / 2.0 - this.rafterWidth - 0.15
    );

    this.innerLeds[1].scale.setY(this.length - this.rafterWidth * 2);
    //this.innerLeds[1].height = this.ledWidth;
    this.innerLeds[1].position.set(
      this.width / 2 - this.rafterWidth - 0.15,
      this.height - this.postWidth + this.ledWidth / 2 + this._innerLedOffest,
      0
    );

    this.innerLeds[2].scale.setY(this.width - this.rafterWidth * 2);
    //this.innerLeds[2].height = this.ledWidth;
    this.innerLeds[2].position.set(
      0,
      this.height - this.postWidth + this.ledWidth / 2 + this._innerLedOffest,
      this.length / -2.0 + this.rafterWidth + 0.15
    );

    this.innerLeds[3].scale.setY(this.length - this.rafterWidth * 2);
    //this.innerLeds[3].height = this.ledWidth;
    this.innerLeds[3].position.set(
      this.width / -2 + this.rafterWidth + 0.15,
      this.height - this.postWidth + this.ledWidth / 2 + this._innerLedOffest,
      0
    );
  }

  public updateInnerLights(glowPass: OutlinePass) {
    this.hasInner = this.settings.ledSettings.selectedInnerLed === 1;

    for (const light of this.innerLeds) {
      if (this.hasInner) {
        glowPass.selectedObjects.push(light);
      } else {
        glowPass.selectedObjects = glowPass.selectedObjects.filter((el) => el !== light);
      }

      light.visible = this.hasInner;
    }
  }

  public updateLightColor() {
    this._ledMaterial.color.set(this.settings.ledSettings.color.code);
  }

  updateColor() {
    this.updateLightColor();
  }
}
