<div style="position: relative;"><div class="colorTitle">Izberite barvo stene:</div>
<input
(colorPickerChange)="setWallColor()"
id="colorPicker"
[value]="color"
[(colorPicker)]="color"
[cpPosition]="'top'"
[cpPositionOffset]="'10%'"
[cpPositionRelativeToArrow]="false"
[cpAlphaChannel]="'disabled'"
[cpOutputFormat]="'hex'"
[cpDisableInput]="true" readonly/>
</div>
