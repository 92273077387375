<div class="container">
    <button mat-flat-button class="backButton" (click)="backClick()">
      Nazaj
    </button>
    <h1>Uporaba piškotkov na spletnem mestu: sencila-bled.4th.vision</h1>
      <p>
        Upravljavec spletnega mesta in vseh podatkov povezanih z njim je:
      </p>
      <ul>
        <li>Naziv podjetja: SENČILA BLED D.O.O</li>
        <li>Naslov: Pot na Lisce 11, 4260 Bled</li>
        <li>Elektronski naslov: info@sencila-bled.si</li>
        <li>Kontaktna telefonska številka: + 386 (0) 4 575 23 09</li>
      </ul>
  
      <h2>Vrste piškotkov, njihov namen in čas trajanja:</h2>
      <p>
        Piškotki so majhne datoteke, ki jih naložimo na vaš računalnik. Pomagajo pri hitrejšem delovanju spletne strani. Spletno mesto uporablja naslednje piškotke:
      </p>
  
      <ul>
        <li>Ime piškotka: _ga</li>
        <li>Namen piškotka: Googlova statistika ogledov</li>
        <li>Čas trajanja: 13 mesecev</li>
      </ul>
  
      <ul>
        <li>Ime piškotka: _clck</li>
        <li>Namen piškotka: Bingova statistika ogledov</li>
        <li>Čas trajanja: 12 mesecev</li>
      </ul>
  
      <h2>Obvestilo brez privolitve:</h2>
      <p>
        Upravljavec zagotavlja obvestilo brez privolitve, v kolikor uporablja te piškotke:
      </p>
      <p>
        Piškotki, ki so potrebni izključno zaradi prenosa sporočila po elektronskem komunikacijskem omrežju in piškotki, ki so nujno potrebni za zagotovitev storitve informacijske družbe, ki jo naročnik ali uporabnik izrecno zahtevata.
      </p>
  
      <h2>Obvestilo s privolitvijo uporabnika:</h2>
      <p>
        Upravljavec zagotavlja obvestilo s privolitvijo uporabnika v vseh ostalih primerih in uporabnika ustrezno obvešča o možnostih nastavitev piškotkov. 
        Upravljavec brez izrecne privolitve ne uporablja piškotkov, ki potrebujejo privolitev uporabnika za namestitev. 
        Upravljavec zagotavlja možnost naknadne spremembe privolitve uporabnika s tem, da obvestilo ohranja na vidnem mestu na spletni strani.
      </p>
  </div>
  