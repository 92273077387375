import { Component } from '@angular/core';
import { IColor } from '../builders/interfaces';
import { Color } from 'three';
import { BuilderService } from '../SERVICES/BuilderService';
import { numberToHexString } from '../helpers';

@Component({
  selector: 'app-wall-color-config',
  templateUrl: './wall-color-config.component.html',
  styleUrls: ['./wall-color-config.component.scss'],
})
export class WallColorConfigComponent {
  public color: string = '';
  constructor(public builderService: BuilderService) {
    this.color = numberToHexString(this.builderService.getState().walls.color.code);
  }
  public setWallColor() {
    const tempColor: IColor = {
      code: new Color(this.color).getHex(),
      id: '',
      name: '',
      customColor: '',
    };
    this.builderService.configWallColorEmitter.emit(tempColor);
  }
}
