import {
  Box3,
  FrontSide,
  Mesh,
  MeshStandardMaterial,
  PlaneGeometry,
  RepeatWrapping,
  SRGBColorSpace,
  Texture,
  TextureLoader,
  Vector3,
} from 'three';
import { IColor, SideAccessoryType } from '../../interfaces';
import { CurtainClosingType, Side, SideAccessoryState } from '../../enums';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PergolaBuilder } from '../pergola_builder';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { SceneController } from 'modules/scene-setup/SceneController';
import { SideAccessory } from './side_accessory';
gsap.registerPlugin(ScrollTrigger);

export class Curtain extends SideAccessory {
  private _curtainMaterial: MeshStandardMaterial;
  private _modelsMaterial: MeshStandardMaterial;
  private _geometry: PlaneGeometry;
  private _curtainOuter: Mesh;
  private _curtainInner: Mesh;

  private _topModel: Mesh;

  private _boundinBoxTopSideDimensions: Vector3;

  private _curtainOffset: number = -0.02;
  private _stretched: number = 0.5;
  private _amplitude: number = 0.025;
  private _frequency: number = 50;
  private _minStretched: number = 0.1;

  private _width: number | number[];
  private _height: number;
  private _closingType: CurtainClosingType = CurtainClosingType.left;

  static objectName: string = 'curtain';
  private _child: Curtain | null = null;
  override _order: number = 1;

  private h: number = 0.1;
  private v: number = 1;
  private w: number = 0.002;
  private s: number = 0.08;
  private segW: number = 50;
  private segH: number = 30;

  dispose() {
    PergolaBuilder.removeObjectsWithChildren(this._curtainMaterial);
    PergolaBuilder.removeObjectsWithChildren(this._modelsMaterial);
    PergolaBuilder.removeObjectsWithChildren(this._geometry);
    PergolaBuilder.removeObjectsWithChildren(this._curtainOuter);

    PergolaBuilder.removeObjectsWithChildren(this._topModel);

    PergolaBuilder.removeObjectsWithChildren(this);
  }

  constructor(private _models: Array<Mesh | Texture>, private _frameColor: IColor, private _curtainColor: IColor) {
    super();
    this._topModel = this._models[0].clone() as Mesh;

    const boundinBoxTopSide = new Box3().setFromObject(this._topModel);
    this._boundinBoxTopSideDimensions = boundinBoxTopSide.getSize(new Vector3(0, 0, 0));

    this.add(this._topModel);

    this._modelsMaterial = new MeshStandardMaterial({
      color: this._frameColor.code, // Semi-transparent green color
      transparent: false,
      roughness: 0.8,
      metalness: 0.5,
    });

    this._topModel.material = this._modelsMaterial;

    // Add screen
    this._curtainMaterial = new MeshStandardMaterial({
      color: this._curtainColor.code, // Semi-transparent green color
      transparent: true,
      opacity: 1,
      side: FrontSide,
      map: this._models[1] as Texture,
    });

    this._geometry = new PlaneGeometry(1, 1, this.segW, this.segH);
    this._curtainOuter = new Mesh(this._geometry, this._curtainMaterial);
    this._curtainOuter.castShadow = true;

    this._curtainInner = new Mesh(this._geometry, this._curtainMaterial);
    this._curtainInner.castShadow = true;
    this._curtainInner.rotation.y = Math.PI;

    this.add(this._curtainInner);
    this.add(this._curtainOuter);
    this.frustumCulled = false;
    this._curtainOuter.frustumCulled = false;
    this._curtainOuter.frustumCulled = false;
    
  }

  private updateWaves() {
    // update inner
    /*const positionAttr = this._curtainOuter.geometry.getAttribute('position');
    for (let i = 0; i < positionAttr.count; i++) {
      const x = positionAttr.getX(i);
      const y = positionAttr.getY(i);
      const z = this._amplitude * Math.sin(this._frequency * x);
      positionAttr.setXYZ(i, x, y, z);
    }
    this._curtainOuter.geometry.computeVertexNormals();
    this._curtainInner.geometry.computeVertexNormals();*/

    const start = 0;

    const positionsOuter = this._curtainOuter.geometry.getAttribute('position').array as Float32Array;
    const positionsInner = this._curtainInner.geometry.getAttribute('position').array as Float32Array;

    /*for (let y = 0; y < this.segH + 1; y++) {
      for (let x = 0; x < this.segW + 1; x++) {
        const index = 3 * (x + y * (this.segW + 1));

        const displacement = (Math.sin(this.h * x + this.v * y - time) * this.w * x) / 4;

        positionsOuter[index + 2] = displacement;
        positionsInner[index + 2] = displacement;
      }
    }*/

    const diff =
      this.h * (this.segH - 1 + 20) + this.v * (this.segW - 1) - start - (this.h * (0 + 20) + this.v * 0 - start);
    for (let y = 0; y < this.segH + 1; y++) {
      for (let x = 0; x < this.segW + 1; x++) {
        const index = 3 * (x + y * (this.segW + 1));

        const displacementOuter = (Math.sin(this.h * (y + 20) + this.v * x - start) * this.w * (y + 20)) / 4; // use y instead of x
        const displacementInner = (Math.sin(this.h * (y + 20) + this.v * x - start - diff) * this.w * (y + 20)) / 4; // use y instead of x

        positionsOuter[index + 2] = displacementOuter;
        positionsInner[index + 2] = displacementInner;
      }
    }

    this._curtainOuter.geometry.getAttribute('position').needsUpdate = true;
    this._curtainInner.geometry.getAttribute('position').needsUpdate = true;

    this._curtainOuter.geometry.computeVertexNormals();
    this._curtainInner.geometry.computeVertexNormals();
    //requestAnimationFrame(() => this.updateWaves());
  }

  private _setDimensionsMainModel(width: number, height: number, offset: number = 0) {
    this._topModel.position.y = height / 2;
    this._topModel.position.x = width / -2;
    this._topModel.position.z = -this._boundinBoxTopSideDimensions.z;
    this._topModel.scale.x = width;

    let curtainWidth;

    if (this._stretched > 1) {
      this._closingType = CurtainClosingType.right;
      const strech = 2 - this._stretched;
      curtainWidth =
        strech * (width) * (1 - this._minStretched) +
        this._minStretched * (width);
    } else {
      this._closingType = CurtainClosingType.left;
      curtainWidth =
        this._stretched * (width) * (1 - this._minStretched) +
        this._minStretched * (width );
    }

    if (this._closingType === CurtainClosingType.left) {
      this._curtainOuter.position.x =
        (width / 2 - curtainWidth / 2) * -1 + offset;
      this._curtainInner.position.x =
        (width / 2 - curtainWidth / 2) * -1 + offset;
    } else {
      this._curtainOuter.position.x = width / 2 - curtainWidth / 2 + offset;
      this._curtainInner.position.x = width / 2 - curtainWidth / 2 + offset;
    }

    this._curtainOuter.position.z = this._curtainOffset;
    this._curtainOuter.position.y = -this._boundinBoxTopSideDimensions.y / 2;
    this._curtainOuter.scale.set(curtainWidth, height - this._boundinBoxTopSideDimensions.y, 1);

    this._curtainInner.position.z = this._curtainOffset;
    this._curtainInner.position.y = -this._boundinBoxTopSideDimensions.y / 2;
    this._curtainInner.scale.set(curtainWidth, height - this._boundinBoxTopSideDimensions.y, 1);
    this.updateWaves();
  }

  override setDimensions(width: number | number[], height: number) {
    this._width = width;
    this._height = height;
    if (width instanceof Array) {
      if (this._child === null) {
        this._child = new Curtain(this._models, this._frameColor, this._curtainColor);
        this.add(this._child);
        this._child.updateState(this._state);
        this._child.setStreched((this._stretched / 2) * 100);
      }
      this._child.visible = true;

      this._setDimensionsMainModel(width[0], height, width[0] / -2 - 0.16 / 2);
      this._child.setDimensions(width[1], height);
      this._child.position.x = width[1] / 2 + 0.16 / 2;
    } else {
      if (this._child !== null) {
        this._child.visible = false;
      }
      this._setDimensionsMainModel(width, height);
    }
  }

  setStreched(percentage: number) {
    this._stretched = percentage / 100;
    this._stretched *= 2;

    this.setDimensions(this._width, this._height);
    if (this._child !== null) {
      this._child.setStreched((this._stretched / 2) * 100);
    }
  }

  override updateState(state: SideAccessoryState): void {
    this._state = state;

    if (this._state === SideAccessoryState.preview) {
      this._curtainMaterial.opacity = 0.5;
      this._modelsMaterial.opacity = 0.5;
    }
    if (this._state === SideAccessoryState.added) {
      this._curtainMaterial.opacity = 1;
      this._modelsMaterial.opacity = 1;
    }
    if (this._state === SideAccessoryState.ghost) {
      this._curtainMaterial.opacity = 0.1;
      this._modelsMaterial.opacity = 0.1;
    }

    if (this._child !== null) {
      this._child.updateState(this._state);
    }
  }

  static async getModels(loader: GLTFLoader): Promise<Array<Mesh | Texture>> {
    const models = await Promise.all([
      loader.loadAsync('./assets/builder/KE/curtains/curtain_top.glb'),
    ]);

    const meshes: (Mesh | Texture)[] = [];

    models.forEach((model) => {
      const mesModel: Mesh = model.scene.children[0] as Mesh;
      mesModel.castShadow = true;
      mesModel.receiveShadow = true;
      meshes.push(mesModel);
    });

    const textureLoader = new TextureLoader();
    const texture = await textureLoader.load('/assets/builder/curtina_material.jpg');
    texture.colorSpace = SRGBColorSpace;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.set(3, 3);

    meshes.push(texture);
    return meshes;
  }

  override getColor(): IColor {
    return this._curtainColor;
  }

  setColor(color: IColor): void {
    this._curtainColor = color;
    this._curtainMaterial.color.set(color.code);
  }

  setClosingType(type: CurtainClosingType) {
    this._closingType = type;
    this.setDimensions(this._width, this._height);
    if (this._child !== null) {
      this._child.setClosingType(this._closingType);
    }
  }

  getClosingType(): CurtainClosingType {
    return this._closingType;
  }

  override getState(): SideAccessoryType {
    return {
      type: Curtain.objectName,
      color: this._curtainColor,
      closingSide: this._closingType,
    };
  }

  override updateColor(color: IColor) {
    this._frameColor = color;
    this._modelsMaterial.color.set(color.code);
    if (this._child !== null) {
      this._child.updateColor(this._frameColor);
    }
  }

  override focus(side: Side, sceneController: SceneController) {
    const bbox = new Box3().setFromObject(this);
    const bboxCenter: Vector3 = new Vector3();
    bbox.getCenter(bboxCenter);

    let val = bboxCenter.x;
    if (side === Side.front || side === Side.back) val = bboxCenter.z;

    gsap.to(sceneController.camera.position, {
      duration: this._animationDuration,
      x: bboxCenter.x + (val === bboxCenter.x ? 10 * Math.sign(val) : 0),
      y: bboxCenter.y,
      z: bboxCenter.z + (val === bboxCenter.z ? 10 * Math.sign(val) : 0),
    });

    gsap.to(sceneController.orbitControls.target, {
      duration: this._animationDuration,
      x: 0,
      y: 0,
      z: 0,
    });
  }
}
