<section id="nav-container">
    <header id="nav-header">
        <a href="https://sencila-bled.si/"><img id="logo" src="../../assets/sencila_logo.png"/></a>

        <mat-horizontal-stepper #stepper>
            <mat-step [editable]="false">
                <ng-template matStepLabel>Izbira tipa</ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Izbira postavitve</ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Izbira dimenzij</ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Izbira barv</ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Izbira stranskih dodatkov</ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Izbira drugih dodatkov</ng-template>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Nastavitve okolice</ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Potrditev povpraševanja</ng-template>
            </mat-step>
        </mat-horizontal-stepper>
    </header>

    <div id="nav-body" [style.overflow]="currentState > navigationState.Types ? 'hidden' : 'hidden auto'" [ngSwitch]="currentState">

       <div class="buttons" *ngIf="currentState > navigationState.Types && !formSubmitted">
            <button id="button-ar" (click)="onARClick(true)" mat-raised-button>
                <span>Postavi v prostor</span>
                <img width="32px" height="32px" src="./assets/icons/AR.svg" >
              </button>

              <button id="button-env" (click)="onEnvironmentClick()" mat-raised-button>
                <span>Okolica</span>
                <img width="32px" height="32px" src="./assets/icons/settings.svg" >
              </button>
        </div>

        <app-ar-component [(qrUrl)]="qrUrl"></app-ar-component>

        <app-video-overlay *ngIf="showVideo" 
        (videoClosed)="onVideoClose()" 
        [title]="'Navodilo za uporabo'"
        [subtitle]="'Prosimo oglejte video za pomoč pri uporabi obogatene resničnosti. V primeru težav nas prosimo kontaktirajte!'"
        [videoUrl]="'./assets/AR_navodila.mp4'"
        [buttonText]="'Postavi v prostor'"></app-video-overlay>

        <app-video-overlay *ngIf="showVideo2" 
        (videoClosed)="onVideoClose2()" 
        [title]="'Delovanje obogatene resnicnosti'"
        [subtitle]="'Prosimo oglejte informativni video o obogateni resničnosti.'"
        [videoUrl]="'./assets/AR_za_pergole.mp4'"
        [buttonText]="'Poskusi obogateno resničnost'"></app-video-overlay>

        <app-type-selection *ngSwitchCase="navigationState.Types" (typeClick)="typeClicked(stepper)"></app-type-selection>
        <app-submit-form (formSubmitted)="onFormSubmitted()" *ngSwitchCase="navigationState.Submit"></app-submit-form>

        <div *ngIf="currentState > navigationState.Types && !formSubmitted" 
          id="toggle-menu" (click)="toggleMenu()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>

        <app-viewer (loadingStatusChange)="onLoadingStatusChange($event)" *ngIf="currentState > navigationState.Types && !formSubmitted"></app-viewer>

        <div id="nav-side-menu" *ngIf="currentState >= navigationState.Layouts && !formSubmitted">
            <div class="container" *ngSwitchCase="navigationState.Layouts">
              <h1 id="selection-header">Izberite tip postavitve:</h1>
              <app-layout-selection ></app-layout-selection>
            </div>
            <div class="container" *ngSwitchCase="navigationState.Dimensions">
              <h1 id="selection-header">Izberite dimenzije:</h1>
              <app-dimension-config ></app-dimension-config>
            </div>
            <div class="color-container" *ngSwitchCase="navigationState.Colors">
              <div>
                <h1 id="selection-header">Izberite barvo konstrukcije:
                  <br><span id="selection-subtext">* Barve se lahko razlikujejo v končnem izdelku</span>
                </h1>
                
                <app-color-config [mode]="colorConfigMode.frame"></app-color-config>
              </div>
              <div>
                <h1 id="selection-header">Izberite barvo lamel:</h1>
                <app-color-config  [mode]="colorConfigMode.louvers"></app-color-config>
              </div>
              <div *ngIf="hideWallColorPicker" [hidden]="!builderService.selectedLayout || builderService.selectedLayout.id === 'layout1'">
                <h1 id="selection-header">Izberite barvo stene:</h1>
                <app-wall-color-config></app-wall-color-config>
              </div>
            </div>
            <div class="container" *ngSwitchCase="navigationState.Screens">
              <h1 id="selection-header">Izberite stranske dodatke:</h1>
              <app-screen-config ></app-screen-config>
            </div>
            <div class="container" *ngSwitchCase="navigationState.Accessories">
              <h1 id="selection-header">Izberite druge dodatke:</h1>
              <app-accesories-config ></app-accesories-config>
            </div>
            <div *ngSwitchCase="navigationState.Environment">
              <app-environment-config [showClose]="showCloseButton" (closeClick)="onEnvironmentClose()" ></app-environment-config>
            </div>
        </div>

    </div>

    <footer id="nav-footer">
        <button [disabled]="currentState === navigationState.Types || formSubmitted" class="footer-button" id="back-button" mat-flat-button (click)="onBack(stepper)">
                <mat-icon translate="no" class="footer-icon">west</mat-icon>
                <span>Nazaj</span>
        </button>

        <button [disabled]="currentState === navigationState.Types || formSubmitted" *ngIf="currentState !== navigationState.Submit" id="next-button" class="footer-button" mat-flat-button (click)="onNext(stepper)">
            <span>Naprej</span>
            <mat-icon translate="no" class="footer-icon">east</mat-icon>
        </button>

        <button [disabled]="formSubmitted" *ngIf="currentState === navigationState.Submit" class="footer-button" id="finish-button" mat-flat-button (click)="submitForm()">
            <span>Potrdi</span>
            <mat-icon translate="no" class="footer-icon">east</mat-icon>
        </button>
    </footer>
</section>

<app-component-loader *ngIf="loading | async" [logo]="'../../assets/sencila_logo.png'" [text]="'Nalaganje'"></app-component-loader>
