
<qrcode [qrdata]="qrUrl" [ariaLabel]="'QR Code for configuration'"
        [cssClass]="'qr-code'"
        [colorDark]="'#000000ff'"
        [colorLight]="'#ffffffff'"
        [elementType]="'canvas'"
        [errorCorrectionLevel]="'M'"
        [margin]="0"
        [scale]="1"
        [title]="'View created pergola in your space'"
        [width]="256">
</qrcode>