import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { IPergolaSettings, ISideCurtain, ISideScreen, ISideWindows } from '../builders/interfaces';
import { BuilderService } from './BuilderService';
import { Box3, PerspectiveCamera, SRGBColorSpace, WebGLRenderer } from 'three';
import { PergolaSettings } from '../builders/KE_builder/pergola_settings';
import { SubmitForm } from '../submit-form/submit-form.component';
import { environment } from 'src/environments/environment';
import { deflate } from 'pako';
import { SceneService } from 'modules/scene-setup/scene.service';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  public submitEventEmmiter: EventEmitter<void>;

  constructor(private http: HttpClient) {
    this.submitEventEmmiter = new EventEmitter();
  }

  public async loadSettings(builderService: BuilderService): Promise<void> {
    let path = '';

    if (builderService.type === 'Skylife') {
      path = 'assets/ke-skylife-settings.json';
    } else if (builderService.type === 'Hardtop') {
      path = 'assets/ke-prime-settings.json';
    }

    const response = await firstValueFrom(this.http.get<IPergolaSettings>(path));

    const settings = new PergolaSettings();
    settings.loadSettings(response);
    builderService.settings = settings;

    //await sceneService.sceneController.loadDayHDR();
    /*if (!builderService.hdrDay) {
      const loader = new RGBELoader();
      const loader2 = new TextureLoader();

      const texture = await loader.loadAsync('assets/day.hdr');
      const image = await loader2.loadAsync('assets/day.jpg');
      image.generateMipmaps = false;

      texture.mapping = EquirectangularReflectionMapping;
      image.mapping = EquirectangularReflectionMapping;
      image.colorSpace = SRGBColorSpace;

      builderService.hdrDay = texture;
      builderService.daySkybox = image;
    }

    if (!builderService.hdrNight) {
      this.loadNightSkybox(builderService);
    }*/
  }

  public getConfigurationUrl(builderService: BuilderService): string {
    const configurationJSON = builderService.getState();

    if (configurationJSON) {
      const compressedByteArray = deflate(JSON.stringify(configurationJSON)); // Compress the string using deflate format
      const compressedNumberArray = Array.from(compressedByteArray); // Convert the compressed byte array to an array of numbers
      const compressedString = btoa(String.fromCharCode.apply(null, compressedNumberArray)); // Convert the array of numbers to a string and encode it
      const currentUrl = new URL(window.location.href); // Parse the current URL
      currentUrl.searchParams.set('data', compressedString); // Add the compressed string as a new query parameter
      currentUrl.searchParams.set('ar', 'true');
      return currentUrl.toString();
    }

    return '';
  }

  public getScreenshot(sceneService: SceneService, builderService: BuilderService): string {
    const screenshotWidth = 1920;
    const screenshotHeight = 1080;

    // Create a temporary camera for taking the screenshot
    const tempCamera = sceneService.sceneController.camera.clone() as PerspectiveCamera;
    tempCamera.aspect = screenshotWidth / screenshotHeight;
    tempCamera.updateProjectionMatrix();

    const screenshotRenderer = new WebGLRenderer({ antialias: true });
    screenshotRenderer.setSize(screenshotWidth, screenshotHeight);
    screenshotRenderer.setClearColor(0xeeeeee, 1);
    screenshotRenderer.outputColorSpace = SRGBColorSpace;

    const bbox = new Box3().setFromObject(builderService.sceneBuilder.getPergola3Dmodel());

    sceneService.sceneController.cameraController.setIsometricView(tempCamera, bbox, tempCamera.aspect);

    screenshotRenderer.render(sceneService.sceneController.scene, tempCamera);
    const screenshot = screenshotRenderer.domElement.toDataURL('image/jpeg').replace('data:image/jpeg;base64,', '');

    screenshotRenderer.dispose();

    return screenshot;
  }

  public async submitOffer(
    sceneService: SceneService,
    builderService: BuilderService,
    form: SubmitForm
  ): Promise<number> {
    const url = environment.APIURL + 'offers';
    const state = builderService.getState();

    const moduleItems = [];
    const moduleNames = ['A', 'B', 'C', 'D'];

    for (let i = 0; i < state.modules.length; i++) {
      if (state.modules[i]) {
        const moduleName = `Modul ${moduleNames[i]}`;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        for (const [key, value] of Object.entries(state.modules[i]!)) {
          let stran = '';
          let accesory = '';
          let properties = '';

          switch (key) {
            case 'front':
              stran = 'Sprednja';
              break;
            case 'back':
              stran = 'Zadnja';
              break;
            case 'left':
              stran = 'Leva';
              break;
            case 'right':
              stran = 'Desna';
              break;
          }

          for (const acc of value) {
            switch (acc?.type) {
              case 'screen':
                accesory = 'Senčilo';
                properties = `Barva: ${(acc as ISideScreen).color.name}\nProsojnost: ${(acc as ISideScreen).opacity}`;
                break;
              case 'windows':
                accesory = 'Vrata';
                properties = `Zapiranje: ${(acc as ISideWindows).closingSide === 0 ? 'Levo' : 'Desno'}\nŠtevilo oken: ${
                  (acc as ISideWindows).numberWindows
                }`;
                break;
              case 'curtain':
                accesory = 'Zavesa';
                properties = `Barva: ${(acc as ISideCurtain).color.name}`;
                break;
            }

            moduleItems.push({
              modul: moduleName,
              side: stran,
              accessory: accesory,
              properties: properties,
            });
          }
        }
      }
    }

    const data = {
      pergolaType: 'Kedry ' + state.pergolaType,
      pergolaLayout: state.pergolaLayout?.name,
      width: state.width,
      length: state.length,
      height: state.height,
      frameColor: state.frameColor.customColor ? state.frameColor.customColor : state.frameColor.name,
      louversColor: state.louversColor.customColor ? state.louversColor.customColor : state.louversColor.name,
      addOns: {
        sunSensor: state.otherSensorsSettings.sunSensor ? 'Da' : 'Ne',
        rainSensor: state.otherSensorsSettings.rainSensor ? 'Da' : 'Ne',
        windSensor: state.otherSensorsSettings.windSensor ? 'Da' : 'Ne',
        innerLed: state.ledSettings.innerLedOptions[state.ledSettings.selectedInnerLed],
      },
      image1: this.getScreenshot(sceneService, builderService),
      configurationUrl: this.getConfigurationUrl(builderService),
      buyerInfo: {
        name: form.name,
        surname: form.surname,
        phoneNumber: form.phoneNumber,
        email: form.email,
        address: form.address.trim() + ', ' + form.city.trim(),
        comment: form.comment,
      },
      items: moduleItems,
    };

    const req = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer 88a50d6a9d0deb5575ff7b334a2a83a097100ba0',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return req.status;
  }
}
