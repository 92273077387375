/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IBuilder,
  IColor,
  ISideAccessoryArr,
  ISideCurtain,
  ISideScreen,
  ISideWindows,
  SideAccessoryArr,
} from '../interfaces';
import { Intersection, Object3D, Event as THREEJSEvent, Mesh, Texture } from 'three';
import { SideAccessory } from './side_accessories/side_accessory';
import { ShadeScreen } from './side_accessories/shade_screen';

import { CurtainClosingType, Side, SideAccessoryState, WindowClosinType } from '../enums';
import { ModuleState } from './module_builder';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PergolaBuilder } from './pergola_builder';
import { GlassDoors, Window } from './side_accessories/glass_doors';
import { Curtain } from './side_accessories/curtain';

export class SideAccessoryController extends Object3D implements IBuilder {
  settings: ModuleState;
  placeholdersVisible: boolean = false;

  private _screenModels: Array<Mesh>;
  private _windowModels: Array<Mesh>;
  private _curtainModels: Array<Mesh | Texture>;

  public loaded: boolean = false;

  constructor(settings: ModuleState, private _loader: GLTFLoader) {
    super();
    this.settings = settings;
    this.updateDimensions();
  }

  dispose(): void {
    for (const screen of this._screenModels) {
      PergolaBuilder.removeObjectsWithChildren(screen);
    }

    for (const window of this._windowModels) {
      PergolaBuilder.removeObjectsWithChildren(window);
    }

    for (const curtain of this._curtainModels) {
      PergolaBuilder.removeObjectsWithChildren(curtain);
    }

    this._screenModels = [];
    this._windowModels = [];
    this._curtainModels = [];

    PergolaBuilder.removeObjectsWithChildren(this);
  }

  createSideAccessoryInstance<T extends SideAccessory>(
    classType: new (...args: any[]) => T,
    side: Side,
    ...args: any[]
  ): T {
    const instance = new classType(...args);
    instance.rotation.set(0, this._calculateRotation(side), 0);
    return instance;
  }

  private _addScreen(side: Side): SideAccessory {
    const instance = new ShadeScreen(
      this._screenModels,
      this.settings.rafterSettings.color,
      this.settings.shadesColors[0]
    );
    instance.setStreched(this.settings.screenStreched);
    instance.rotation.set(0, this._calculateRotation(side), 0);
    return instance;
  }

  private _addWindow(side: Side): SideAccessory {
    const instance = new GlassDoors(this._windowModels, this.settings.rafterSettings.color);
    instance.setStreched(this.settings.windowStreched);
    instance.rotation.set(0, this._calculateRotation(side), 0);
    return instance;
  }

  private _addCurtain(side: Side): SideAccessory {
    const instance = new Curtain(
      this._curtainModels,
      this.settings.rafterSettings.color,
      this.settings.curtainColors[0]
    );
    instance.setStreched(this.settings.curtainStreched);
    instance.rotation.set(0, this._calculateRotation(side), 0);
    return instance;
  }

  async initObject(): Promise<void> {
    this._screenModels = await ShadeScreen.getModels(this._loader);
    this._windowModels = await GlassDoors.getModels(this._loader);
    this._curtainModels = await Curtain.getModels(this._loader);

    const sideNames = ['front', 'right', 'back', 'left'];

    if (this.settings.startModulesAccesories) {
      for (let sideIndex = 0; sideIndex < sideNames.length; sideIndex++) {
        const accessory = this.settings.startModulesAccesories[sideNames[sideIndex]];

        for (const acc of accessory) {
          if (acc.type === ShadeScreen.objectName) {
            const screen = acc as ISideScreen;
            this.addScreen(sideIndex, screen.opacity, screen.color);
          }
          if (acc.type === GlassDoors.objectName) {
            const window = acc as ISideWindows;
            this.addWindow(sideIndex, window.numberWindows, window.closingSide);
          }
          if (acc.type === Curtain.objectName) {
            const curtain = acc as ISideCurtain;
            this.addCurtain(sideIndex, curtain.closingSide, curtain.color);
          }
        }
      }
    }
    this.loaded = true;
  }

  _mod(n: number, m: number): number {
    return ((n % m) + m) % m;
  }

  private _caclualteWidth(side: Side): number | number[] {
    let width = 0;

    // add lengths of the pergola
    if (side % 2 === 0) {
      width += this.settings.width;
    } else {
      width += this.settings.length;
    }

    if (!this.settings.wallSettings.sides.includes(this._mod(side - 1, 4))) {
      if (side % 2 === 0) {
        width -= this.settings.postSettings.width;
      } else {
        width -= this.settings.postSettings.length;
      }
    }

    if (!this.settings.wallSettings.sides.includes(this._mod(side + 1, 4))) {
      if (side % 2 === 0) {
        width -= this.settings.postSettings.width;
      } else {
        width -= this.settings.postSettings.length;
      }
    }

    if (this.settings.wallSettings.sides.includes(2) && this.settings.moduleIndex >= 2 && side % 2 === 1) {
      if (
        this.settings.wallSettings.sides.includes(this._mod(side - 1, 4)) ||
        this.settings.wallSettings.sides.includes(this._mod(side + 1, 4))
      ) {
        width -= this.settings.postSettings.width;
      }
    }

    if (this.settings.wallSettings.sides.includes(3) && this.settings.moduleIndex % 2 === 1 && side % 2 === 0) {
      if (
        this.settings.wallSettings.sides.includes(this._mod(side - 1, 4)) ||
        this.settings.wallSettings.sides.includes(this._mod(side + 1, 4))
      ) {
        width -= this.settings.postSettings.width;
      }
    }

    if (this.settings.length > this.settings.minLengthAddPost) {
      let width1 = (this.settings.length - this.settings.postSettings.length * 3) / 2;
      let width2 = (this.settings.length - this.settings.postSettings.length * 3) / 2;
      if (side === Side.left) {
        if (this.settings.wallSettings.sides.includes(Side.back)) {
          width1 += this.settings.postSettings.length;
        }
        if (this.settings.wallSettings.sides.includes(Side.front)) {
          width2 += this.settings.postSettings.length;
        }
        return [width1, width2];
      }
      if (side === Side.right) {
        if (this.settings.wallSettings.sides.includes(Side.back)) {
          width1 += this.settings.postSettings.length;
        }
        if (this.settings.wallSettings.sides.includes(Side.front)) {
          width2 += this.settings.postSettings.length;
        }
        return [width2, width1];
      }
    }

    return width;
  }

  private _calculateOffset(side: Side): number {
    let offset = 0; // additional width if
    if (this.settings.wallSettings.sides.includes(this._mod(side - 1, 4))) {
      if (side % 2 === 0 && !(this.settings.wallSettings.sides.includes(3) && this.settings.moduleIndex % 2 === 1)) {
        offset += this.settings.postSettings.width;
      } else if (side % 2 === 1 && !(this.settings.wallSettings.sides.includes(2) && this.settings.moduleIndex >= 2)) {
        offset += this.settings.postSettings.length;
      }
    }

    if (this.settings.wallSettings.sides.includes(this._mod(side + 1, 4))) {
      if (side % 2 === 0 && !(this.settings.wallSettings.sides.includes(3) && this.settings.moduleIndex % 2 === 1)) {
        offset -= this.settings.postSettings.width;
      } else if (side % 2 === 1 && !(this.settings.wallSettings.sides.includes(2) && this.settings.moduleIndex >= 2)) {
        offset -= this.settings.postSettings.length;
      }
    }

    if (side === 0 || side === 3) {
      offset *= -1;
    }

    if (side % 2 === 1 && this.settings.length > this.settings.minLengthAddPost) {
      return 0;
    }
    return offset / 2.0;
  }

  private _calculateRotation(side: Side): number {
    return (side * Math.PI) / 2;
  }

  private _updateSideDimensions(side: Side) {
    let windowOffset = 0;
    let curtainOffset = 0;
    for (const accessory of this.settings.sidesAccessories[side]) {
      if (accessory instanceof ShadeScreen) {
        windowOffset += 0.03;
        curtainOffset += 0.03;
      }
      if (accessory instanceof GlassDoors) {
        curtainOffset += (2 + accessory.getWindowsNumber()) * 0.018;
      }
    }

    for (const accessory of this.settings.sidesAccessories[side]) {
      let offset = 0;
      if (accessory instanceof GlassDoors) {
        offset = windowOffset;
      }
      if (accessory instanceof Curtain) {
        offset = curtainOffset;
      }

      switch (side) {
        case Side.front: {
          accessory.position.set(
            this._calculateOffset(side),
            this.settings.height / 2.0 - this.settings.rafterSettings.height / 2.0,
            this.settings.length / 2.0 - offset
          );
          accessory.setDimensions(
            this._caclualteWidth(side),
            this.settings.height - this.settings.rafterSettings.height
          );
          break;
        }

        case Side.right: {
          accessory.position.set(
            this.settings.width / 2.0 - offset,
            this.settings.height / 2.0 - this.settings.rafterSettings.height / 2.0,
            this._calculateOffset(side)
          );
          accessory.setDimensions(
            this._caclualteWidth(side),
            this.settings.height - this.settings.rafterSettings.height
          );
          break;
        }

        case Side.back: {
          accessory.position.set(
            this._calculateOffset(side),
            this.settings.height / 2.0 - this.settings.rafterSettings.height / 2.0,
            this.settings.length / -2.0 + offset
          );
          accessory.setDimensions(
            this._caclualteWidth(side),
            this.settings.height - this.settings.rafterSettings.height
          );
          break;
        }

        case Side.left: {
          accessory.position.set(
            this.settings.width / -2.0 + offset,
            this.settings.height / 2.0 - this.settings.rafterSettings.height / 2.0,
            this._calculateOffset(side)
          );
          accessory.setDimensions(
            this._caclualteWidth(side),
            this.settings.height - this.settings.rafterSettings.height
          );
          break;
        }
      }
    }
  }

  updateDimensions() {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      this._updateSideDimensions(side);
    }
  }

  updateColor() {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      for (const accessory of this.settings.sidesAccessories[side]) {
        accessory.updateColor(this.settings.rafterSettings.color);
      }
    }
  }

  addWindow(side: Side, numberWindows: number = 3, closingType: WindowClosinType = WindowClosinType.left) {
    const instance = this._addAcessory(GlassDoors, side) as GlassDoors;
    instance.setClosingType(closingType);
    instance.setWindowsNumber(numberWindows);
    this.updateDimensions();
  }

  addScreen(side: Side, opacity: number = 5, color: IColor = { name: '', id: '', code: 0, customColor: '' }) {
    const instance = this._addAcessory(ShadeScreen, side) as ShadeScreen;
    instance.setOpacity(opacity);
    instance.setColor(color);
    this.updateDimensions();
  }

  addCurtain(
    side: Side,
    closingType: CurtainClosingType = CurtainClosingType.left,
    color: IColor = { name: '', id: '', code: 0, customColor: '' }
  ) {
    const instance = this._addAcessory(Curtain, side) as Curtain;
    instance.setColor(color);
    instance.setClosingType(closingType);
    this.updateDimensions();
  }

  // Here are your type guards
  isScreenType(type: any): type is typeof ShadeScreen {
    return type === ShadeScreen;
  }

  isGlassDoorsType(type: any): type is typeof GlassDoors {
    return type === GlassDoors;
  }

  isCurtainType(type: any): type is typeof Curtain {
    return type === Curtain;
  }

  private _checkIfAccessoryOnSide<T extends SideAccessory>(classType: new (...args: any[]) => T, side: Side): boolean {
    for (const accessory of this.settings.sidesAccessories[side]) {
      if (accessory instanceof classType) {
        return true;
      }
    }

    return false;
  }

  private _addAcessory<T extends SideAccessory>( // doda side accessorie na stran, če tam ni stene. Če je stena potem ta funkcija ne naredi nič
    classType: new (...args: any[]) => T,
    side: Side,
    state: SideAccessoryState = SideAccessoryState.added
  ): SideAccessory | null {
    if (this.settings.wallSettings.sides.includes(side)) {
      // do not add acessory if there is already a wall
      return null;
    }
    if (this._checkIfAccessoryOnSide(classType, side)) {
      return null;
    }

    let instance: SideAccessory;

    if (this.isScreenType(classType)) {
      instance = this._addScreen(side);
    } else if (this.isGlassDoorsType(classType)) {
      instance = this._addWindow(side);
    } else if (this.isCurtainType(classType)) {
      instance = this._addCurtain(side);
    } else {
      instance = this.createSideAccessoryInstance(classType, side);
    }

    instance.updateState(state);
    this.settings.sidesAccessories[side].push(instance);
    this.add(instance);
    this.reorderSidesAccessories();
    return instance;
  }

  private reorderSidesAccessories(): void {
    this.children.sort((a: THREE.Object3D, b: THREE.Object3D) => {
      if (a instanceof SideAccessory && b instanceof SideAccessory) {
          const orderA = a.getOrder() as number;
          const orderB = b.getOrder() as number;
          return orderA - orderB;
      }
      return 0;  // or another default behavior for non-SideAccessory objects
  });
  }

  /*setWindows(sides: Side[]) {
    this._setSideAcessory(GlassDoors, sides);
  }

  setScreens(sides: Side[]) {
    this._setSideAcessory(Screen, sides);
  }*/

  /*private _setSideAcessory<T extends SideAccessory>(classType: new (...args: any[]) => T, sides: Side[]) {
    // nastavi side acessroy na določeno strani, ki so v arrayu, elemnt istega class, ki ni v arrayu sideov se izbriše in nadomesti s plaeholderjem
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      const instance = this.settings.sidesAccessories[side];
      if (sides.includes(side)) {
        this._addAcessory(classType, side);
      } else if (instance instanceof classType) {
        // add placeholder because side is the right class but is not iclided in the array
        this._addPlaceholder(side);
      }
    }
    this.updateDimensions();
  }*/

  private _removeAccessoriesOnSide(side: Side): void {
    for (const accessory of this.settings.sidesAccessories[side]) {
      accessory.traverse((object) => {
        if (object instanceof Mesh) {
          if (object.material) object.material.dispose();
          if (object.geometry) object.geometry.dispose();
        }
      });
      this.remove(accessory);
    }

    this.settings.sidesAccessories[side] = [];
  }

  addWall(side: Side) {
    this._removeAccessoriesOnSide(side);
    this.updateDimensions();
  }

  private _showPossibleLocations<T extends SideAccessory>(classType: new (...args: any[]) => T) {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;

      // če na toti strani ne sme bit dodatek, preskoči
      if (!this.settings.availableSideAccesories.includes(side)) {
        continue;
      }

      // će je placeholder ali null nastavi na novi acerroy, ki ima state preview
      if (!this._checkIfAccessoryOnSide(classType, side)) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          accessory.updateState(SideAccessoryState.ghost);
        }
        this._addAcessory(classType, side, SideAccessoryState.preview);
      }
    }
    this.updateDimensions();
  }

  private _hidePossibleLocations() {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      const accessories = this.settings.sidesAccessories[side];
      for (let i = accessories.length - 1; i >= 0; i--) {
        if (accessories[i].state === SideAccessoryState.preview) {
          accessories[i].traverse((object) => {
            if (object instanceof Mesh) {
              if (object.material) object.material.dispose();
              if (object.geometry) object.geometry.dispose();
            }
          });
          this.remove(accessories[i]);
          accessories.splice(i, 1);
        } else {
          accessories[i].updateState(SideAccessoryState.added);
        }
      }
    }
  }

  showPossibleWindowLocations() {
    this._showPossibleLocations(GlassDoors);
  }

  showPossibleScreenLocations() {
    this._showPossibleLocations(ShadeScreen);
  }

  showPossibleCurtainLocations() {
    this._showPossibleLocations(Curtain);
  }

  hideSideAccessoryLocations() {
    this._hidePossibleLocations();
  }

  // TODOOOO check code
  checkIntersection(intersections: Intersection<Object3D<THREEJSEvent>>[]): SideAccessory | null {
    if (intersections.length === 0) {
      return null;
    }

    let modelAdded: SideAccessory | null = null;

    intersections.forEach((intersection) => {
      if (intersection.object.parent instanceof SideAccessory && modelAdded === null) {
        let clickedSideAccessory = intersection.object.parent as SideAccessory;
        if (clickedSideAccessory.state === SideAccessoryState.preview) {
          while (clickedSideAccessory.parent instanceof SideAccessory) {
            clickedSideAccessory = clickedSideAccessory.parent;
          }

          // če je preview ga nastavi na true
          clickedSideAccessory.updateState(SideAccessoryState.added);
          this._hidePossibleLocations();
          modelAdded = clickedSideAccessory;
        }
      }

      // za windows
      if (intersection.object.parent instanceof Window) {
        let clickedSideAccessory = intersection.object.parent.parent as SideAccessory;
        if (clickedSideAccessory.state === SideAccessoryState.preview) {
          while (clickedSideAccessory.parent instanceof SideAccessory) {
            clickedSideAccessory = clickedSideAccessory.parent;
          }
          // če je preview ga nastavi na true
          clickedSideAccessory.updateState(SideAccessoryState.added);
          this._hidePossibleLocations();
          modelAdded = clickedSideAccessory;
        }
      }
    });

    return modelAdded;
  }

  changeScreenColor(panel: ShadeScreen, color: IColor) {
    panel.setColor(color);
  }

  changeScreenOpacity(panel: ShadeScreen, opacity: number) {
    panel.setOpacity(opacity);
  }

  removeSideAccessory(panel: SideAccessory) {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      const accessories = this.settings.sidesAccessories[side];
      for (let i = accessories.length - 1; i >= 0; i--) {
        if (accessories[i] === panel) {
          accessories[i].traverse((object) => {
            if (object instanceof Mesh) {
              if (object.material) object.material.dispose();
              if (object.geometry) object.geometry.dispose();
            }
          });
          this.remove(accessories[i]);
          accessories.splice(i, 1);
        }
      }
    }
  }

  updateWindowNumber(panel: GlassDoors, number: number) {
    panel.setWindowsNumber(number);
  }

  getStateSides(): { [key: string]: ISideAccessoryArr } | null {
    const sideNames = ['front', 'right', 'back', 'left'];
    const outDict: { [key: string]: ISideAccessoryArr } = {};
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      outDict[sideNames[side]] = [];
      for (const accessory of this.settings.sidesAccessories[side]) {
        outDict[sideNames[side]].push(accessory.getState());
      }
    }

    if (Object.keys(outDict).length === 0) {
      return null;
    }

    return outDict;
  }

  getPanelSide(panel: SideAccessory): Side | undefined {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (this.settings.sidesAccessories[side] !== null) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          if (accessory === panel) return side;
        }
      }
    }

    return undefined;
  }

  setScreenStreching(percentage: number) {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (this.settings.sidesAccessories[side] !== null) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          if (accessory instanceof ShadeScreen) {
            (accessory as ShadeScreen).setStreched(percentage);
          }
        }
      }
    }
  }

  setWindowStreching(percentage: number) {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (this.settings.sidesAccessories[side] !== null) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          if (accessory instanceof GlassDoors) {
            (accessory as GlassDoors).setStreched(percentage);
          }
        }
      }
    }
  }

  setCurtainStreching(percentage: number) {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (this.settings.sidesAccessories[side] !== null) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          if (accessory instanceof Curtain) {
            (accessory as Curtain).setStreched(percentage);
          }
        }
      }
    }
  }

  getWindowList(): SideAccessory[] {
    const ret: SideAccessory[] = [];

    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (this.settings.sidesAccessories[side] !== null) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          if (accessory instanceof GlassDoors) {
            ret.push(accessory);
          }
        }
      }
    }

    return ret;
  }

  getScreenList(): SideAccessory[] {
    const ret: SideAccessory[] = [];

    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (this.settings.sidesAccessories[side] !== null) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          if (accessory instanceof ShadeScreen) {
            ret.push(accessory);
          }
        }
      }
    }

    return ret;
  }

  getCurtainList(): SideAccessory[] {
    const ret: SideAccessory[] = [];

    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (this.settings.sidesAccessories[side] !== null) {
        for (const accessory of this.settings.sidesAccessories[side]) {
          if (accessory instanceof Curtain) {
            ret.push(accessory);
          }
        }
      }
    }

    return ret;
  }

  getAccessoriesList(): SideAccessoryArr[] {
    const ret: SideAccessoryArr[] = [];

    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      ret.push(this.settings.sidesAccessories[side]);
    }

    return ret;
  }

  private _removeAllAccessoriesFromSide(side: Side): void {
    const accessories = this.settings.sidesAccessories[side];
    while (accessories && accessories.length > 0) {
      this.removeSideAccessory(accessories[0]);
    }
  }

  updateAvailableSideAccessoriesPositions() {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      if (!this.settings.availableSideAccesories.includes(side)) {
        this._removeAllAccessoriesFromSide(side);
      }
    }
  }

  public removeAllAccessories(): void {
    for (const key in this.settings.sidesAccessories) {
      const side = Number(key) as Side;
      this._removeAllAccessoriesFromSide(side);
    }
  }
}
