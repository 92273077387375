import { Box3, FrontSide, Mesh, MeshStandardMaterial, PlaneGeometry, Vector3 } from 'three';
import { IColor, SideAccessoryType } from '../../interfaces';
import { Side, SideAccessoryState } from '../../enums';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PergolaBuilder } from '../pergola_builder';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { SceneController } from 'modules/scene-setup/SceneController';
import { SideAccessory } from './side_accessory';
gsap.registerPlugin(ScrollTrigger);

export class ShadeScreen extends SideAccessory {
  private _screenMaterial: MeshStandardMaterial;
  private _modelsMaterial: MeshStandardMaterial;
  private _geometry: PlaneGeometry;
  private _screenOutside: Mesh;
  private _screenInside: Mesh;

  private _topModel: Mesh;
  private _topLeftSideModel: Mesh;
  private _topRightSideModel: Mesh;
  private _bottomModel: Mesh;
  private _leftSideModel: Mesh;
  private _rightSideModel: Mesh;

  private _boundinBoxTopSideDimensions: Vector3;
  private _bottomBoxDimenions: Vector3;

  private _screenOpacity: number = 5;
  private _screenOffset: number = -0.015;

  private _stretched: number = 0.5;
  public _width: number | number[];
  public _height: number;
  private _integrated: boolean = true;

  private _child: ShadeScreen | null = null;
  static objectName: string = 'screen';
  override _order: number = 3;

  dispose() {
    PergolaBuilder.removeObjectsWithChildren(this._screenMaterial);
    PergolaBuilder.removeObjectsWithChildren(this._modelsMaterial);
    PergolaBuilder.removeObjectsWithChildren(this._geometry);
    PergolaBuilder.removeObjectsWithChildren(this._screenOutside);

    PergolaBuilder.removeObjectsWithChildren(this._topModel);
    PergolaBuilder.removeObjectsWithChildren(this._topLeftSideModel);
    PergolaBuilder.removeObjectsWithChildren(this._topRightSideModel);
    PergolaBuilder.removeObjectsWithChildren(this._bottomModel);
    PergolaBuilder.removeObjectsWithChildren(this._leftSideModel);
    PergolaBuilder.removeObjectsWithChildren(this._rightSideModel);

    PergolaBuilder.removeObjectsWithChildren(this);
  }

  constructor(private _models: Array<Mesh>, private _frameColor: IColor, private _screenColor: IColor) {
    super();
    this._topModel = this._models[2].clone();
    this._topLeftSideModel = this._models[3].clone();
    this._topRightSideModel = this._models[3].clone();
    this._bottomModel = this._models[0].clone();
    this._leftSideModel = this._models[1].clone();
    this._rightSideModel = this._models[1].clone();

    if (!this._integrated) {
      const boundinBoxTopSide = new Box3().setFromObject(this._topLeftSideModel);
      this._boundinBoxTopSideDimensions = boundinBoxTopSide.getSize(new Vector3(0, 0, 0));
    } else {
      const boundinBoxTopSide = new Box3().setFromObject(this._topLeftSideModel);
      this._boundinBoxTopSideDimensions = boundinBoxTopSide.getSize(new Vector3(0, 0, 0));
      this._boundinBoxTopSideDimensions.y = 0;
    }

    const bottomBox = new Box3().setFromObject(this._bottomModel);
    this._bottomBoxDimenions = bottomBox.getSize(new Vector3(0, 0, 0));

    this._initScaling();

    if (!this._integrated) {
      this.add(this._topModel);
      this.add(this._topLeftSideModel);
      this.add(this._topRightSideModel);
    }

    this.add(this._leftSideModel);
    this.add(this._rightSideModel);

    this.add(this._bottomModel);

    this._modelsMaterial = new MeshStandardMaterial({
      color: this._frameColor.code, // Semi-transparent green color
      transparent: true,
      opacity: 1,
      roughness: 0.8,
      metalness: 0.5,
    });

    // set material
    if (!this._integrated) {
      this._topModel.material = this._modelsMaterial;
      this._topLeftSideModel.material = this._modelsMaterial;
      this._topRightSideModel.material = this._modelsMaterial;
    }

    this._bottomModel.material = this._modelsMaterial;
    this._leftSideModel.material = this._modelsMaterial;
    this._rightSideModel.material = this._modelsMaterial;

    // Add screen
    this._screenMaterial = new MeshStandardMaterial({
      color: 0x36454f, // Semi-transparent green color
      transparent: true,
      opacity: 1 - this._screenOpacity / 100.0,
      side: FrontSide,
    });

    this._geometry = new PlaneGeometry(1, 1);
    this._screenOutside = new Mesh(this._geometry, this._screenMaterial);
    this._screenOutside.castShadow = true;

    this._screenInside = new Mesh(this._geometry, this._screenMaterial);
    this._screenInside.castShadow = true;

    this.add(this._screenOutside);
    this.add(this._screenInside);
  }

  private _initScaling() {
    this._topLeftSideModel.scale.x = -1;
    this._leftSideModel.scale.x = -1;
  }

  private _setDimensionsMainModel(width: number, height: number, offset: number = 0) {
    const streched = this._stretched / 100;
    if (!this._integrated) {
      this._topModel.position.y = height / 2;
      this._topModel.position.x = width / -2 + this._boundinBoxTopSideDimensions.x - 0.005 + offset;
      this._topModel.position.z = -this._boundinBoxTopSideDimensions.z;
      this._topModel.scale.x = width - this._boundinBoxTopSideDimensions.x * 2 + 0.01;

      this._topLeftSideModel.position.y = height / 2;
      this._topLeftSideModel.position.x = width / -2 + this._boundinBoxTopSideDimensions.x + offset;
      this._topLeftSideModel.position.z = -this._boundinBoxTopSideDimensions.z;

      this._topRightSideModel.position.y = height / 2;
      this._topRightSideModel.position.x = width / 2 - this._boundinBoxTopSideDimensions.x + offset;
      this._topRightSideModel.position.z = -this._boundinBoxTopSideDimensions.z;
    }

    this._leftSideModel.position.y = height / 2 - this._boundinBoxTopSideDimensions.y;
    this._leftSideModel.position.x = width / -2 + this._boundinBoxTopSideDimensions.x + offset;
    this._leftSideModel.position.z = -this._boundinBoxTopSideDimensions.z;
    this._leftSideModel.scale.y = height - this._boundinBoxTopSideDimensions.y;

    this._rightSideModel.position.y = height / 2 - this._boundinBoxTopSideDimensions.y;
    this._rightSideModel.position.x = width / 2 - this._boundinBoxTopSideDimensions.x + offset;
    this._rightSideModel.position.z = -this._boundinBoxTopSideDimensions.z;
    this._rightSideModel.scale.y = height - this._boundinBoxTopSideDimensions.y;

    const screenHeight = streched * (height - this._boundinBoxTopSideDimensions.y - this._bottomBoxDimenions.y);
    this._bottomModel.position.x = width / -2 + this._boundinBoxTopSideDimensions.x - 0.005 + offset;
    this._bottomModel.position.y =
      height / -2 +
      this._bottomBoxDimenions.y +
      (1 - streched) * (height - this._boundinBoxTopSideDimensions.y - this._bottomBoxDimenions.y);
    this._bottomModel.position.z = -this._bottomBoxDimenions.z;
    this._bottomModel.scale.x = width - this._boundinBoxTopSideDimensions.x * 2 + 0.01;

    this._screenOutside.position.z = this._screenOffset;
    this._screenOutside.position.y = height / 2 - this._boundinBoxTopSideDimensions.y - screenHeight / 2;
    this._screenOutside.position.x = offset;
    this._screenOutside.scale.set(width, screenHeight, -1);

    this._screenInside.position.z = this._screenOffset;
    this._screenInside.position.y = height / 2 - this._boundinBoxTopSideDimensions.y - screenHeight / 2;
    this._screenInside.position.x = offset;
    this._screenInside.scale.set(width, screenHeight, -1);
    this._screenInside.rotation.y = Math.PI;
  }

  override setDimensions(width: number | number[], height: number) {
    this._width = width;
    this._height = height;

    if (width instanceof Array) {
      if (this._child === null) {
        this._child = new ShadeScreen(this._models, this._frameColor, this._screenColor);
        this.add(this._child);
        this._child.setOpacity(this._screenOpacity);
        this._child.updateState(this._state);
        this._child.setStreched(this._stretched);
        this._child.setColor(this._screenColor);
      }
      this._child.visible = true;
      this._setDimensionsMainModel(width[0], height, width[0] / -2 - 0.16 / 2);
      this._child.setDimensions(width[1], height);
      this._child.position.x = width[1] / 2 + 0.16 / 2;
    } else {
      if (this._child !== null) {
        this._child.visible = false;
      }
      this._setDimensionsMainModel(width, height);
    }
  }

  setStreched(percentage: number) {
    this._stretched = percentage;
    this.setDimensions(this._width, this._height);
    if (this._child !== null) {
      this._child.setStreched(percentage);
    }
  }

  override updateState(state: SideAccessoryState): void {
    this._state = state;
    if (this._state === SideAccessoryState.preview) {
      this._screenMaterial.opacity = 0.5;
      this._modelsMaterial.opacity = 0.5;
    }
    if (this._state === SideAccessoryState.added) {
      this._screenMaterial.opacity = 1 - this._screenOpacity / 100.0;
      this._modelsMaterial.opacity = 1;
    }
    if (this._state === SideAccessoryState.ghost) {
      this._screenMaterial.opacity = 0.1;
      this._modelsMaterial.opacity = 0.1;
    }
    if (this._child !== null) {
      this._child.updateState(this._state);
    }
  }

  static async getModels(loader: GLTFLoader): Promise<Array<Mesh>> {
    const models = await Promise.all([
      loader.loadAsync('./assets/builder/KE/screen/screen_bottom_v2.glb'),
      loader.loadAsync('./assets/builder/KE/screen/screen_side_v2.glb'),
      loader.loadAsync('./assets/builder/KE/screen/screen_top_center.glb'),
      loader.loadAsync('./assets/builder/KE/screen/screen_top_side.glb'),
    ]);

    const meshes: Mesh[] = [];
    models.forEach((model) => {
      const mesModel: Mesh = model.scene.children[0] as Mesh;
      mesModel.castShadow = true;
      mesModel.receiveShadow = true;
      meshes.push(mesModel);
    });

    return meshes;
  }

  getOpacity(): number {
    return this._screenOpacity;
  }

  override getColor(): IColor {
    return this._screenColor;
  }

  setOpacity(opacity: number): void {
    this._screenOpacity = opacity;
    this._screenMaterial.opacity = 1 - this._screenOpacity / 100.0;
    if (this._child !== null) {
      this._child.setOpacity(opacity);
    }
  }

  setColor(color: IColor): void {
    this._screenColor = color;
    this._screenMaterial.color.set(color.code);

    if (this._child !== null) {
      this._child.setColor(this._screenColor);
    }
  }

  override getState(): SideAccessoryType {
    return {
      type: ShadeScreen.objectName,
      color: this._screenColor,
      opacity: this._screenOpacity,
    };
  }

  override updateColor(color: IColor) {
    this._frameColor = color;
    this._modelsMaterial.color.set(color.code);
    if (this._child !== null) {
      this._child.updateColor(this._frameColor);
    }
  }

  override focus(side: Side, sceneController: SceneController) {
    const bbox = new Box3().setFromObject(this);
    const bboxCenter: Vector3 = new Vector3();
    bbox.getCenter(bboxCenter);

    let val = bboxCenter.x;
    if (side === Side.front || side === Side.back) val = bboxCenter.z;

    gsap.to(sceneController.camera.position, {
      duration: this._animationDuration,
      x: bboxCenter.x + (val === bboxCenter.x ? 10 * Math.sign(val) : 0),
      y: bboxCenter.y,
      z: bboxCenter.z + (val === bboxCenter.z ? 10 * Math.sign(val) : 0),
    });

    gsap.to(sceneController.orbitControls.target, {
      duration: this._animationDuration,
      x: 0,
      y: 0,
      z: 0,
    });
  }
}
