/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Group, Raycaster } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Side } from '../enums';
import { IPergolaBuilder, SideAccessoryArr, SideRecord } from '../interfaces';
import { ModuleBuilder } from './module_builder';
import { PergolaSettings } from './pergola_settings';
import { SideAccessory } from './side_accessories/side_accessory';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
export class PergolaBuilder extends Group implements IPergolaBuilder {
  private _modules: Array<ModuleBuilder | null> = [];
  private _maxNumModulesWidth: number = 2;
  private _maxNumModuleLength: number = 2;
  private _loader: GLTFLoader;

  loaded = false;
  public settings: PergolaSettings;

  constructor(settings: PergolaSettings, private _glowPass: OutlinePass) {
    super();
    this.settings = settings;
  }

  async initObjects(): Promise<void> {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

    this._loader = new GLTFLoader();
    this._loader.setDRACOLoader(dracoLoader);

    // TODO: naredi, da se prvo null razen prvi in se potem loadajo
    const promises = [];

    for (let i = 0; i < this._maxNumModulesWidth * this._maxNumModuleLength; i++) {
      const sides: Side[] = [];
      const indeces = this._get2DIndices(i);
      if (indeces.i > 0) sides.push(Side.left);
      if (indeces.j > 0) sides.push(Side.back);

      const module = new ModuleBuilder(this.settings, sides, this._loader, this._glowPass, i);
      if (this.settings.startModulesAccesories?.length > i) {
        module.setSideAccesories(this.settings.startModulesAccesories[i]);
      }
      promises.push(module.initObjects());

      this._modules.push(module);
      this.add(module);

      if (i > 0) {
        module.visible = false;
      }
    }

    await Promise.all(promises);
    this.loaded = true;
    this.updateDimensions();
  }

  dispose() {
    for (const module of this._modules) {
      module?.dispose();
    }

    this._modules = [];

    PergolaBuilder.removeObjectsWithChildren(this);
  }

  private _get2DIndices(index: number) {
    const j = Math.floor(index / this._maxNumModulesWidth);
    const i = index % this._maxNumModulesWidth;
    return { i: i, j: j };
  }

  updateFrameColor() {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.updateFrameColor();
    }
  }

  updateLouversColor() {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.updateLouversColor();
    }
  }

  updateInnerLeds(glowPass: OutlinePass) {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.updateInnerLeds(glowPass);
    }
  }

  updateWalls(): void {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.setWallSettings(this.settings.wallSettings);
      module?.updateWalls();
    }
  }

  getModuleStates(): (SideRecord | null)[] {
    if (!this.loaded) return [];

    const ret: (SideRecord | null)[] = [];

    for (const module of this._modules) {
      ret.push(module!.sideAccessoryController.getStateSides());
    }

    return ret;
  }

  getPanelSide(panel: SideAccessory): Side | undefined {
    if (!this.loaded) return undefined;

    for (const module of this._modules) {
      const side = module?.sideAccessoryController.getPanelSide(panel);
      if (side !== undefined) return side;
    }

    return undefined;
  }

  getNumModules(): number {
    const widthModulesNeeded = Math.ceil(this.settings.width / this.settings.maxModuleWidth);
    const lengthModulesNeeded = Math.ceil(this.settings.length / this.settings.maxModuleLength);
    return widthModulesNeeded * lengthModulesNeeded;
  }

  updateLouversRotation(): void {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.updateLouversRotation();
    }
  }

  updateScreenStretching(): void {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.updateScreenStretching();
    }
  }

  updateWindowStretching(): void {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.updateWindowStretching();
    }
  }

  updateCurtainStretching(): void {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.updateCurtainStretching();
    }
  }

  getWindowList(): SideAccessory[] {
    let list: SideAccessory[] = [];
    for (const module of this._modules) {
      if (module !== null) {
        list = list.concat(module?.getWindowList());
      }
    }
    return list;
  }

  getScreenList(): SideAccessory[] {
    let list: SideAccessory[] = [];
    for (const module of this._modules) {
      if (module !== null) {
        list = list.concat(module?.getScreenList());
      }
    }
    return list;
  }

  getCurtainList(): SideAccessory[] {
    let list: SideAccessory[] = [];
    for (const module of this._modules) {
      if (module !== null) {
        list = list.concat(module?.getCurtainList());
      }
    }
    return list;
  }

  getAccessoriesList(): SideAccessoryArr[][] {
    const list: SideAccessoryArr[][] = [];
    for (const module of this._modules) {
      if (module !== null) {
        //list = list.concat(module?.getAccessoriesList());
        list.push(module?.getAccessoriesList());
      }
    }

    return list;
  }

  removePanel(panel: SideAccessory): void {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.removePanel(panel);
    }
  }

  public updateDimensions() {
    if (!this.loaded) return;

    const widthModulesNeeded = Math.ceil(this.settings.width / this.settings.maxModuleWidth);
    const lengthModulesNeeded = Math.ceil(this.settings.length / this.settings.maxModuleLength);

    const visibleModules = [];
    for (let i = 0; i < this._maxNumModulesWidth * this._maxNumModuleLength; i++) {
      if (this._modules[i] !== null) {
        if(this._modules[i]!.visible){
          this._modules[i]!.setDimensions(
            0,
            0,
            0
          );
        }
        this._modules[i]!.visible = false;

        visibleModules.push(false);
      }
    }

    const combinedWidth = this.settings.width;
    const combinedLength = this.settings.length;

    for (let i = 0; i < widthModulesNeeded; i++) {
      for (let j = 0; j < lengthModulesNeeded; j++) {
        const index = i + j * this._maxNumModulesWidth;
        if (this._modules[i] === null) {
          this._modules[i] = new ModuleBuilder(this.settings, [], this._loader, this._glowPass, index);
          this.add(this._modules[i]!);
        }

        visibleModules[index] = true;

        this._modules[index]!.visible = true;
        const moduleWidthToSet = Math.min(this.settings.maxModuleWidth, combinedWidth / widthModulesNeeded);
        const moduleLengthToSet = Math.min(this.settings.maxModuleLength, combinedLength / lengthModulesNeeded);
        const xPosition = (i * combinedWidth) / widthModulesNeeded - ((widthModulesNeeded - 1) * moduleWidthToSet) / 2;
        const yPosition =
          (j * combinedLength) / lengthModulesNeeded - ((lengthModulesNeeded - 1) * moduleLengthToSet) / 2;

        let offsetX = 0;
        let offsetY = 0;
        if (i > 0) offsetX += this.settings.rafterSettings.width; // zamik zaradi tega, ker se pri modulih, ki se pripenjanjo ne doda rafter na tisti strani (prav tako ne štange)
        if (j > 0) offsetY += this.settings.rafterSettings.width; // zamik zaradi tega, ker se pri modulih, ki se pripenjanjo ne doda rafter na tisti strani (prav tako ne štange)
        this._modules[index]!.position.set(xPosition - offsetX / 2.0, 0, yPosition - offsetY / 2.0);
        this._modules[index]!.setDimensions(
          moduleWidthToSet + offsetX,
          moduleLengthToSet + offsetY,
          this.settings.height
        );

        // dodaj na katerih straneh vse se lahko dodajo dodatki
        const availableSideAccessoriesPositions: Side[] = [];
        if (i === 0) {
          availableSideAccessoriesPositions.push(Side.left);
        }
        if (j === 0) {
          availableSideAccessoriesPositions.push(Side.back);
        }

        if (i === widthModulesNeeded - 1) {
          availableSideAccessoriesPositions.push(Side.right);
        }

        if (j === lengthModulesNeeded - 1) {
          availableSideAccessoriesPositions.push(Side.front);
        }
        this._modules[index]!.setAvailableSideAccessoriesPositions(availableSideAccessoriesPositions);
      }
    }

    for (let i = 0; i < this._maxNumModulesWidth * this._maxNumModuleLength; i++) {
      if (!visibleModules[i]) {
        this._modules[i]!.removeSideAccessories();
      }
    }
  }

  /* functions used only by chatbot
  
  addWindow(side: Side): void {
    // doda screen na določen side
    this.sideAccessoryController.addWindow(side);
  }

  addScreen(side: Side): void {
    // doda screen na določen side
    this.sideAccessoryController.addScreen(side);
  }

  setWindows(sides: Side[]) {
    // nastavi window vsepovsod, kjer je podan sides. Če so bila ki prej okna, se izbrišejo tam kjer ni podan položaj v sides array
    this.sideAccessoryController.setWindows(sides);
  }

  setScreens(sides: Side[]) {
    // nastavi screene vsepovsod, kjer je podan sides. Poglej opis zgoraj
    this.sideAccessoryController.setScreens(sides);
  }*/

  showPossibleWindowLocations() {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.showPossibleWindowLocations();
    }
  }

  showPossibleScreenLocations() {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.showPossibleScreenLocations();
    }
  }

  showPossibleCurtainLocations() {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.showPossibleCurtainLocations();
    }
  }

  hideSideAccessoryLocations() {
    if (!this.loaded) return;

    for (const module of this._modules) {
      module?.hideSideAccessoryLocations();
    }
  }

  checkIntersection(raycaster: Raycaster): SideAccessory | null {
    if (!this.loaded) return null;

    let intersected = null;
    for (const module of this._modules) {
      if (module !== null) {
        intersected = module?.checkIntersection(raycaster);
        if (intersected !== null) {
          break;
        }
      }
    }

    if (intersected !== null) {
      for (const module of this._modules) {
        module?.hideSideAccessoryLocations();
      }
    }

    return intersected;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static removeObjectsWithChildren(obj: any) {
    if (!obj) return true;
    if (!obj === undefined) return true;

    if (obj.isMaterial) {
      if (obj.map) obj.map.dispose();
      if (obj.lightMap) obj.lightMap.dispose();
      if (obj.bumpMap) obj.bumpMap.dispose();
      if (obj.normalMap) obj.normalMap.dispose();
      if (obj.specularMap) obj.specularMap.dispose();
      if (obj.envMap) obj.envMap.dispose();

      obj.dispose();
      return true;
    }

    if (obj.children && obj.children.length > 0) {
      for (let x = obj.children.length - 1; x >= 0; x--) {
        this.removeObjectsWithChildren(obj.children[x]);
      }
    }

    if (obj.geometry) {
      obj.geometry.dispose();
    }

    if (obj.material) {
      if (obj.material.length) {
        for (let i = 0; i < obj.material.length; ++i) {
          if (obj.material[i].map) obj.material[i].map.dispose();
          if (obj.material[i].lightMap) obj.material[i].lightMap.dispose();
          if (obj.material[i].bumpMap) obj.material[i].bumpMap.dispose();
          if (obj.material[i].normalMap) obj.material[i].normalMap.dispose();
          if (obj.material[i].specularMap) obj.material[i].specularMap.dispose();
          if (obj.material[i].envMap) obj.material[i].envMap.dispose();

          obj.material[i].dispose();
        }
      } else {
        if (obj.material.map) obj.material.map.dispose();
        if (obj.material.lightMap) obj.material.lightMap.dispose();
        if (obj.material.bumpMap) obj.material.bumpMap.dispose();
        if (obj.material.normalMap) obj.material.normalMap.dispose();
        if (obj.material.specularMap) obj.material.specularMap.dispose();
        if (obj.material.envMap) obj.material.envMap.dispose();

        obj.material.dispose();
      }
    }

    if (obj.removeFromParent !== undefined) {
      obj.removeFromParent();
    }
    return true;
  }
}
