import {
  Mesh,
  BoxGeometry,
  Object3D,
  TextureLoader,
  MeshBasicMaterial,
  RepeatWrapping,
  SRGBColorSpace,
  PlaneGeometry,
  DoubleSide,
  ShadowMaterial,
} from 'three';

import { Floor } from './interfaces';
import { PergolaSettings } from './KE_builder/pergola_settings';

export class FloorBuilder extends Object3D {
  settings: PergolaSettings;
  boxGeometry: BoxGeometry;
  boxMaterial: MeshBasicMaterial;
  textureLoader: TextureLoader;
  box: Mesh;

  private _ofsset: number = 0.2; // koliko je prek pergole
  private _height: number = 0.02;
  private _textureScale: number = 0.7;

  constructor(settings: PergolaSettings) {
    super();
    this.settings = settings;
  }

  updateDimensions(): void {
    this.box.scale.set(this.settings.width + this._ofsset, this.settings.length + this._ofsset, this._height);
    this.boxMaterial.map?.repeat.set(
      (this.settings.width + this._ofsset) * this._textureScale,
      (this.settings.length + this._ofsset) * this._textureScale
    );
  }

  dispose() {
    this.boxGeometry.dispose();
    this.boxMaterial.dispose();
    this.box.geometry.dispose();
  }

  async updateTexture(floor: Floor): Promise<void> {
    this.settings.selectedFloor = floor;

    const texture = await this.textureLoader.loadAsync(floor.url)
    texture.colorSpace = SRGBColorSpace;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    this.boxMaterial = new MeshBasicMaterial({ map: texture });
    this.box.material = this.boxMaterial;

    this.box.receiveShadow = true;
    this.box.castShadow = true;

    this.updateDimensions();
  }

  async initObjects(): Promise<void> {
    this.textureLoader = new TextureLoader();
    this.boxGeometry = new BoxGeometry(1, 1, 1);

    const texture = await this.textureLoader.loadAsync(this.settings.selectedFloor.url);

    texture.colorSpace = SRGBColorSpace;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;

    this.boxMaterial = new MeshBasicMaterial({ map: texture, shadowSide: DoubleSide });
    this.box = new Mesh(this.boxGeometry, this.boxMaterial);
    this.box.rotation.set(Math.PI / -2, 0, 0);
    this.box.position.set(0, -this._height / 2.0, 0);

    const plane = new PlaneGeometry(20, 20, 10, 10);
    const planeMaterial = new ShadowMaterial({ shadowSide: DoubleSide });
    const planeMesh = new Mesh(plane, planeMaterial);
    planeMesh.receiveShadow = true;
    planeMesh.rotation.x = -Math.PI / 2;
    planeMesh.position.y += 0.01;
    planeMaterial.opacity = 0.2;

    this.add(planeMesh);

    this.add(this.box);
    this.updateDimensions();
  }
}
