<div id="qr-container" *ngIf="qrUrl" (click)="onCloseClick()">
    <div (click)="$event.stopPropagation();" class="holder_qr">
      <button (click)="$event.stopPropagation();" mat-icon-button class="close-qr-panel">
        <mat-icon>close</mat-icon>
      </button>

      <div class="content_qr">
        <h1 class="qr_title"> {{ qrTitle }} </h1>
        <p class="qr_subtext"> {{ qrSubtext }} </p>
        <p style='opacity: .6;' class="qr_subtext"> {{ qrNotice }} </p>
        <div class="iconContainer">
          <mat-icon style="font-size: 32px;">tablet_mac</mat-icon>
          <mat-icon style="display: flex; align-items: flex-end;">smartphone</mat-icon>
        </div>

        <div>
          <qrcode (click)="$event.stopPropagation()" [qrdata]="qrUrl" [ariaLabel]="'QR Code for configuration'"
          [cssClass]="'qr-code'"
          [colorDark]="'#000000ff'"
          [colorLight]="'#ffffffff'"
          [elementType]="'canvas'"
          [errorCorrectionLevel]="'M'"
          [imageSrc]="logoUrl"
          [imageHeight]="55"
          [imageWidth]="55"
          [margin]="0"
          [scale]="1"
          [title]="'Prikaz ustvarjene scene v svojem prostoru'"
          [width]="256"></qrcode>
        </div>
      </div>
    </div>
</div>

<div id="ar-ui-container">
  <div id="ar-ui">
    <button id="exit-ar-button" mat-icon-button class="close-icon-button" aria-label="Close"><mat-icon>close</mat-icon></button>
    <!--<button id="ar-reset-button" mat-raised-button>Ponovno postavi</button>-->
    <div id="ar_instructions_container">
      <div class="move_phone_ar">
        <div id="ar-prompt">
          <img src="https://modelviewer.dev/shared-assets/icons/hand.png">
        </div>
        <div id="ar_move_text"><div id="text"><p> {{ arMoveText }} </p></div></div>
      </div>
    </div>
  </div>
</div>